import styled from 'styled-components';

const FollowupLabel = styled.label`
  display: block;
  color: ${props => props.theme.questionTextColor};
  padding: 5px 0px 5px 0px;
  font-weight: 700;
  margin-left: 10px;
  font-size: 1.3rem;
`;

export default FollowupLabel;
