import styled from "styled-components";
import defaultTheme from "./theme";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const ITC = cookies.get("cid") == 218;
const SDL = cookies.get("cid") == 226;

const ChatBotContainer = ITC
  ? styled.div`
      background-image: url("https://itc-user-media.s3.ap-south-1.amazonaws.com/media/store1+(1).jpg");
      background-size: cover;
      border-radius: 0px;
      box-shadow: 0px;
      font-family: ${({ theme }) => theme.fontFamily};
      overflow: hidden;
      position: ${(props) => (props.floating ? "fixed" : "relative")};
      bottom: ${(props) => (props.floating ? "32px" : "initial")};
      right: ${(props) => (props.floating ? "32px" : "initial")};
      width: ${(props) => props.width};
      z-index: 999;
      transform: ${(props) => (props.opened ? "scale(1)" : "scale(0)")};
      transform-origin: bottom right;
      transition: transform 0.3s ease;
      @media screen {
        border-radius: ${(props) => (props.floating ? "0" : "")};
        bottom: 0;
        height: 100vh;
        right: 0;
        width: 100vw;
      }

      @media screen and (min-width: 993px) {
        border-radius: ${(props) => (props.floating ? "0" : "")};
        bottom: 0;
        height: 100vh;
        right: 70.8vw;
        width: 77.5vw;
      }

      @media screen and (max-width: 415px) {
        @media screen and (max-height: 737px) {
          height: 100vh;
          width: 100vw;
        }
      }
    `
  : styled.div`
      background: ${({ theme }) => theme.background};
      border-radius: 0px;
      box-shadow: 0px;
      font-family: ${({ theme }) => theme.fontFamily};
      overflow: hidden;
      position: ${(props) => (props.floating ? "fixed" : "relative")};
      bottom: ${(props) => (props.floating ? "32px" : "initial")};
      right: ${(props) => (props.floating ? "32px" : "initial")};
      width: ${(props) => props.width};
      z-index: 999;
      transform: ${(props) => (props.opened ? "scale(1)" : "scale(0)")};
      transform-origin: bottom right;
      transition: transform 0.3s ease;

      @media screen {
        border-radius: ${(props) => (props.floating ? "0" : "")};
        bottom: 0;
        height: 100vh;
        right: 0;
        width: 100vw;
      }

      @media screen and (min-width: 993px) {
        border-radius: ${(props) => (props.floating ? "0" : "")};
        bottom: 0;
        height: 100vh;
        right: 70.8vw;
        width: 77.5vw;
      }

      @media screen and (max-width: 415px) {
        @media screen and (max-height: 737px) {
          height: 100vh;
          width: 100vw;
        }
      }
    `;

ChatBotContainer.defaultProps = {
  theme: defaultTheme,
};

export default ChatBotContainer;
