import React, { Component } from "react";
import _ from "lodash";
import DepartmentOptionStyle from "./DepartmentOptionStyle";
import SubmitButton from "./SubmitButton";
import { themeSelector } from "../../../static/theme";
import { Icon } from "semantic-ui-react";
import ReactTooltip from "react-tooltip";

import Cookies from "universal-cookie";
import { Modal, Button } from "react-bootstrap";
const cookies = new Cookies();
export default class DepartmentOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      output: "",
      disabled: false,
      hide: false,
      isSelected: {},
      showModal: false,
      theme: themeSelector(parseInt(cookies.get("cid")), process.env.NODE_ENV),
    };
    this.renderOption = this.renderOption.bind(this);
    this.submitOutput = this.submitOutput.bind(this);
    this.DepartmentSelect = this.DepartmentSelect.bind(this);
  }

  componentDidMount() {
    const { options } = this.props;

    options.filter((o) => o.selected === true).length > 0
      ? this.setState({
          disabled: true,
        })
      : null;
  }

  DepartmentSelect(value) {
    if (!this.state.disabled) {
      const newState = Object.assign({}, this.state.isSelected);
      newState[value] = !this.state.isSelected[value];
      const keys = Object.keys(newState);
      const output = keys.reduce((acc, val) => {
        if (newState[val] === true) {
          if (acc !== "") {
            acc = acc.concat(",");
          }
          acc += val;
        }
        return acc;
      }, "");

      this.setState({
        isSelected: newState,
        output,
      });
    }
  }

  handleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  submitOutput() {
    this.setState({
      disabled: true,
      hide: true,
      showModal: false,
    });
    this.props.submitAnswer(this.state.output);
  }

  renderOption(step, index) {
    const isSelected = false;
    const { id, answer } = step;
    this.state.isSelected[id] = this.state.isSelected[id]
      ? this.state.isSelected[id]
      : step.selected
      ? step.selected
      : isSelected;

    return (
      <DepartmentOptionStyle
        key={index}
        optionColor={
          this.state.isSelected[id]
            ? this.state.theme.deptOptionColorSelected
            : this.state.theme.deptOptionColorNotSelected
        }
        fontColor={this.state.theme.deptOptionFontColor}
        onClick={() => this.DepartmentSelect(id)}
      >
        {answer}
        {step.definition ? (
          <React.Fragment key={step.definition}>
            <Icon
              style={{
                paddingLeft: "5px",
                color: "white",
                cursor: "pointer",
                background: "none",
              }}
              name="info circle"
              size="small"
              color="white"
              data-tip={step.definition}
              data-type="light"
              data-place="right"
              data-effect="solid"
            />
            <ReactTooltip />
          </React.Fragment>
        ) : null}
      </DepartmentOptionStyle>
    );
  }

  render() {
    const { options } = this.props;
    return (
      <React.Fragment>
        {_.map(options, this.renderOption)}
        {this.state.hide || this.state.output === "" ? null : (
          <div style={{ textAlign: "center" }}>
            <SubmitButton
              optionColor="#00ffff"
              fontColor="#2a2a2a"
              onClick={this.handleModal}
            />
          </div>
        )}
        <Modal
          backdrop={true}
          className="modal-container"
          style={{ display: "flex", alignItems: "center" }}
          backdropStyle={{
            opacity: "0.5",
            zIndex: 2,
            height: "100% !important",
            position: "fixed",
          }}
          animation={false}
          show={this.state.showModal}
          onHide={this.handleModal}
        >
          <Modal.Body>
            <Modal.Title style={{ textAlign: "center", fontSize: "1.3rem" }}>
              Are you sure you have selected all the functions you interact
              with?
              <br />
              Press <strong>'Yes'</strong> to continue and <strong>'No'</strong>{" "}
              to go back.
            </Modal.Title>
            <center>
              <Button
                style={{
                  marginTop: "20px",
                  color: "white",
                  backgroundColor: "#25c16f",
                  fontFamily: '"Roboto","Open Sans", sans-serif',
                  transition: "background-color .15s ease-in",
                  textTransform: "uppercase",
                  display: "inline-block",
                  fontSize: 14,
                  fontWeight: 600,
                  marginRight: "20px",
                }}
                onClick={this.submitOutput}
              >
                Yes
              </Button>
              <Button
                style={{
                  marginTop: "20px",
                  color: "white",
                  backgroundColor: "#25c16f",
                  fontFamily: '"Roboto","Open Sans", sans-serif',
                  transition: "background-color .15s ease-in",
                  textTransform: "uppercase",
                  display: "inline-block",
                  fontSize: 14,
                  fontWeight: 600,
                }}
                onClick={this.handleModal}
              >
                No
              </Button>
            </center>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
