let config = {};

if (process.env.NODE_ENV === "production") {
  config = {
    baseURL:
      window.location.href.includes("hrlogica") == true
        ? "https://hrlogica.xane.ai/h1"
        : "https://api.xane.ai/r1",
    headers: {
      "x-origin": "3",
      "x-version": "1",
    },
  };
} else if (process.env.NODE_ENV === "development") {
  config = {
    baseURL: "https://dev.xane.ai",
    headers: {
      "x-origin": "3",
      "x-version": "1",
    },
  };
} else if (process.env.NODE_ENV === "preproduction") {
  config = {
    baseURL: "https://pre.xane.ai",
    headers: {
      "x-origin": "3",
      "x-version": "1",
    },
  };
} else if (process.env.NODE_ENV === "staging") {
  config = {
    baseURL: "https://stage.xane.ai",
    headers: {
      "x-origin": "3",
      "x-version": "1",
    },
  };
}

module.exports = config;
