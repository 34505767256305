import React from "react";
import Cookies from "universal-cookie";
import "./FollowupInput.css";
const cookies = new Cookies();

export default class FollowupInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.answer ? this.props.answer : "",
      answered: false,
      edit: false,
      placeholder: "Enter your response here.",
    };
    this.updateValue = this.updateValue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.props.answer
      ? this.setState({
          answered: true,
        })
      : null;
  }

  updateValue(e) {
    this.setState({
      value: e.target.value,
    });
  }

  handleKeyPress(event) {
    if (event.key == "Enter") {
      event.preventDefault();
      let val = this.state.value,
        start = event.target.selectionStart,
        end = event.target.selectionEnd;

      this.setState(
        { value: val.substring(0, start) + "\n" + val.substring(end) },
        () => {
          this.refs.input.selectionStart = this.refs.input.selectionEnd =
            start + 1;
        }
      );
    }
  }

  handleSubmit(value, dept, qId) {
    // console.log('here');
    if (value !== "" && value !== undefined && value !== null) {
      // console.log('22');
      if (this.state.edit === true) {
        this.props.handleEdit(value, dept, qId);
        this.setState({
          answered: true,
          edit: false,
        });
      } else {
        // console.log('herer');
        this.props.handleSubmit(value, dept, qId);
        this.setState({
          placeholder: "Enter your response here.",
          answered: true,
        });
      }
    } else {
      this.setState({
        placeholder: "Please elaborate your response.",
      });
    }
  }

  handleEdit() {
    this.props.handleEditFollowup(this.props.dept);
    this.setState({
      answered: false,
      edit: true,
    });
  }

  // componentDidUpdate() {
  //   if (this.input !== null) {
  //     this.input.focus();
  //   }
  // }

  render() {
    // console.log(this.props.disabled);
    return this.state.answered === true ? (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            marginLeft: "2rem",
            fontSize: "1.3rem",
            color: `${this.props.theme.questionTextColor}`,
            flexDirection: "column",
          }}
        >
          {this.state.value}
          {/* for incred survey 31AUG2021 */}
          {this.props.edit ||
          cookies.get("survey_id") == 11 ||
          cookies.get("survey_id") == 12 ||
          (process.env.NODE_ENV === "development" &&
            cookies.get("survey_id") == 301) ||
          (process.env.NODE_ENV === "production" &&
            cookies.get("survey_id") == 15) ? (
            <button
              style={{
                marginLeft: "10px",
                backgroundColor: "#2a2a2a",
                color: "#ff883a",
                padding: "7px 10px",
                fontWeight: "600",
                float: "right",
                border: "1px solid #ff883a",
                borderRadius: "5px",
                cursor: "pointer",
                width: "fit-content",
              }}
              onClick={this.handleEdit}
            >
              Edit
            </button>
          ) : null}
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <textarea
          className="textBox"
          style={{
            marginLeft: "1rem",
            marginBottom: "1rem",
            backgroundColor: `${this.props.theme.inputBgColor}`,
            border: "1px solid #fff",
            borderRadius: "5px",
            color: "#fff",
            fontWeight: "600",
            fontSize: "1.2rem",
            boxSizing: "border-box",
            padding: "10px",
            outline: "none",
            width: "100%",
          }}
          disabled={
            this.props.disabled === undefined || this.props.edit === true
              ? false
              : !this.props.disabled
          }
          value={this.state.value}
          onChange={(e) => this.updateValue(e)}
          ref="input"
          placeholder={this.state.placeholder}
          onKeyPress={this.handleKeyPress}
        />
        <button
          className="Sbutton"
          style={{
            marginLeft: "10px",
            // marginTop: "10px",
            backgroundColor: `${this.props.theme.btnBg}`,
            color: "#fff",
            fontWeight: "600",
            border: "1px solid #fff",
            borderRadius: "5px",
            padding: "7px 10px",
            cursor: "pointer",
            //  margin: "auto"
          }}
          // disabled={this.props.disabled === undefined ? false : !this.props.disabled}
          onClick={() =>
            this.handleSubmit(this.state.value, this.props.dept, this.props.qId)
          }
        >
          Submit
        </button>
      </React.Fragment>
    );
  }
}
