import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Switch, Route } from 'react-router-dom';

import App from './container/App/App.jsx';

ReactDOM.render((
  <HashRouter>
    <Switch>
      <Route path="/" name="Home" component={App} />
    </Switch>
  </HashRouter>
), document.getElementById('root'));
