import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import _ from 'lodash';
import DateComponent from './DateComponent';
import DateElement from './DateElement';
import Dates from './Dates';
import DatesStepContainer from './DatesStepContainer';
import SubmitButton from './SubmitButton';

class DatesStep extends Component {
  /* istanbul ignore next */
  constructor(props) {
    super(props);
    this.state = {
      dateOne: '',
      dateTwo: '',
      minDate: '',
    };
    this.handleDateOne = this.handleDateOne.bind(this);
    this.handleDateTwo = this.handleDateTwo.bind(this);
    this.submitDate = this.submitDate.bind(this);
    // this.onDateClick = this.onDateClick.bind(this);
  }

  componentDidMount() {
    const minDate = new Date().toISOString().substring(0, 10);
    this.setState({ minDate });
  }

  submitDate() {
    let date = this.state.dateOne
      .toString()
      .split('-')
      .reverse()
      .reduce((acc, val) => (acc += `${val}/`), '');
    date = date.slice(0, date.length - 1);
    if (this.state.dateTwo !== '') {
      date += ` - ${this.state.dateTwo
        .toString()
        .split('-')
        .reverse()
        .reduce((acc, val) => (acc += `${val}/`), '')}`;
      date = date.slice(0, date.length - 1);
    }
    this.props.triggerNextStep({ value: date });
  }

  handleDateOne(e, { value }) {
    this.setState({
      dateOne: value,
    });
  }

  handleDateTwo(e, { value }) {
    this.setState({
      dateTwo: value,
    });
  }

  render() {
    const { bubbleStyle } = this.props;
    const { inputDate } = this.props.step;

    return (
      <DatesStepContainer className="rsc-ds">
        <Dates className="rsc-ds-dates">
          <DateComponent key={inputDate[0]} className="rsc-ds-date">
            <DateElement className="rsc-ds-date-element" style={bubbleStyle}>
              <Input
                style={{
                  color: 'white !important',
                }}
                max={this.state.minDate}
                type="date"
                // transparent
                label={inputDate.length === 1 ? 'DOB: ' : 'From: '}
                value={this.state.dateOne}
                onChange={this.handleDateOne}
              />
            </DateElement>
          </DateComponent>
          {inputDate[1] ? (
            <DateComponent key={inputDate[1]} className="rsc-ds-date">
              <DateElement className="rsc-ds-date-element" style={bubbleStyle}>
                <Input
                  style={{
                    color: 'white !important',
                  }}
                  max={this.state.minDate}
                  type="date"
                  // transparent
                  label="To: "
                  value={this.state.dateTwo}
                  onChange={this.handleDateTwo}
                />
              </DateElement>
            </DateComponent>
          ) : null}
          {this.state.dateOne === '' ||
          (inputDate[1] ? this.state.dateTwo === '' : false) ? null : (
            <SubmitButton onClick={this.submitDate} />
          )}
        </Dates>
      </DatesStepContainer>
    );
  }
}

DatesStep.propTypes = {
  step: PropTypes.object.isRequired,
  triggerNextStep: PropTypes.func.isRequired,
  bubbleStyle: PropTypes.object.isRequired,
};

export default DatesStep;
