import styled from 'styled-components';

const Content = styled.div`
  height: 80vh;
  margin-top: 1vh;
  padding-top: 1vh;
  overflow-y:scroll;
  overflow-x:hidden;
`;

export default Content;
