import emoji from "node-emoji";

export default function convertToQuestion(data, prevAnswer = null) {
  const questionSet = [];
  let question = {};

  if (data.response) {
    // eslint-disable-next-line default-case
    switch (data.response) {
      // Do nothing with response, just enable next department
      case 1:
        if (prevAnswer === null) {
          questionSet.push({});
        } else {
          question.id = data.current_question.id;
          question.message = data.current_question.question;
          if (data.current_question.answer_type === "Objective") {
            question.answer_type = "Objective";
            question.followup = prevAnswer.followup;
            question.department = prevAnswer.department;
            const remDept = data.department_set.slice(
              prevAnswer.department.length
            );
            question.department = [...question.department, ...remDept];
            question.answers = prevAnswer.answers;
          } else {
            question.answer_type = "Subjective";
            question.followup = [];
            prevAnswer.department.forEach((d) => question.followup.push({}));
            question.department = prevAnswer.department;
            const remDept = data.department_set.slice(
              prevAnswer.department.length
            );
            question.department = [...question.department, ...remDept];
          }
          questionSet.push(question);
        }
        break;

      // Follow-up question to the last department
      case 2:
        if (prevAnswer === null) {
          question.id = data.next_question.id;
          question.message = data.next_question.question;
          question.answered = false;
          question.department_id = data.department_set[0].department_id;
          question.answer_type = "Followup";
          questionSet.push(question);
        } else {
          question.id = data.current_question.id;
          question.message = data.current_question.question;
          if (data.current_question.answer_type === "Objective") {
            question.answer_type = "Objective";
            question.followup = prevAnswer.followup;
            question.department = prevAnswer.department;
            const remDept = data.current_department_set.slice(
              prevAnswer.department.length
            );
            question.department = [...question.department, ...remDept];
            question.answers = prevAnswer.answers;
          } else {
            question.answer_type = "Subjective";
            question.followup = [];
            question.department = data.current_department_set;
          }
          questionSet.push(question);
        }
        break;

      // New QuestionSet
      case 3:
        question.id = data.next_question.id;
        question.message = data.next_question.question;
        if (data.next_question.answer_type === "Objective") {
          // If next question is Objective Set
          question.answer_type = "Objective";
          question.followup = [];
          question.department = data.department_set;
          question.answers = data.next_question.answer.map((ans) => ({
            id: ans.id,
            answer: ans.answer,
          }));
        } else {
          question.answer_type = "Subjective";
          question.followup = [];
          question.department = data.department_set;
        }
        questionSet.push(question);
        break;
    }
  } else if (data.welcome_survey === true) {
    question.id = "Welcome";
    question.message = data.message;
    questionSet.push(question);

    question = {};
    question.id = data.next_question.id;
    question.message = data.next_question.question;
    if (data.next_question.answer_type === "Multiple Choice") {
      // If next question is Multiple Choice
      question.answer_type = "MCQ";
      question.answers = data.next_question.answer.map((ans) => {
        const definition = Definitions[ans.answer];
        return {
          id: ans.id,
          answer: ans.answer,
          definition,
        };
      });
    } else if ((data.next_question.answer_type = "Objective")) {
      question.answer_type = "Objective";
      question.department = [
        {
          department_id: null,
          department: null,
        },
      ];
      question.followup = [];
      question.answers = data.next_question.answer.map((ans) => ({
        id: ans.id,
        answer: emoji.emojify(ans.answer),
      }));
    } else if (data.next_question.answer_type == "Subjective") {
      question.answer_type = "Subjective";
      question.followup = [];
      question.department = data.department_set;
    }
    questionSet.push(question);
  } else if (data.end_survey === true) {
    question.id = "EndSurvey";
    question.message = data.message;
    questionSet.push(question);
  } else {
    question.id = data.next_question.id;
    question.message = data.next_question.question;
    question.answer_type = data.next_question.answer_type;
    if (
      question.answer_type !== "Objective" &&
      question.answer_type !== "Subjective"
    ) {
      question.answer_type == "Objective";
    }
    if (data.is_follow_up) {
      question.answer_type = "Followup";
    } else {
      question.followup = [];
    }
    question.department = [
      {
        department_id: null,
        department: null,
        editable: false,
      },
    ];
    question.answers = data.next_question.answer.map((ans) => ({
      id: ans.id,
      answer: emoji.emojify(ans.answer),
    }));
    questionSet.push(question);
  }

  return questionSet;
}

const Definitions = {
  Administration:
    "Supports and maintains physical security, Infrastructure, transport and facility for entire Firstsource",
  "Business Transformation":
    "Business Transformation Office enables strategic, operational and technological change by driving Transformation initiatives around Automation, Interaction & Digital Analytics for our Clients, with intent to improve customer experience & engagement, reduce cost and increase revenue",
  "Corporate Communication":
    "Corporate Communication is responsible for organization level communications outside Firstsource and all organization wide communication within the organization",
  Pricing:
    "Pricing department takes a lead role in monitoring pricing effectiveness for proposed solution to clients by setting up the systems that collects the right data and then shares that information with the solutioning teams",
  "HR : Firstsource Academy":
    "Firstsource Academy helps organization and individuals build capability for current and future needs by delivering learning that is relevant, real time and reachable",
  "HR : Performance Enhancement Team (ACE)":
    "The team facilitates performance enhancement process which includes goal cascades and setting the right goals, ongoing reviews and stakeholder feedback and annual performance reviews to help employees perform at their best every single day.",
  "HR : Employee Engagement":
    "Employee Engagement Team is responsible for aligning business objectives with employees and management in designated business units. The team serves as a consultant to management on human resource-related issues. The role of the team is to keep employees engaged , motivated and ultimately leading to high staff retention  ",
  "HR : Compensation & Benefits (Payroll)":
    "Designs employee salary structure and HR policies",
  "HR : Recruitment":
    "Recruitment ensures timely fulfillment of human resource demand as raised by various departments in the organization to meet their requirements",
  "HR : Shared Services":
    "HR shared service is responsible for: process improvement, complete scope of transactional services such as in HR data & analytics, payroll, reports, document management & data for employees across their life cycle",
  "Procurement Global Commercial Team (GCT)":
    "GCT supports the business operations and other functions in the organization by processing their procurement requests by sourcing right products and services with best negotiations and timely deliveries",
  WFM: "WFM facilitates assigning of required number of skilled people to each process to function optimally and meet client defined SLAs while making optimal utilization of resources",
  Marketing:
    "Marketing is the face of the organizations as it builds and maintains the brand as per the organization's vision and mission",
  Software:
    "Responsible for supporting all existing FSL's internal software platforms and also supports various functions by developing or enhancing different software tools to meet client requirements",
  "Pre Sales":
    "Pre Sales team – Bid managers  ensures proper project management of the sales opportunities (RFX) and Proposal Writing as per the inputs of the core bid team.",
  Transition:
    "Transition team ensures timely and accurate migration of knowledge, systems, and operating capabilities from client or vendor to Firstsource",
  Operation:
    "Operations is mainly concerned with planning, organising, directing and controlling all the activities of an organisation which helps in converting inputs / requests from customer / client into valuable services for satisfying customer needs",
  Technology:
    "Responsible for the Technology infrastructure like hardware and networking of computer systems in the organization",
  "Solutions Architect":
    "Solution Architect team design the solution by effectively mapping Firstsource products and services in order to get the solution that adds value to the clients",
  "Service Excellence - Training & Quality":
    "Each program has dedicated quality team that is responsible for monitoring and coaching the staff to ensure continuous improvement of procedures, processes within the scope of business as well as value add/feedback to clients to influence overall customer experience improvements. Training team supports end to end training for new hires and existing staff across all programs.New hire training includes Pre- process, Process and product training and OJT.BAU staff is supported through refreshers and one on one coaching",
  "Software Ops":
    "Responsible for supporting all existing FSL's internal software platforms and also supports various functions by developing or enhancing different software tools to meet client requirements",
  "Quality Assurance":
    "Each program has dedicated quality team that is responsible for monitoring and coaching the staff to ensure continuous improvement of procedures, processes within the scope of business as well as value add/feedback to clients to influence overall customer experience improvements.",
  "Legal & Compliance(Mortgage)":
    "Responsible to ensure all the activities, processes, procedures and company policies etc. are in agreement with the laws of the land",
  "Legal & Compliance(Payer)":
    "Responsible to ensure all the activities, processes, procedures and company policies etc. are in agreement with the laws of the land",
  "CM Int Training":
    "Training team supports end to end training for new hires and existing staff across all programs. New hire training includes Pre-process, Process and product training and OJT. BAU staff is supported through refreshers and one on one coaching",
  "Solutions Design & Transition":
    "Transition team ensures timely and accurate migration of knowledge, systems, and operating capabilities from client or vendor to Firstsource. This team also handles all internal projects and co-ordinates BCRs. This function is also responsible for the pre-sales efforts, co-ordinating and facilitating the submission of RFP responses.",
  "Operations Excellence":
    "This team supports Training and Project Implementation",
  Training:
    "Training team supports end to end training for new hires and existing staff across all programs. New hire training includes Pre-process, Process and product training and OJT. BAU staff is supported through refreshers and one on one coaching",
  "CM Int Service Quality":
    "Each program has dedicated quality team that is responsible for monitoring and coaching the staff to ensure continuous improvement of procedures, processes within the scope of business as well as value add/feedback to clients to influence overall customer experience improvements.",
  "Sales & Client Relations":
    "Client Relations team liaises between Firstsource and its clients for development of relationships with client, by providing guidance & advice to Internal functions and ensuring all of the clients' needs are being answered by relevant teams",
  "Finance (Business Finance, Revenue Assurance)":
    "Responsible for recording and monitoring all the monetary transactions that takes place across the organization",
  "Complaints Management": "Collections Complaints Management Team",
  "CM Int Process Excellence":
    "Facilitates or drives process improvement projects or initiatives around efficiency, effectiveness and client value add through process reengineering, automation and various process improvement tools and techniques i.e. Six Sigma, Lean etc.",
  "Ops Analytics & Reporting": "Provider Ops Analytics & Reporting Team",
  Analytics:
    "Providing business insights and solution through big data analysis",
  "CM Int Ops":
    "Operations is mainly concerned with planning, organising, directing and controlling all the activities of an organisation which helps in converting inputs / requests from customer / client into valuable services for satisfying customer needs",
  "Finance (Business Finance)":
    "Responsible for recording and monitoring all the monetary transactions that takes place across the organization",
  EDI: "Provider EDI Team",
  "Client Compliance, Audit & Change Control":
    "Collections Client Compliance, Audit & Change Control Team",
  "Client Relations - CM Int":
    "Client Relations team liaises between Firstsource and its clients for development of relationships with client, by providing guidance & advice to Internal functions and ensuring all of the clients' needs are being answered by relevant teams",
  "Med Assist Sales Client Services & Marketing":
    "Med Assist Sales Client Services & Marketing Team",
  "Client Relations - File Processing, Correspondence":
    "Collections Client Relations - File Processing, Correspondence etc. Team",
  "Business Finance":
    "Business Finance work closely with a particular business unit creating a real and active partnership with both operations and management. Their role is to provide regular support and analysis, to be a trusted adviser and to add value that will assist in decision making. They also provide financial services pertaining to their respective businesses like revenue forecasting, budgeting, P&L preparation and MIS of respective account's performance",
  "HR : Compensations & Benefits (Payroll)":
    "Designs employee salary structure and HR policies.",
  "Procurement Global Commercial Team (GCT) ":
    "GCT supports the business operations and other functions in the organization by processing their procurement requests by sourcing right products and services with best negotiations and timely deliveries.",
  "Client Relations - File Processing, Correspondence etc.":
    "Collections Client Relations - File Processing, Correspondence etc. Team",
};
