import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Option from "./Option";
import OptionElement from "./OptionElement";
import Options from "./Options";
import OptionsStepContainer from "./OptionsStepContainer";
import emoji from "node-emoji";
import { mapImage } from "./mapImage";

const labelImages = {
  slightly_frowning_face:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/slightly_frowning_face.png",
  slightly_smiling_face:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/slightly_smiling_face.png",
  neutral_face:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/neutral_face.png",
  cry: "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/cry.png",
  smiley:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/smiley.png",
};

class OptionsStep extends Component {
  /* istanbul ignore next */
  constructor(props) {
    super(props);

    this.renderOption = this.renderOption.bind(this);
    this.onOptionClick = this.onOptionClick.bind(this);
  }

  onOptionClick({ value }) {
    this.props.triggerNextStep({ value });
  }

  renderOption(option) {
    const { bubbleStyle } = this.props;
    const { user } = this.props.step;
    const { value, label } = option;

    return (
      <Option key={value} className="rsc-os-option">
        <OptionElement
          className="rsc-os-option-element"
          style={
            process.env.NODE_ENV === "staging"
              ? { background: "#1ec1ac" }
              : bubbleStyle
          }
          user={user}
          onClick={() => this.onOptionClick({ value })}
        >
          {/* {mapImage(label)} */}

          {label === emoji.emojify(":cry:") ? (
            <img style={{ width: 30, height: 30 }} src={labelImages.cry} />
          ) : label.replace(/\s/g, "") ===
            emoji.emojify(":slightly_frowning_face:") ? (
            <img
              style={{ width: 30, height: 30 }}
              src={labelImages.slightly_frowning_face}
            />
          ) : label === emoji.emojify(":neutral_face:") ? (
            <img
              style={{ width: 30, height: 30 }}
              src={labelImages.neutral_face}
            />
          ) : label === emoji.emojify(":slightly_smiling_face:") ? (
            <img
              style={{ width: 30, height: 30 }}
              src={labelImages.slightly_smiling_face}
            />
          ) : label === emoji.emojify(":smiley:") ? (
            <img style={{ width: 30, height: 30 }} src={labelImages.smiley} />
          ) : mapImage(label).includes("https") ? (
            <img style={{ width: 80, height: 80 }} src={mapImage(label)} />
          ) : (
            mapImage(label)
          )}
        </OptionElement>
      </Option>
    );
  }

  render() {
    const { options } = this.props.step;
    return (
      <OptionsStepContainer className="rsc-os">
        <Options className="rsc-os-options">
          {_.map(options, this.renderOption)}
        </Options>
      </OptionsStepContainer>
    );
  }
}

OptionsStep.propTypes = {
  step: PropTypes.object.isRequired,
  triggerNextStep: PropTypes.func.isRequired,
  bubbleStyle: PropTypes.object.isRequired,
};

export default OptionsStep;
