import React, { Component } from 'react';
import DepartmentOptionStyle from './DepartmentOptionStyle';

class SubmitButton extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <DepartmentOptionStyle
        optionColor={this.props.optionColor}
        fontColor={this.props.fontColor}
        onClick={() => this.props.onClick()}
      >
        Submit
      </DepartmentOptionStyle>
    );
  }
}
export default SubmitButton;
