import styled from 'styled-components';

const ImageContainer = styled.div`
  display: inline-block;
  order: ${props => props.user ? '1' : '0'};
  padding: 6px;
  margin-left: ${props => props.user ? '0px' : '48px'};
  margin-right: ${props => props.user ? '48px' : '0px'};
  @media screen and (max-width: 450px){
   margin-left: 0px;
  margin-right:0px
}
@media screen and (min-width:1883px){
	
}
`;

export default ImageContainer;
