import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Cookies from "universal-cookie";
import MultipleSelect from "./MultipleSelect";
import MultipleElement from "./MultipleElement";
import MultipleSelects from "./MultipleSelects";
import MultipleStepContainer from "./MultipleStepContainer";
import SubmitButton from "./SubmitButton";
import emoji from "node-emoji";

import { mapImage } from "../options/mapImage";
const labelImages = {
  slightly_frowning_face:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/slightly_frowning_face.png",
  slightly_smiling_face:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/slightly_smiling_face.png",
  neutral_face:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/neutral_face.png",
  cry:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/cry.png",
  smiley:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/smiley.png",
};
class MultipleSelectsStep extends Component {
  /* istanbul ignore next */
  constructor(props) {
    super(props);
    this.state = {
      isSelected: {},
      output: "",
      hide: false,
      filteredOptions: [],
    };
    this.renderMultipleSelect = this.renderMultipleSelect.bind(this);
    this.onMultipleSelectClick = this.onMultipleSelectClick.bind(this);
    this.submitOutput = this.submitOutput.bind(this);
  }

  // onMultipleSelectClick({ value, label }) {
  //   if (!this.state.filteredOptions.includes(label)) {
  //     this.state.filteredOptions = [...this.state.filteredOptions, label];
  //   } else if (this.state.filteredOptions.includes(label)) {
  //     let index = this.state.filteredOptions.indexOf(label);
  //     this.state.filteredOptions.splice(index, 1);
  //   }
  //   const newState = Object.assign({}, this.state.isSelected);
  //   newState[value] = !this.state.isSelected[value];
  //   const keys = Object.keys(newState);
  //   const output = keys.reduce((acc, val) => {
  //     if (newState[val] === true) {
  //       if (acc !== "") {
  //         acc = acc.concat(",");
  //       }
  //       acc += val;
  //     }
  //     return acc;
  //   }, "");

  //   this.setState({
  //     isSelected: newState,
  //     output,
  //     filteredOptions: this.state.filteredOptions
  //   });
  // }

  onMultipleSelectClick({ value }) {
    const newState = Object.assign({}, this.state.isSelected);
    newState[value] = !this.state.isSelected[value];
    const keys = Object.keys(newState);
    const output = keys.reduce((acc, val) => {
      if (newState[val] === true) {
        if (acc !== "") {
          acc = acc.concat(",");
        }
        acc += val;
      }
      return acc;
    }, "");
    this.setState({
      isSelected: newState,
      output,
    });
  }

  // submitOutput() {
  //   this.setState({
  //     hide: true
  //   });
  //   this.props.triggerNextStep({
  //     value: this.state.output,
  //     label: this.state.filteredOptions
  //   });
  // }

  submitOutput() {
    this.setState({
      hide: true,
    });
    console.log(this.state.output);
    this.props.triggerNextStep({ value: this.state.output });
  }

  renderMultipleSelect(multipleSelect) {
    const { selected } = this.props.step;
    const { value, label } = multipleSelect;
    this.state.isSelected[value] = this.state.isSelected[value]
      ? this.state.isSelected[value]
      : selected;
    return (
      <MultipleSelect key={value} className="rsc-os-multiple-select">
        <MultipleElement
          className="rsc-os-multiple-select-element"
          onClick={() => this.onMultipleSelectClick({ value })}
          bubbleColor={this.state.isSelected[value] ? "#0084ff" : "#f1f0f0"}
        >
          {label === emoji.emojify(":cry:") ? (
            <img style={{ width: 30, height: 30 }} src={labelImages.cry} />
          ) : label === emoji.emojify(":slightly_frowning_face:") ? (
            <img
              style={{ width: 30, height: 30 }}
              src={labelImages.slightly_frowning_face}
            />
          ) : label === emoji.emojify(":neutral_face:") ? (
            <img
              style={{ width: 30, height: 30 }}
              src={labelImages.neutral_face}
            />
          ) : label === emoji.emojify(":slightly_smiling_face:") ? (
            <img
              style={{ width: 30, height: 30 }}
              src={labelImages.slightly_smiling_face}
            />
          ) : label === emoji.emojify(":smiley:") ? (
            <img style={{ width: 30, height: 30 }} src={labelImages.smiley} />
          ) : mapImage(label).includes("https") ? (
            <div style={{ justifyContent: "center", alignItems: "center" }}>
              <img
                style={{ width: 70, height: 65 }}
                src={mapImage(label)}
                alt={label}
              />
              {/*<p style={{ width: "65px", wordWrap: "break-word" }}>{label}</p>*/}
            </div>
          ) : (
            mapImage(label)
          )}
        </MultipleElement>
      </MultipleSelect>
    );
  }

  render() {
    let { options, id } = this.props.step;
    let outputs = this.state.output.split(",");
    return (
      <MultipleStepContainer>
        <MultipleSelects className="rsc-os-options">
          {_.map(options, this.renderMultipleSelect)}
        </MultipleSelects>
        <MultipleSelects
          style={{ alignItems: "center", justifyContent: "center" }}
          className="rsc-os-options"
        >
          {this.state.hide || this.state.output == "" ? null : (
            <SubmitButton
              bubbleColor={this.props.step.bubbleColor}
              fontColor={this.props.step.fontColor}
              onClick={this.submitOutput}
            />
          )}
        </MultipleSelects>
      </MultipleStepContainer>
    );
  }
}

MultipleSelectsStep.propTypes = {
  step: PropTypes.object.isRequired,
  triggerNextStep: PropTypes.func.isRequired,
  bubbleStyle: PropTypes.object.isRequired,
};

export default MultipleSelectsStep;
