import styled from 'styled-components';
import { invalidInput } from './common/animations';

const InputNumber = styled.input.attrs({
  maxLength: '10',
})`
  animation: ${props => (props.invalid ? `${invalidInput} .2s ease` : '')};
  border: 1px solid black;
  border-radius: 5px;
  border-top: ${props => (props.invalid ? '0' : '1px solid #eee')};
  box-shadow: ${props => (props.invalid ? 'inset 0 0 2px #b24442' : 'none')};
  box-sizing: border-box;
  color: ${props => (props.invalid ? '#b24442' : '')};
  font-size: 16px;
  opacity: ${props => (props.disabled && !props.invalid ? '.5' : '1')};
  outline: none;
  padding: ${props => (props.hasButton ? '16px 52px 16px 10px' : '16px 10px')};
  width: 60%;
  margin-left:148px;
  -webkit-appearance: none;

  &:disabled { background: #fff; }
  }

  @media screen and (min-width:993px)
  {
    width: 54%;
  }

  @media screen and (min-width:1100px)
  {
    width: 58%;
  }

  @media screen and (min-width:1250px)
  {
    width: 62%;
  }

  @media screen and (max-width:910px)
  {
    width: 55%;
  }

  @media screen and (max-width:740px)
  {
    width: 52%;
  }

  @media screen and (max-width:650px)
  {
    width: 42%;
  }

  @media screen and (max-width:450px)
  {
    width: 66%;
    margin-left:10%;
  }

  @media screen and (max-width:374px)
  {
    width: 42%;
  }

  @media screen and (max-width:374px)
  {
    width: 63%;
  }
  
  @media screen and (min-width:1400px){
    width: 65%;
    font-size: 28px;
  }

  @media screen and (min-width:1750px){
    width: 74%;
    font-size: 28px;
  }

  @media screen and (min-width:1951px){
    width: 79%;
    font-size: 28px;
  }

  @media screen and (min-width:2450px){
    width: 80%;
    font-size: 28px;
  }
`;

export default InputNumber;
