import styled from "styled-components";

const DepartmentAnswerStyle = styled.div`
  margin-left: 10px;
  margin-top: ${window.innerWidth > 500 ? 0 : "10px"};
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  background-color: ${(props) => props.optionColor};
  color: ${(props) =>
    props.optionColor === props.theme.optionSelected
      ? props.theme.optionNotSelected
      : props.theme.optionSelected};
  padding: 5px 10px;
  font-size: 1rem;
  font-weight: 600;
  border: 2px solid
    ${(props) =>
      props.optionColor === props.theme.optionSelected
        ? props.theme.optionNotSelected
        : props.theme.optionSelected};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    filter: brightness(75%);
  }
`;

export default DepartmentAnswerStyle;
