import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Random from "random-id";
const axios = require("axios");
import { SubmitIcon, MicIcon, CloseIcon, ChatIcon } from "./icons";

import { withRouter } from "react-router-dom";
import MicRecorder from "mic-recorder-to-mp3";
const axiosConfig = require("./components/AxiosConfig/AxiosConfig");
import { Send2Server } from "./view/Main.jsx";
import {
  CustomStep,
  OptionsStep,
  TextStep,
  MultipleSelectStep,
  DatesStep,
} from "./steps";
import schema from "./schemas/schema";
import * as storage from "./storage";
import ChatBotContainer from "./ChatBotContainer";
import Content from "./Content";
import Header from "./Header";
import InputText from "./InputText";
import InputNumber from "./InputNumber";
import InputEmail from "./InputEmail";
import HeaderTitle from "./HeaderTitle";
import HeaderIcon from "./HeaderIcon";
import FloatButton from "./FloatButton";
import Footer from "./Footer";
import TextAreaInput from "./Input";
import SubmitButton from "./SubmitButton";
import Recognition from "./recognition";
import Cookies from "universal-cookie";
const cookies = new Cookies();

function getUserIcon() {
  const cId = cookies.get("cid");
  if (process.env.NODE_ENV === "development") {
    switch (parseInt(cId)) {
      case 16:
        return "https://i.imgur.com/AKkRM2b.png";
      case 4:
        return "https://i.imgur.com/AeqUYTw.jpeg";

      default:
        return "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/userIcon.png";
    }
  }
  if (process.env.NODE_ENV === "production") {
    switch (parseInt(cId)) {
      case 9:
        return "https://i.imgur.com/AKkRM2b.png";
      case 3:
        return "https://i.imgur.com/AeqUYTw.jpeg";

      default:
        return "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/userIcon.png";
    }
  }
}

function getBotIcon() {
  const cId = cookies.get("cid");
  if (process.env.NODE_ENV === "development") {
    switch (parseInt(cId)) {
      case 4:
        return "https://i.imgur.com/Fm9ojhD.jpg";

      default:
        return "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/xanenewIcon.png";
    }
  }
  if (process.env.NODE_ENV === "production") {
    switch (parseInt(cId)) {
      case 3:
        return "https://i.imgur.com/Fm9ojhD.jpg";

      default:
        return "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/xanenewIcon.png";
    }
  }
}

// const xaneNewIcon =
//   "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/xanenewIcon.png";
const hgs_icon =
  "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/HGS+Logo+%2B+Chat.001+(1).png";
//import xaneNewIcon from "./icons/xanenew.png";
// const userIcon = getUserIcon();
const userIcon = getUserIcon();
("https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/userIcon.png");
//import { SubmitIcon } from "./icons";
import "./Styles/Chatbot.css";

import AWS from "aws-sdk";
import { shuffle } from "./common/shuffle";

var randomstring = require("randomstring");
const s3 = new AWS.S3({
  accessKeyId: "AKIAQ7HV3E2EZCAWSZVA",
  secretAccessKey: "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",
});
import $ from "jquery";
const Mp3Recorder = new MicRecorder({ bitRate: 128 });
// const cookies = new Cookies();
const xaneNewIcon = getBotIcon();
class ChatBot extends Component {
  /* istanbul ignore next */
  constructor(props) {
    super(props);

    this.state = {
      renderedSteps: [],
      previousSteps: [],
      currentStep: {},
      previousStep: {},
      steps: {},
      disabled: true,
      opened: props.opened || !props.floating,
      inputValue: "",
      inputInvalid: false,
      speaking: false,
      recognitionEnable: props.recognitionEnable && Recognition.isSupported(),
      defaultUserSettings: {},
      isRecording: false,
      blobURL: "",
      isBlocked: false,
      filteredOptions: null,
      inputType: null,
      valid: false,
      isModalVisible: false,
    };

    this.renderStep = this.renderStep.bind(this);
    this.getTriggeredStep = this.getTriggeredStep.bind(this);
    this.generateRenderedStepsById = this.generateRenderedStepsById.bind(this);
    this.triggerNextStep = this.triggerNextStep.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onRecognitionChange = this.onRecognitionChange.bind(this);
    this.onRecognitionEnd = this.onRecognitionEnd.bind(this);
    this.onRecognitionStop = this.onRecognitionStop.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmitButton = this.handleSubmitButton.bind(this);
  }

  componentDidUpdate() {
    this.scrollBottom();
  }

  scrollBottom = () => {
    var objDiv = document.querySelector(".rsc-content");
    objDiv.scrollTop = objDiv.scrollHeight;
  };

  componentWillMount() {
    const {
      botDelay,
      botAvatar,
      cache,
      cacheName,
      customDelay,
      userAvatar,
      userDelay,
    } = this.props;
    const steps = {};

    const defaultBotSettings = { delay: botDelay, avatar: botAvatar };
    const defaultUserSettings = { delay: userDelay, avatar: userAvatar };
    const defaultCustomSettings = { delay: customDelay };
    const total = this.props.steps.filter(
      (obj) => typeof obj.id === "string" && obj.id.includes("ques_")
    ).length;

    for (let i = 0, len = this.props.steps.length; i < len; i += 1) {
      const step = this.props.steps[i];
      let settings = {};

      if (step.user) {
        settings = defaultUserSettings;
      } else if (step.message || step.asMessage) {
        settings = defaultBotSettings;
      } else if (step.component) {
        settings = defaultCustomSettings;
      }
      settings.delay = step.animation;

      steps[step.id] = Object.assign({}, settings, schema.parse(step));
    }

    schema.checkInvalidIds(steps);

    const firstStep = this.props.steps[0];

    if (firstStep.message) {
      const message = firstStep.message;
      firstStep.message = typeof message === "function" ? message() : message;
      steps[firstStep.id].message = firstStep.message;
    }

    const { currentStep, previousStep, previousSteps, renderedSteps } =
      storage.getData(
        {
          cacheName,
          cache,
          firstStep,
          steps,
        },
        () => {
          // focus input if last step cached is a user step
          this.setState({ disabled: false }, () => {
            // this.input.focus();
          });
        }
      );

    this.setState({
      currentStep,
      defaultUserSettings,
      previousStep,
      previousSteps,
      renderedSteps,
      steps,
      total,
      filteredOptions:
        cookies.get("cid") == 218 &&
        process.env.NODE_ENV === "development" &&
        JSON.parse(localStorage.getItem("filteredOptions"))
          ? JSON.parse(localStorage.getItem("filteredOptions"))
          : [
              "Britannia_5050",
              "Britannia_Bourbon",
              "Oreo",
              "Parle_G",
              "Sunfeast_Bounce",
              "Sunfeast_Dark_Fantasy_Chocofills",
              "Parle_HideAndSeek_Chocochip_Cookies",
              "Sunfeast_Farmlite",
              "Britannia_Marie_Gold",
              "Sunfeast_Marie_Light",
              "Britannia_GoodDay",
              "Britannia_Nutrichoice",
              "Parle_HappyHappy_Cookies",
              "Sunfeast_Moms_Magic",
              "Parle_Magix_Kreams",
              "Parle_Monaco",
              "Parle_2020",
            ],
    });
  }

  componentDidMount() {
    {
      process.env.NODE_ENV === "development" &&
        cookies.get("cid") == 218 &&
        navigator.getUserMedia(
          { audio: true },
          () => {
            this.setState({ isBlocked: false });
          },
          () => {
            this.setState({ isBlocked: true });
          }
        );
    }

    const { recognitionEnable } = this.state;
    const { recognitionLang } = this.props;
    if (recognitionEnable) {
      this.recognition = new Recognition(
        this.onRecognitionChange,
        this.onRecognitionEnd,
        this.onRecognitionStop,
        recognitionLang
      );
    }
    this.state.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type == "childList") {
          for (let i = 0; i < mutation.addedNodes.length; i++) {
            mutation.target.scrollTop = mutation.target.scrollHeight;
          }
        }
      });
    });
    this.state.observer.observe(this.content, { childList: true });
  }

  componentWillUpdate(nextProps, nextState) {
    // console.log("updated: "+JSON.stringify(nextProps.steps));
    const { opened, steps } = nextProps;
    // if(steps !== nextState.steps){
    // this.setState({ steps });
    // }
    if (opened !== undefined && opened !== nextState.opened) {
      this.setState({ opened });
    }
  }

  componentWillUnmount() {
    this.state.observer.disconnect();
  }

  onRecognitionChange(value) {
    this.setState({ inputValue: value });
  }

  onRecognitionEnd() {
    this.setState({ speaking: false });
    this.handleSubmitButton();
  }

  onRecognitionStop() {
    this.setState({ speaking: false });
  }

  onValueChange(event) {
    if (this.state.inputType === "Number") {
      const inputValue = event.target.validity.valid
        ? event.target.value
        : this.state.inputValue;
      this.setState({ inputValue });
    } else if (this.state.inputType === "Email") {
      this.setState({
        inputValue: event.target.value,
        valid: event.target.validity.valid && event.target.value.length > 0,
      });
    } else {
      this.setState({ inputValue: event.target.value });
    }
  }

  getTriggeredStep(trigger, value) {
    const steps = this.generateRenderedStepsById();
    return typeof trigger === "function" ? trigger({ value, steps }) : trigger;
  }

  getStepMessage(message) {
    const { previousSteps } = this.state;
    const lastStepIndex =
      previousSteps.length > 0 ? previousSteps.length - 1 : 0;
    const steps = this.generateRenderedStepsById();
    const previousValue = previousSteps[lastStepIndex].value;
    return typeof message === "function"
      ? message({ previousValue, steps })
      : message;
  }

  redirectToForm = () => {
    cookies.set("cid", 64);
    this.props.history.push("/form");
  };

  generateRenderedStepsById() {
    const { previousSteps } = this.state;
    const steps = {};

    for (let i = 0, len = previousSteps.length; i < len; i += 1) {
      const { id, message, value } = previousSteps[i];
      steps[id] = { id, message, value };
    }

    return steps;
  }

  async triggerNextStep(data) {
    const { defaultUserSettings, previousSteps, renderedSteps, steps } =
      this.state;

    let { currentStep, previousStep } = this.state;
    const isEnd = currentStep.end;
    if (currentStep.tag === "Contact Number") {
      this.setState({
        inputType: "Number",
      });
    } else if (currentStep.tag === "Email") {
      this.setState({
        inputType: "Email",
      });
    } else if (previousStep.tag === "Contact Number") {
      this.props.verifyOTP(currentStep.value);
      this.setState({
        inputType: null,
      });
    } else {
      this.setState({
        inputType: null,
      });
    }
    if (data && data.value && typeof data.value !== "string") {
      currentStep.value = data.value;
    }

    if (
      data &&
      (typeof data.value === "string" || typeof data.value === "undefined")
    ) {
      if (data.value == "" || typeof data.value === "undefined") {
        currentStep.value = currentStep.options[0].value;
        currentStep.value = currentStep.value.toString();
      } else {
        currentStep.value = data.value;
      }
    }
    if (data && data.trigger) {
      currentStep.trigger = this.getTriggeredStep(data.trigger, data.value);
    }

    if (currentStep.inputDate) {
      const step = {
        user: true,
        message: data.value,
        value: data.value,
      };
      delete currentStep.inputDate;
      currentStep = Object.assign({}, currentStep, step, defaultUserSettings);

      renderedSteps.pop();
      previousSteps.pop();
      renderedSteps.push(currentStep);
      previousSteps.push(currentStep);
      // console.log(JSON.stringify(currentStep));
      this.setState({
        currentStep,
        renderedSteps,
        previousSteps,
      });
    } else if (isEnd) {
      localStorage.removeItem("filteredOptions");
      //localStorage.removeItem("globalOptions");
      this.handleEnd();
      //this.redirectToForm();
    } else if (currentStep.options && data) {
      // console.log(currentStep.options);
      let option;
      if (typeof data.value !== "string") {
        option = currentStep.options.filter((o) => o.value === data.value)[0];
      } else {
        option = currentStep.options.filter(
          (o) => o.value == data.value.split(",")[0]
        )[0];
      }
      const trigger = this.getTriggeredStep(option.trigger, currentStep.value);
      currentStep.multipleChoice = false;
      if (typeof data.value === "string") {
        const val = data.value.split(",");
        let label = "";
        for (let i = 0; i < val.length; i += 1) {
          currentStep.options
            .filter((o) => o.value == val[i])
            .reduce((acc, val) => (label += `${val.label}\n`), label);
        }
        label.substring(0, label.length - 2);
        option.label = label;
      }
      delete currentStep.options;
      // console.log(option.label)

      // replace choose option for user message
      currentStep = Object.assign(
        {},
        currentStep,
        option,
        defaultUserSettings,
        {
          user: true,
          message: option.label,
          trigger,
        }
      );
      currentStep.value = data.value;
      renderedSteps.pop();
      previousSteps.pop();
      renderedSteps.push(currentStep);
      previousSteps.push(currentStep);
      this.setState(
        {
          currentStep,
          renderedSteps,
          previousSteps,
          filteredOptions: cookies.get("cid") == 218 && data.label,
        },
        () => {
          localStorage.setItem(
            "filteredOptions",
            JSON.stringify(this.state.filteredOptions)
          );
        }
      );
    } else if (currentStep.trigger) {
      if (currentStep.replace) {
        renderedSteps.pop();
      }
      let score = null;
      let surveyId = null;
      if (currentStep.trigger == "score") {
        const { Score, SurveyId } = await this.props.getScore();
        score = Score;
        surveyId = SurveyId;
      }

      const trigger = this.getTriggeredStep(
        currentStep.trigger,
        currentStep.value
      );
      let nextStep = Object.assign({}, steps[trigger]);

      if (nextStep.message) {
        nextStep.message = this.getStepMessage(nextStep.message);
      } else if (nextStep.update) {
        const updateStep = nextStep;
        nextStep = Object.assign({}, steps[updateStep.update]);

        if (nextStep.options) {
          for (let i = 0, len = nextStep.options.length; i < len; i += 1) {
            nextStep.options[i].trigger = updateStep.trigger;
          }
        } else {
          nextStep.trigger = updateStep.trigger;
        }
      }

      if (nextStep.id == "score") {
        const message = nextStep.component.props.message.replace(
          /\[\[.*\]\]/g,
          score
        );
        delete nextStep.component;
        nextStep.component = (
          <Send2Server
            loading_func={false}
            surveyId={surveyId}
            step_id={nextStep.id}
            trigger={nextStep.trigger}
            message={message}
          />
        );
        nextStep.asMessage = true;
        nextStep.waitAction = true;
        delete nextStep.message;
      }
      if (nextStep.id == "report") {
        let message;
        switch (this.state.score) {
          case "High Risk":
            message =
              "This essentially means you are a brave person. You always fight back for what you consider is right and are not scared of challenges. So what it may mean is that sometimes you may get into trouble. Our two cents: It is okay to step back sometimes. Stay Safe!";
            break;
          case "Moderate Risk":
            message =
              "This essentially means you often engage in confrontation and fight back for what you believe in even during physically threatening situations. And sometimes there is confusion if you should step away. Always consider your safety as a primary objective and all your activities should be directed towards getting out of the situation and get to a safer place.";
            break;
          case "Mild Risk":
            message =
              "This essentially means you usually avoid confrontation and hence mostly is in the safe zone when comes to physical threats. You may have to sometimes fight back, speak up for yourself once you are in a safe zone. ";
            break;
          case "Ideal Risk":
            message =
              "Bravo! You know how to handle a hostile situation and diffuse it. You know when to fight back and when to step away. ";
            break;
        }
        delete nextStep.component;
        nextStep.component = (
          <Send2Server
            loading_func={false}
            surveyId={surveyId}
            step_id={nextStep.id}
            trigger={nextStep.trigger}
            message={message}
          />
        );
        nextStep.asMessage = true;
        nextStep.waitAction = true;
        delete nextStep.message;
      }

      nextStep.key = Random(24);

      previousStep = currentStep;
      currentStep = nextStep;

      this.setState({ renderedSteps, currentStep, previousStep }, () => {
        if (nextStep.user && !nextStep.answered) {
          this.setState({ disabled: false }, () => {
            //this.input.focus();
          });
        } else {
          renderedSteps.push(nextStep);
          previousSteps.push(nextStep);
          if (score !== null) {
            this.setState({ renderedSteps, previousSteps, score });
          }
          this.setState({ renderedSteps, previousSteps });
        }
      });
    }

    const { cache, cacheName } = this.props;
    if (cache) {
      setTimeout(() => {
        storage.setData(cacheName, {
          currentStep,
          previousStep,
          previousSteps,
          renderedSteps,
        });
      }, 300);
    }
  }

  handleEnd() {
    if (this.props.handleEnd) {
      const { previousSteps } = this.state;

      const renderedSteps = previousSteps.map((step) => {
        const { id, message, value } = step;
        return { id, message, value };
      });

      const steps = [];

      for (let i = 0, len = previousSteps.length; i < len; i += 1) {
        const { id, message, value } = previousSteps[i];
        steps[id] = { id, message, value };
      }

      const values = previousSteps
        .filter((step) => step.value)
        .map((step) => step.value);

      this.props.handleEnd({ renderedSteps, steps, values });
    }
  }

  isLastPosition(step) {
    const { renderedSteps } = this.state;
    const length = renderedSteps.length;
    const stepIndex = renderedSteps.map((s) => s.key).indexOf(step.key);

    if (length <= 1 || stepIndex + 1 === length) {
      return true;
    }

    const nextStep = renderedSteps[stepIndex + 1];
    const hasMessage = nextStep.message || nextStep.asMessage;

    if (!hasMessage) {
      return true;
    }

    const isLast = step.user !== nextStep.user;
    return isLast;
  }

  isFirstPosition(step) {
    const { renderedSteps } = this.state;
    const stepIndex = renderedSteps.map((s) => s.key).indexOf(step.key);

    if (stepIndex === 0) {
      return true;
    }

    const lastStep = renderedSteps[stepIndex - 1];
    const hasMessage = lastStep.message || lastStep.asMessage;

    if (!hasMessage) {
      return true;
    }

    const isFirst = step.user !== lastStep.user;
    return isFirst;
  }

  handleKeyPress(event) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

    if (
      event.key == "Enter" &&
      cookies.get("cid") == 8 &&
      process.env.NODE_ENV == "development"
    ) {
      if (
        (this.state.inputValue.length < 10 &&
          this.state.inputType === "Number") ||
        (!re.test(this.state.inputValue) && this.state.inputType === "Email")
      ) {
        return;
      } else {
        this.handleSubmitButton();
      }
    }

    // else {
    //   event.preventDefault();
    //   let val = this.state.inputValue,
    //     start = event.target.selectionStart,
    //     end = event.target.selectionEnd;

    //   this.setState(
    //     { inputValue: val.substring(0, start) + "\n" + val.substring(end) },
    //     () => {
    //       this.refs.input.selectionStart = this.refs.input.selectionEnd =
    //         start + 1;
    //     }
    //   );
    // }
  }

  handleSubmitButton() {
    const { inputValue, speaking, recognitionEnable } = this.state;
    if ((_.isEmpty(inputValue) || speaking) && recognitionEnable) {
      this.recognition.speak();
      if (!speaking) {
        this.setState({ speaking: true });
      }
      return;
    }
    this.submitUserMessage();
  }

  submitUserMessage() {
    const { defaultUserSettings, inputValue, previousSteps, renderedSteps } =
      this.state;
    let { currentStep } = this.state;
    // console.log("user input: "+JSON.stringify(currentStep));

    const isInvalid = currentStep.validator && this.checkInvalidInput();
    const cid = cookies.get("cid");
    const env = process.env.NODE_ENV;
    const getCids = {
      development: [4],
      production: [3],
    };

    let checkValidationBasedOnCid = getCids[env].includes(Number(cid));
    console.log(checkValidationBasedOnCid, env);
    if (checkValidationBasedOnCid && this.state.inputValue.length < 20) {
      alert("Response must be at least 20 characters long.");
      this.setState({
        isInvalid: true,
      });
      return;
    }

    if (!isInvalid) {
      if (this.state.valid === false && this.state.inputType === "Email") {
        this.props.showAlert("Email is not valid");
        this.setState({
          isInvalid: true,
        });
      } else if (
        this.state.inputValue.length !== 10 &&
        this.state.inputType === "Number"
      ) {
      } else {
        const step = {
          message: inputValue.toString(),
          value: inputValue.toString(),
        };

        currentStep = Object.assign({}, defaultUserSettings, currentStep, step);
        //console.log("user input2: " + JSON.stringify(currentStep));

        renderedSteps.push(currentStep);
        previousSteps.push(currentStep);
        this.setState({
          currentStep,
          renderedSteps,
          previousSteps,
          disabled: true,
          inputValue: "",
        });
      }
    }
  }

  uploadOnS3 = async (result) => {
    const { name, user_id } = cookies.get("user");
    let folder = `${name + user_id}`;
    let fileName = `${randomstring.generate()}.jpg`;
    // let readFileOptions = { encoding: 'utf8' }
    // const fileContent = (params.files.image.data)
    let Location = null;
    let buf = new Buffer(
      result.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    let params = {
      Bucket: `itc-user-media/user-uploads/${folder}`,
      Key: `${fileName}`, // File name you want to save as in S3
      Body: buf,
      ACL: "public-read",
      ContentType: `image/jpeg`,
      ContentEncoding: "base64",
    };
    s3.upload(params, async (err, data) => {
      if (err) {
        throw err;
      }
      Location = await data.Location;
      this.setState({ inputValue: Location });
    });
  };

  checkInvalidInput() {
    const { currentStep, inputValue } = this.state;
    const result = currentStep.validator(inputValue);
    const value = inputValue;

    if (typeof result !== "boolean" || !result) {
      this.setState(
        {
          inputValue: result.toString(),
          inputInvalid: true,
          disabled: true,
        },
        () => {
          setTimeout(() => {
            this.setState(
              {
                inputValue: result.toString(),
                inputInvalid: false,
                disabled: false,
              },
              () => {
                //this.input.focus();
              }
            );
          }, 2000);
        }
      );

      return true;
    }

    return false;
  }

  editPreviousAnswer = async (id) => {
    axiosConfig.headers["x-auth"] =
      window.location.href.includes("hrlogica") == true
        ? await sessionStorage.getItem("x-auth")
        : await cookies.get("x-auth");
    let companyId =
      window.location.href.includes("hrlogica") == true
        ? await sessionStorage.getItem("cid")
        : await Number(cookies.get("cid"));
    let stepId = id.id.split("_")[1];
    axios
      .delete(
        "/users/" + companyId + "/delete_last_answer/" + stepId,
        axiosConfig
      )
      .then(async (res) => {
        if (res.data.affected_rows > 0) {
          let index = this.state.renderedSteps.findIndex(
            (val) => val.id == id.id
          );
          this.state.renderedSteps.splice(
            index,
            this.state.renderedSteps.length - index
          );

          this.setState(
            { renderedSteps: this.state.renderedSteps },
            async () => {
              await this.renderStep(id, stepId);
            }
          );
        }
      });
  };

  handleTakePicture = (e) => {
    var a = new FileReader();

    a.addEventListener("load", async () => {
      this.uploadOnS3(a.result);
    });

    a.readAsDataURL(e.target.files[0]);
  };

  toggleChatBot(opened) {
    if (this.props.toggleFloating) {
      this.props.toggleFloating({ opened });
    } else {
      this.setState({ opened });
    }
  }

  start = () => {
    if (this.state.isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          this.setState({ isRecording: true });
        })
        .catch((e) => console.error(e));
    }
  };

  stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);

        var a = new FileReader();

        a.addEventListener("load", async () => {
          const { name, user_id } = cookies.get("user");
          let folder = `${name + user_id}`;
          let fileName = `${randomstring.generate()}.mp3`;
          // let readFileOptions = { encoding: 'utf8' }
          // const fileContent = (params.files.image.data)
          let buf = new Buffer(
            a.result.replace(/^data:audio\/\w+;base64,/, ""),
            "base64"
          );
          let Location;
          let params = {
            Bucket: `itc-user-media/user-uploads/${folder}`,
            Key: `${fileName}`, // File name you want to save as in S3
            Body: buf,
            ACL: "public-read",
            ContentType: `audio/mp3`,
          };
          s3.upload(params, async (err, data) => {
            if (err) {
              throw err;
            }
            Location = await data.Location;
            this.setState({ inputValue: Location });
          });
        });

        a.readAsDataURL(blob);

        this.setState({ isRecording: false });
      })
      .catch((e) => console.log(e));
  };

  renderStep(step, index) {
    if (step.id < 3738) {
      localStorage.removeItem("filteredOptions");
    }
    if (process.env.NODE_ENV === "development") {
      switch (step.id) {
        case "ques_3755":
          step.delay = 30000;
          break;
        case "ques_3757":
          step.delay = 27000;
          break;
        case "ques_3759":
          step.delay = 12000;
          break;
        case "ques_3761":
          step.delay = 30000;
          break;
        case "ques_3741":
          step.delay = 6000;
          break;
        case "ques_3752":
          step.delay = 6000;
          break;
        default:
          step.delay = 1000;
      }
    }
    const { renderedSteps } = this.state;
    const {
      avatarStyle,
      bubbleStyle,
      customStyle,
      hideBotAvatar,
      hideUserAvatar,
    } = this.props;
    let { options, component, asMessage } = step;
    const steps = this.generateRenderedStepsById();
    const previousStep = index > 0 ? renderedSteps[index - 1] : {};
    // console.log("step: " + JSON.stringify(step));

    if (component && !asMessage) {
      return (
        <CustomStep
          key={index}
          step={step}
          steps={steps}
          style={customStyle}
          previousStep={previousStep}
          triggerNextStep={this.triggerNextStep}
        />
      );
    }

    //console.log("step" + JSON.stringify(step));
    // console.log("steps" + JSON.stringify(steps));
    // console.log("previousStep" + JSON.stringify(previousStep));
    // if (step.multipleChoice === true) {
    //   step.options = shuffle(step.options);
    //   if (
    //     process.env.NODE_ENV === "development" &&
    //     cookies.get("cid") == 218 &&
    //     this.state.filteredOptions
    //   ) {
    //     if (step.id == 3738) {
    //       let globalOptions = [];
    //       this.state.filteredOptions.forEach((value) => {
    //         let index = step.options.findIndex(
    //           (option) => option.label == value
    //         );
    //         globalOptions.push(step.options[index]);
    //       });
    //       step.options = shuffle(globalOptions);
    //       localStorage.setItem(
    //         "globalOptions",
    //         JSON.stringify(this.state.filteredOptions)
    //       );
    //     } else if (step.id == 3739) {
    //       let newOptions = [];
    //       this.state.filteredOptions.forEach((value) => {
    //         let index = step.options.findIndex(
    //           (option) => option.label == value
    //         );
    //         newOptions.push(step.options[index]);
    //       });
    //       step.options = shuffle(newOptions);
    //       localStorage.setItem(
    //         "filteredOptions",
    //         JSON.stringify(this.state.filteredOptions)
    //       );
    //     } else if (step.id == 3740) {
    //       let newOptions = [];
    //       JSON.parse(localStorage.getItem("globalOptions")).forEach((value) => {
    //         let index = step.options.findIndex(
    //           (option) => option.label == value
    //         );
    //         newOptions.push(step.options[index]);
    //       });
    //       step.options = shuffle(newOptions);
    //     } else {
    //       localStorage.removeItem("filteredOptions");
    //     }
    //   }
    //   return (
    //     <MultipleSelectStep
    //       key={index}
    //       step={step}
    //       triggerNextStep={this.triggerNextStep}
    //       bubbleStyle={bubbleStyle}
    //     />
    //   );
    // }

    if (step.multipleChoice === true) {
      return (
        <MultipleSelectStep
          key={index}
          step={step}
          triggerNextStep={this.triggerNextStep}
          bubbleStyle={bubbleStyle}
        />
      );
    }

    if (options) {
      return (
        <OptionsStep
          key={index}
          step={step}
          triggerNextStep={this.triggerNextStep}
          bubbleStyle={bubbleStyle}
        />
      );
    }

    if (step.inputDate) {
      return (
        <DatesStep
          key={index}
          step={step}
          triggerNextStep={this.triggerNextStep}
          bubbleStyle={bubbleStyle}
        />
      );
    }

    return (
      <TextStep
        key={index}
        step={step}
        steps={steps}
        previousStep={previousStep}
        previousValue={previousStep.value}
        triggerNextStep={this.triggerNextStep}
        avatarStyle={avatarStyle}
        bubbleStyle={bubbleStyle}
        hideBotAvatar={hideBotAvatar}
        hideUserAvatar={hideUserAvatar}
        isFirst={this.isFirstPosition(step)}
        isLast={this.isLastPosition(step)}
      />
    );
  }

  render() {
    const {
      disabled,
      inputInvalid,
      inputValue,
      opened,
      renderedSteps,
      speaking,
      recognitionEnable,
    } = this.state;
    const {
      className,
      contentStyle,
      floating,
      footerStyle,
      headerComponent,
      headerTitle,
      hideHeader,
      hideSubmitButton,
      inputStyle,
      placeholder,
      recognitionPlaceholder,
      style,
      submitButtonStyle,
      width,
    } = this.props;

    const header = headerComponent || (
      <Header className="rsc-header">
        <HeaderTitle className="rsc-header-title">{headerTitle}</HeaderTitle>
        {floating && (
          <HeaderIcon
            className="rsc-header-close-button"
            onClick={() => this.toggleChatBot(false)}
          >
            <CloseIcon />
          </HeaderIcon>
        )}
      </Header>
    );

    const icon =
      (_.isEmpty(inputValue) || speaking) && recognitionEnable ? (
        <MicIcon />
      ) : (
        <SubmitIcon />
      );

    let inputPlaceholder = speaking ? recognitionPlaceholder : placeholder;
    if (renderedSteps[renderedSteps.length - 1].tag === "Number") {
      inputPlaceholder = "Phone Number";
    } else if (renderedSteps[renderedSteps.length - 1].tag === "Email") {
      inputPlaceholder = "Email";
    }
    const end = renderedSteps.filter((obj) => obj.id === "endSurvey");
    const completed = renderedSteps.filter(
      (obj) => typeof obj.id === "number" && obj.user === true
    ).length;
    const total = this.state.total;
    let percent = ((completed / total) * 100).toFixed(2);
    if (end.length) {
      percent = 100;
    }

    return (
      <div className={`rsc ${className}`}>
        {floating && (
          <FloatButton
            className="rsc-float-button"
            opened={opened}
            onClick={() => this.toggleChatBot(true)}
          >
            {/* <ChatIcon /> */}
          </FloatButton>
        )}

        <ChatBotContainer
          className="rsc-container"
          floating={floating}
          opened={opened}
          style={style}
          width={width}
          recognitionEnable={true}
        >
          {/* {!hideHeader && header} */}
          {/* <Progress
            color="teal"
            value={completed}
            total={total}
            percent={percent}
            progress
            size="medium"
            style={{ margin: '0', height: '25px' }}
            className="progress"
            active
            autoSuccess
          /> */}
          {cookies.get("cid") == 218 &&
          process.env.NODE_ENV === "development" ? (
            <label htmlFor="file" className="label">
              <i
                className="fa fa-camera"
                style={{
                  fontSize: "2rem",
                  display: "inline",
                  padding: "10px",
                  backgroundColor: "#252525",
                  color: "white",
                  minWidth: "50px",
                  textAlign: "center",
                  position: "fixed",
                  bottom: window.screen.height / 4,
                  right: 30,
                  borderRadius: "40px",
                  opacity: disabled ? 0.5 : 1,
                  zIndex: 999,
                }}
              />
            </label>
          ) : null}
          {!this.state.isRecording &&
          cookies.get("cid") == 218 &&
          process.env.NODE_ENV === "development" ? (
            <label className="label">
              <i
                onClick={!disabled ? this.start : undefined}
                className="fa fa-microphone"
                style={{
                  fontSize: "2rem",
                  display: "inline",
                  padding: "10px",
                  backgroundColor: "#252525",
                  color: "white",
                  minWidth: "50px",
                  textAlign: "center",
                  position: "fixed",
                  bottom: window.screen.height / 5.5,
                  right: 30,
                  borderRadius: "40px",
                  opacity: disabled ? 0.5 : 1,
                  zIndex: 999,
                }}
              />
            </label>
          ) : (
            process.env.NODE_ENV === "development" &&
            cookies.get("cid") == 218 && (
              <label className="label">
                <i
                  onClick={this.stop}
                  className="fa fa-stop-circle"
                  style={{
                    fontSize: "2rem",
                    display: "inline",
                    padding: "10px",
                    backgroundColor: "#252525",
                    color: "white",
                    minWidth: "50px",
                    textAlign: "center",
                    position: "fixed",
                    bottom: window.screen.height / 5.5,
                    right: 30,
                    borderRadius: "40px",
                    opacity: disabled ? 0.5 : 1,
                    zIndex: 999,
                  }}
                />
              </label>
            )
          )}

          <Content
            className="rsc-content"
            innerRef={(contentRef) => (this.content = contentRef)}
            floating={floating}
            style={contentStyle}
          >
            {_.map(renderedSteps, this.renderStep)}
          </Content>

          <Footer
            className="rsc-footer"
            style={{ paddingTop: 6, paddingBottom: 2 }}
          >
            {this.state.inputType === "Number" ? (
              <React.Fragment>
                <InputNumber
                  type="text"
                  pattern={"[0-9]*"}
                  style={inputStyle}
                  innerRef={(inputRef) => (this.input = inputRef)}
                  className="rsc-input"
                  placeholder={inputInvalid ? "" : inputPlaceholder}
                  onKeyPress={this.handleKeyPress}
                  onInput={this.onValueChange}
                  value={inputValue}
                  floating={floating}
                  invalid={inputInvalid}
                  disabled={disabled}
                  hasButton={!hideSubmitButton}
                />
                {!hideSubmitButton && inputValue.length === 10 && (
                  <SubmitButton
                    className="rsc-submit-button"
                    style={submitButtonStyle}
                    onClick={this.handleSubmitButton}
                    invalid={inputInvalid}
                    disabled={disabled}
                    speaking={speaking}
                  >
                    {icon}
                  </SubmitButton>
                )}
              </React.Fragment>
            ) : this.state.inputType === "Email" ? (
              <React.Fragment>
                <InputEmail
                  type="email"
                  style={inputStyle}
                  innerRef={(inputRef) => (this.input = inputRef)}
                  className="rsc-input"
                  placeholder={inputInvalid ? "" : inputPlaceholder}
                  onKeyPress={this.handleKeyPress}
                  onInput={this.onValueChange}
                  value={inputValue}
                  floating={floating}
                  invalid={inputInvalid}
                  disabled={disabled}
                  hasButton={!hideSubmitButton}
                />
                {!hideSubmitButton && (
                  <SubmitButton
                    className="rsc-submit-button"
                    style={submitButtonStyle}
                    onClick={this.handleSubmitButton}
                    invalid={inputInvalid}
                    disabled={disabled}
                    speaking={speaking}
                  >
                    {icon}
                  </SubmitButton>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TextAreaInput
                  type="text"
                  autoFocus={false}
                  rows={1}
                  ref="input"
                  innerRef={(inputRef) => (this.input = inputRef)}
                  className="rsc-input"
                  placeholder={inputInvalid ? "" : inputPlaceholder}
                  onKeyPress={this.handleKeyPress}
                  onChange={this.onValueChange}
                  value={inputValue}
                  floating={floating}
                  invalid={inputInvalid}
                  disabled={disabled}
                  hasButton={!hideSubmitButton}
                />
                `
                {!hideSubmitButton && (
                  <SubmitButton
                    className="rsc-submit-button"
                    style={submitButtonStyle}
                    onClick={this.handleSubmitButton}
                    invalid={inputInvalid}
                    disabled={disabled}
                    speaking={speaking}
                  >
                    {icon}
                  </SubmitButton>
                )}
                `
              </React.Fragment>
            )}

            {cookies.get("cid") == 218 &&
              process.env.NODE_ENV === "development" && (
                <input
                  accept="image/*"
                  onChange={(e) => this.handleTakePicture(e)}
                  capture
                  type="file"
                  name="file"
                  id="file"
                  className="inputfile"
                  disabled={disabled}
                />
              )}
          </Footer>
        </ChatBotContainer>
      </div>
    );
  }
}

ChatBot.propTypes = {
  avatarStyle: PropTypes.object,
  botAvatar: PropTypes.string,
  botDelay: PropTypes.number,
  bubbleStyle: PropTypes.object,
  cache: PropTypes.bool,
  cacheName: PropTypes.string,
  className: PropTypes.string,
  contentStyle: PropTypes.object,
  customDelay: PropTypes.number,
  customStyle: PropTypes.object,
  floating: PropTypes.bool,
  footerStyle: PropTypes.object,
  handleEnd: PropTypes.func,
  headerComponent: PropTypes.element,
  headerTitle: PropTypes.string,
  hideBotAvatar: PropTypes.bool,
  hideHeader: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
  hideUserAvatar: PropTypes.bool,
  inputStyle: PropTypes.object,
  opened: PropTypes.bool,
  toggleFloating: PropTypes.func,
  placeholder: PropTypes.string,
  recognitionEnable: PropTypes.bool,
  recognitionLang: PropTypes.string,
  recognitionPlaceholder: PropTypes.string,
  steps: PropTypes.array.isRequired,
  style: PropTypes.object,
  submitButtonStyle: PropTypes.object,
  userAvatar: PropTypes.string,
  userDelay: PropTypes.number,
  width: PropTypes.string,
};

ChatBot.defaultProps = {
  avatarStyle: {},
  botDelay: 100,
  bubbleStyle: {},
  cache: false,
  cacheName: "rsc_cache",
  className: "",
  contentStyle: {},
  customStyle: {},
  customDelay: 100,
  floating: false,
  footerStyle: {},
  handleEnd: undefined,
  headerComponent: undefined,
  headerTitle: "Chat",
  hideBotAvatar: false,
  hideHeader: false,
  hideSubmitButton: false,
  hideUserAvatar: false,
  inputStyle: {},
  opened: undefined,
  placeholder: "Enter your response...",
  recognitionEnable: false,
  recognitionLang: "en",
  recognitionPlaceholder: "Hearing...",
  style: {},
  submitButtonStyle: {},
  toggleFloating: undefined,
  userDelay: 100,
  width: "350px",
  botAvatar:
    process.env.NODE_ENV == "development" && cookies.get("cid") == 8
      ? hgs_icon
      : xaneNewIcon,
  userAvatar: userIcon,
};

export default withRouter(ChatBot);
