import React from "react";
import { ThemeProvider } from "styled-components";
import { Dimmer, Loader } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";
import ChatBot from "../index";
import SideBar from "../SideBar";
import XaneBar from "../XaneBar";
import convert from "../components/service/converterservice";
import { emojify } from "../components/service/utils";
import emoji from "node-emoji";
import { mapImage } from "../steps/options/mapImage";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const axios = require("axios");
const axiosConfig = require("../components/AxiosConfig/AxiosConfig");
import { welcomeMessage } from "../components/service/welcomeMessage";
import { Image, Modal, Button, Icon } from "semantic-ui-react";
import "./Main.scss";
import { confetti } from "./confetti";
let items = [];
let english = [
  "training",
  "guidance",
  "join",
  "learning",
  "appreciate",
  "incentive",
  "earn",
  "encourage",
  "teamwork",
  "follow",
  "supervisor",
  "feedback",
  "support",
  "development",
  "vision",
  "organisation",
  "recommend",
  "feedback",
  "suggestions",
  "HR",
  "journey",
  "Organisation",
  "perform",
];
console.log(cookies.get("cid"));
const otherFontTheme =
  process.env.NODE_ENV == "staging"
    ? {
        background: "#1c2729",
        fontFamily: "Tahoma, Geneva, sans-serif",
        headerBgColor: "#fff",
        headerFontColor: "#fff",
        headerFontSize: "16px",
        botBubbleColor: "#171c20",
        botFontColor: "white !important",
        userBubbleColor: "#0e5d64",
        userFontColor: "white !important",
      }
    : {
        background: "white",
        fontFamily: "Tahoma, Geneva, sans-serif",
        headerBgColor: "#fff",
        headerFontColor: "#fff",
        headerFontSize: "16px",
        botBubbleColor:
          cookies.get("cid") == 4 || cookies.get("cid") == 3
            ? "red"
            : "#f1f0f0",
        botFontColor: "black",
        userBubbleColor: "#0084ff",
        userFontColor: "white",
      };

const question_id = -1;
const question = "";
const message = "";
let option_id;
let count = 0;

export class Send2Server extends React.Component {
  constructor(props) {
    // console.log(props);
    super(props);
    this.state = {
      message: this.props.get_message
        ? message
        : this.props.get_question
        ? question
        : this.props.message,

      isIncredModalOneVisible: false,
      isIncredModalTwoVisible: false,
      isIncredModalThreeVisible: false,
      isIncredModalFourVisible: false,
    };
  }

  async componentWillMount() {
    const { previousStep } = this.props;
    const triggerCustom = { custom: false };

    if (
      (previousStep.hasOwnProperty("value") && previousStep.value) ||
      option_id != undefined
    ) {
      try {
        const params = {};
        params.question_id = this.props.get_message
          ? question_id
          : previousStep.id;

        if (option_id != undefined) {
          params.answer_id = option_id;
          params.answer_id = params.answer_id.toString();
          option_id = undefined;
        } else if (typeof previousStep.value === "number") {
          // console.log('answer Id', step);
          params.answer_id = previousStep.value;
          params.answer_id = params.answer_id.toString();
        } else if (
          typeof previousStep.value === "string" &&
          this.props.multipleChoice
        ) {
          // console.log('answer Id', step);
          params.answer_id = previousStep.value;
          params.answer_id = params.answer_id.toString();
        } else {
          // console.log('answer', step);
          // console.log("here");
          params.answer = previousStep.value;
        }
        // console.log(option_id);
        // if(option_id!=undefined){
        //   params.answer_id = option_id;
        //   params.answer_id = params.answer_id.toString();
        //   option_id=undefined;
        // }

        axiosConfig.headers["x-auth"] =
          window.location.href.includes("hrlogica") == true
            ? await sessionStorage.getItem("x-auth")
            : await cookies.get("x-auth");
        // console.log(params);
        const res = await axios.post(
          `/surveys/${this.props.surveyId}/answers`,
          params,
          axiosConfig
        );
        console.log(params);
        // console.log("current step: "+this.props.step.id);
        // console.log('Response', JSON.stringify(res.data));
        // res = res.data;
      } catch (error) {
        console.log("Error", error);
      }
    }

    if (triggerCustom.custom) {
      this.props.triggerNextStep({ value: null, trigger: triggerCustom.id });
    } else {
      this.props.triggerNextStep();
    }
  }

  componentDidMount() {
    // console.log("step_id: "+this.props.step_id);
    // console.log("message: "+this.state.message);
    // console.log("trigger: "+this.props.trigger);

    if (this.props.loading_func) {
      this.props.stop_loading();
    }
  }

  getHighlightedText = (text, highlight) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts.map((part) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <b style={{ fontSize: "18px" }}>{part}</b>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  showPopUpOne = () => {
    this.setState({ isIncredModalOneVisible: true });
  };

  handleCloseOne = () => {
    this.setState({ isIncredModalOneVisible: false });
  };
  showPopUpTwo = () => {
    this.setState({ isIncredModalTwoVisible: true });
  };

  handleCloseTwo = () => {
    this.setState({ isIncredModalTwoVisible: false });
  };
  showPopUpThree = () => {
    this.setState({ isIncredModalThreeVisible: true });
  };

  handleCloseThree = () => {
    this.setState({ isIncredModalThreeVisible: false });
  };
  showPopUpFour = () => {
    this.setState({ isIncredModalFourVisible: true });
  };

  handleCloseFour = () => {
    this.setState({ isIncredModalFourVisible: false });
  };

  renderComponent = (survey_id, message) => {
    if (survey_id == 218 && process.env.NODE_ENV === "development") {
      let globalOptions = JSON.parse(localStorage.getItem("globalOptions"))
        ? JSON.parse(localStorage.getItem("globalOptions"))
        : null;

      return message.includes("TB") ? (
        <div style={{ flexDirection: "column", alignItems: "flex-start" }}>
          <p>{message.split(",")[0]}</p>
          <br />

          {message.split(",")[1] && (
            <video
              src={mapImage(message.split(",")[1])}
              width="100%"
              height={200}
              className="video"
              autoPlay
              id={message.split(",")[1]}
            />
          )}
        </div>
      ) : message.includes("DU") ? (
        <div style={{ flexDirection: "column", alignItems: "flex-start" }}>
          <p>{message.split(",")[0]}</p>
          <br />

          {message.split(",")[1] && (
            <video
              src={mapImage(message.split(",")[1])}
              width="100%"
              height={200}
              className="video"
              id={message.split(",")[1]}
              autoPlay
            />
          )}
        </div>
      ) : message.includes("PP") ? (
        <div style={{ flexDirection: "column", alignItems: "flex-start" }}>
          <p>{message.split(",")[0]}</p>
          <br />

          {message.split(",")[1] && (
            <video
              src={mapImage(message.split(",")[1])}
              width="100%"
              height={200}
              className="video"
              id={message.split(",")[1]}
              autoPlay
            />
          )}
        </div>
      ) : message.includes("BS") ? (
        <div style={{ flexDirection: "column", alignItems: "flex-start" }}>
          <p>{message.split(",")[0]}</p>
          <br />

          {message.split(",")[1] && (
            <video
              src={mapImage(message.split(",")[1])}
              width="100%"
              height={200}
              className="video"
              id={message.split(",")[1]}
              autoPlay
            />
          )}
        </div>
      ) : message.includes("<Name>") ? (
        message.replace(
          "<Name>",
          window.location.href.includes("hrlogica") == true
            ? sessionStorage.getItem("user").name.split(" ")[0]
            : cookies.get("user").name.split(" ")[0]
        )
      ) : message.includes("<Image1>") ? (
        <img src={mapImage(globalOptions[0])} width="100%" height="100%" />
      ) : message.includes("<Image2>") ? (
        <img src={mapImage(globalOptions[1])} width="100%" height="100%" />
      ) : message.includes("<Image3>") ? (
        <img src={mapImage(globalOptions[2])} width="100%" height="100%" />
      ) : message.includes("<Image4>") ? (
        <img src={mapImage(globalOptions[3])} width="100%" height="100%" />
      ) : message.includes("<Image5>") ? (
        <img src={mapImage(globalOptions[4])} width="100%" height="100%" />
      ) : message.includes("<Image6>") ? (
        <img src={mapImage(globalOptions[5])} width="100%" height="100%" />
      ) : message.includes("<Image7>") ? (
        <img src={mapImage(globalOptions[6])} width="100%" height="100%" />
      ) : message.includes("<Image8>") ? (
        <img src={mapImage(globalOptions[7])} width="100%" height="100%" />
      ) : message.includes("<Image9>") ? (
        <img src={mapImage(globalOptions[8])} width="100%" height="100%" />
      ) : message.includes("<Image10>") ? (
        <img src={mapImage(globalOptions[9])} width="100%" height="100%" />
      ) : (
        message
      );
    } else if (
      (process.env.NODE_ENV == "development" && survey_id == 2) ||
      (process.env.NODE_ENV == "development" && survey_id == 6) ||
      (process.env.NODE_ENV == "production" && survey_id == 2) ||
      (process.env.NODE_ENV == "production" && survey_id == 6)
    ) {
      return message.includes("iHero") ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {this.getHighlightedText(message, "iHero")}
          <div style={{ width: "40%", marginTop: "5px" }}>
            <Button color="teal" onClick={this.showPopUpOne}>
              View Policy
            </Button>
          </div>
        </div>
      ) : message.includes("Here is a quick reckoner on the platform.") ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {message}
          <div style={{ width: "50%", marginTop: "5px" }}>
            <Button color="teal" onClick={this.showPopUpTwo}>
              View Reckoner
            </Button>
          </div>
        </div>
      ) : message.includes(
          "Here is a quick view on what it is, and some of our stars."
        ) ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {message}
          <div style={{ width: "40%", marginTop: "5px" }}>
            ``
            <Button color="teal" onClick={this.showPopUpThree}>
              View Reckoner
            </Button>
          </div>
        </div>
      ) : message.includes("some of our risk champions.") ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {message}
          <div style={{ width: "40%", marginTop: "5px" }}>
            <Button color="teal" onClick={this.showPopUpFour}>
              View Reckoner
            </Button>
          </div>
        </div>
      ) : message.includes("<Name>") ? (
        message.replace(
          "<Name>",
          window.location.href.includes("hrlogica") == true
            ? sessionStorage.getItem("user").name.split(" ")[0]
            : cookies.get("user").name.split(" ")[0]
        )
      ) : message.includes("High Performance") ? (
        this.getHighlightedText(message, "High Performance")
      ) : message.includes("Ownership") ? (
        this.getHighlightedText(message, "Ownership")
      ) : message.includes("Continuous Improvement") ? (
        this.getHighlightedText(message, "Continuous Improvement")
      ) : message.includes("Integrity") ? (
        this.getHighlightedText(message, "Integrity")
      ) : message.includes("Meritocracy") ? (
        this.getHighlightedText(message, "Meritocracy")
      ) : message.includes("Open Management") ? (
        this.getHighlightedText(message, "Open Management")
      ) : message.includes("Winning") ? (
        this.getHighlightedText(message, "Winning")
      ) : message.includes("InCredBot") ? (
        this.getHighlightedText(message, "InCredBot")
      ) : message.includes("high performance") ? (
        this.getHighlightedText(message, "high performance")
      ) : message.includes("InCred values and leadership commitments") ? (
        this.getHighlightedText(
          message,
          "InCred values and leadership commitments"
        )
      ) : message.includes("integrity") ? (
        this.getHighlightedText(mmessage, "integrity")
      ) : message.includes("ownership") ? (
        this.getHighlightedText(mmessage, "ownership")
      ) : message.includes("Continuously Improving") ? (
        this.getHighlightedText(message, "Continuously Improving")
      ) : message.includes("refer your friends & family") ? (
        this.getHighlightedText(message, "refer your friends & family")
      ) : message.includes("Star of the Month") ? (
        this.getHighlightedText(message, "Star of the Month")
      ) : message.includes("Risk Champion of the Month") ? (
        this.getHighlightedText(message, "Risk Champion of the Month")
      ) : message.includes("anonymous") ? (
        this.getHighlightedText(message, "anonymous")
      ) : message.includes("Incred") ? (
        this.getHighlightedText(message, "Incred")
      ) : message.includes("HR Business Partner") ? (
        this.getHighlightedText(message, "HR Business Partner")
      ) : message.includes("vision, values & commitments of InCred") ? (
        this.getHighlightedText(
          message,
          "vision, values & commitments of InCred"
        )
      ) : message.includes("InCred") ? (
        this.getHighlightedText(message, "InCred")
      ) : (
        message
      );
    } else if (message && message.includes("</a>")) {
      const link = document.createElement("a");
      link.innerHTML = message;

      return (
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={link.firstChild.href}
        >
          {link.firstChild.innerText}
        </a>
      );
    } else if (message.includes("<Name>")) {
      return message.replace(
        "<Name>",
        window.location.href.includes("hrlogica") == true
          ? sessionStorage.getItem("user").name.split(" ")[0]
          : cookies.get("user").name.split(" ")[0]
      );
    } else if (process.env.NODE_ENV == "development" && survey_id == 12) {
      return message.includes("Weight_1") ? (
        <img src={mapImage("Weight_1")} width="100%" height="100%" />
      ) : message.includes("Weight_2") ? (
        <img src={mapImage("Weight_2")} width="100%" height="100%" />
      ) : message.includes("Weight_3") ? (
        <img src={mapImage("Weight_3")} width="100%" height="100%" />
      ) : message.includes("Weight_4") ? (
        <img src={mapImage("Weight_4")} width="100%" height="100%" />
      ) : message.includes("Weight_5") ? (
        <img src={mapImage("Weight_5")} width="100%" height="100%" />
      ) : (
        message
      );
    } else if (process.env.NODE_ENV == "development" && survey_id == 14) {
      return message.includes("Calendly Invite") ? (
        <img
          src="https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/Screenshot+2021-03-18+at+7.34.28+PM.png"
          alt=""
          width="300px"
          height="200px"
        />
      ) : (
        message
      );
    } else if (
      (process.env.NODE_ENV == "development" && survey_id == 293) ||
      (process.env.NODE_ENV == "production" && survey_id == 13) ||
      (process.env.NODE_ENV == "development" && survey_id == 314) ||
      (process.env.NODE_ENV == "production" && survey_id == 21)
    ) {
      // dimensions;
      // <RedMessage></RedMessage>
      // <littleNapPoster></littleNapPoster>
      return message.includes("<LittleNap>") ? (
        <img src={mapImage("LittleNap")} width="100%" height="100%" />
      ) : message.includes("<littleNapPoster>") ? (
        <img src="https://i.imgur.com/RUvbWqK.jpg" width="100%" height="100%" />
      ) : message.includes("<LittleNapLastMessage>") ? (
        <p
          style={{
            // color: "red",
            fontWeight: "900",
          }}
        >
          ONE TEAM ONE DREAM
        </p>
      ) : message.includes("<speakUpLittleNap>") ? (
        <img src="https://i.imgur.com/Nj1lBl4.png" width="100%" height="100%" />
      ) : message.includes("<LittleNapEndBanner>") ? (
        <img src="https://i.imgur.com/sMv92uE.png" width="100%" height="100%" />
      ) : message.includes("<list>") ? (
        <p>
          The questions tested the following key organizational performance
          dimensions:
          <ul>
            <li>Reward & Recognition</li>
            <li>Organizational Goal alignment</li>
            <li>job security</li>
            <li>Career development opportunities</li>
            <li>Colleague Relations</li>
            <li>Management at Little Nap</li>
            <li>Company Feedback</li>
            <li>Managerial Leadership</li>
          </ul>
        </p>
      ) : message.includes("<RedMessage>") ? (
        <p
          style={{
            color: "red",
            fontWeight: "900",
          }}
        >
          Aim of this survey is to make our company best place to work in the
          world.
        </p>
      ) : (
        message
      );
    } else if (
      (process.env.NODE_ENV == "development" && survey_id == 327) ||
      (process.env.NODE_ENV == "production" && survey_id == 30)
    ) {
      // dimensions;
      // <RedMessage></RedMessage>
      // <littleNapPoster></littleNapPoster>
      return message.includes("<LittleNap>") ? (
        <img src={mapImage("LittleNap")} width="100%" height="100%" />
      ) : message.includes("<littleNapPoster2024>") ? (
        <img src="https://i.imgur.com/x0Wvnvk.png" width="100%" height="100%" />
      ) : message.includes("<LittleNapLastMessage>") ? (
        <p
          style={{
            // color: "red",
            fontWeight: "900",
          }}
        >
          ONE TEAM ONE DREAM
        </p>
      ) : message.includes("<speakUpLittleNap2024>") ? (
        <img src="https://i.imgur.com/DMU2i2P.png" width="100%" height="100%" />
      ) : message.includes("<LittleNapEndBanner>") ? (
        <img src="https://i.imgur.com/sMv92uE.png" width="100%" height="100%" />
      ) : message.includes("<list>") ? (
        <p>
          The questions tested the following key organizational performance
          dimensions:
          <ul>
            <li>Reward & Recognition</li>
            <li>Organizational Goal alignment</li>
            <li>job security</li>
            <li>Career development opportunities</li>
            <li>Colleague Relations</li>
            <li>Management at Little Nap</li>
            <li>Company Feedback</li>
            <li>Managerial Leadership</li>
          </ul>
        </p>
      ) : message.includes("<RedMessage>") ? (
        <p
          style={{
            color: "red",
            fontWeight: "900",
          }}
        >
          Aim of this survey is to make our company best place to work in the
          world.
        </p>
      ) : (
        message
      );
    } else if (message.includes("<IncredBulletPoints>")) {
      let NumberOfQuestions = {
        development: {
          317: 23, // IFSL
          318: 19, // Wealth
          319: 18, // Equities
          320: 20, // AMC
          321: 14, // IBD
        },
        production: {
          22: 23, // IFSL
          23: 19, // Wealth
          24: 18, // Equities
          25: 20, // AMC
          26: 14, // IBD
        },
      };
      return (
        <p>
          <b>Points to remember before you begin:</b>
          <ul>
            <li>
              There are {NumberOfQuestions[process.env.NODE_ENV][survey_id]}{" "}
              questions in the survey.
            </li>
            <li>All questions are mandatory to respond to.</li>
            <li>
              Read the question and rating scale carefully before responding.
            </li>
            <li>
              You can move on to the next question as soon as you respond to the
              current one.
            </li>
            <li>
              Once a response is selected and you proceed to the next question,
              the previous response cannot be edited in retrospect.
            </li>
            <li>
              In case you can’t complete the survey in one go, you may use the
              same link and the survey will resume from where you last left.
            </li>
          </ul>
          <b>Confidentiality: </b> We assure you that we will keep your data and
          your responses highly confidential. Make sure you respond in a frank
          and honest manner.
        </p>
      );
    } else {
      return message;
    }
  };

  render() {
    return (
      <div style={{ display: "flex" }}>
        {this.renderComponent(cookies.get("survey_id"), this.state.message)}

        <Modal
          style={{ height: "fit-content" }}
          open={this.state.isIncredModalOneVisible}
          closeOnDimmerClick
          onClose={this.handleCloseOne}
          size="small"
        >
          <i
            className="fa fa-times"
            style={{ float: "right", fontSize: "24px", padding: "15px" }}
            onClick={this.handleCloseOne}
          />

          <Modal.Content>
            <Image src="https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/iHero.jpg" />
          </Modal.Content>
        </Modal>
        <Modal
          style={{ height: "fit-content" }}
          open={this.state.isIncredModalTwoVisible}
          closeOnDimmerClick
          onClose={this.handleCloseTwo}
          size="small"
        >
          <i
            className="fa fa-times"
            style={{ float: "right", fontSize: "24px", padding: "15px" }}
            onClick={this.handleCloseTwo}
          />
          <Modal.Content>
            <Image src="https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/Employee+Referral.jpg" />
          </Modal.Content>
        </Modal>
        <Modal
          style={{ height: "fit-content" }}
          open={this.state.isIncredModalThreeVisible}
          closeOnDimmerClick
          onClose={this.handleCloseThree}
          size="small"
        >
          <i
            className="fa fa-times"
            style={{ float: "right", fontSize: "24px", padding: "15px" }}
            onClick={this.handleCloseThree}
          />
          <Modal.Content>
            <Image src="https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/Star+of+the+Month.jpg" />
          </Modal.Content>
        </Modal>
        <Modal
          style={{ height: "fit-content" }}
          open={this.state.isIncredModalFourVisible}
          closeOnDimmerClick
          onClose={this.handleCloseFour}
          size="small"
        >
          <i
            className="fa fa-times"
            style={{ float: "right", fontSize: "24px", padding: "15px" }}
            onClick={this.handleCloseFour}
          />
          <Modal.Content>
            <Image src="https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/Risk+Champion+of+the+Month.jpg" />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyId: -1,
      steps: [],
      visible: false,
      loginData: true,
      debug: false,
      surveyComplete: false,
      width: null,
      isLoading: true,
      dimmed: true,
      message: "",
      question: "",
      question_id: -1,
      showProgress: false,
      OTPModal: false,
      infoModal: false,
      infoModalMessage: "",
      otp: "",
    };
    this.handleSidebarToggle = this.handleSidebarToggle.bind(this);
    this.handleSurvey = this.handleSurvey.bind(this);
    this.updateWindowDimension = this.updateWindowDimension.bind(this);
    this.stopLoading = this.stopLoading.bind(this);
    this.getScore = this.getScore.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleStageSurvey = this.handleStageSurvey.bind(this);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimension);
    try {
      process.env.NODE_ENV == "staging"
        ? await this.handleStageSurvey()
        : await this.handleSurvey();
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
  }

  updateWindowDimension() {
    this.setState({
      width: window.innerWidth,
    });
  }

  handleSidebarToggle() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  handleReset() {
    this.setState(
      {
        steps: {},
      },
      async () => await this.handleStageSurvey()
    );
  }

  handleOpenInfoModal = (msg) => {
    this.setState({
      infoModalMessage: msg,
      infoModal: true,
    });
  };
  handleCloseInfoModal = () => {
    this.setState({
      infoModal: false,
    });
  };
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  };
  handleOTPChange = (event) => {
    const otp = event.target.validity.valid
      ? event.target.value
      : this.state.otp;
    this.setState({ otp });
  };
  handleSubmit = async () => {
    const { otp, number } = this.state;
    axiosConfig.headers["x-auth"] = sessionStorage.getItem("x-auth");
    const res = await axios.get(
      `/users/verify_otp?mobile=${number}&otp=${otp}`,
      axiosConfig
    );
    if (res.data.data.type === "success") {
      this.setState({
        OTPModal: false,
        otp: "",
      });
    } else {
      this.setState({
        infoModalMessage: "Incorrect OTP!",
        infoModal: true,
      });
    }
  };
  sendOTP = async (number) => {
    //   this.setState({
    //     number,
    //     OTPModal: true,
    //   });
    //   axiosConfig.headers['x-auth'] = sessionStorage.getItem('x-auth');
    //   await axios.get(`/users/send_otp?mobile=${number}`, axiosConfig);
  };
  resendOTP = async () => {
    axiosConfig.headers["x-auth"] = sessionStorage.getItem("x-auth");
    const res = await axios.get(
      `users/retry_otp?mobile=${this.state.number}`,
      axiosConfig
    );
    if (res.data.data.type === "success") {
      this.setState({
        infoModalMessage: "OTP resent successfully",
        infoModal: true,
      });
    } else {
      this.setState({
        infoModalMessage: res.data.data.message,
        infoModal: true,
      });
    }
  };

  stopLoading() {
    this.setState({
      isLoading: false,
      dimmed: false,
    });
    // console.log("stop loading");
  }

  async getScore() {
    axiosConfig.headers["x-auth"] =
      window.location.href.includes("hrlogica") == true
        ? await sessionStorage.getItem("x-auth")
        : await cookies.get("x-auth");
    const res = await axios.get(
      `/surveys/${this.state.surveyId}/score`,
      axiosConfig
    );
    let score = res.data.data.survey_score
      .toString()
      .split("")
      .reduce((acc, val) => (acc += Number(val)), 0);
    // console.log(score);
    switch (score) {
      case 15:
        score = "High Risk";
        break;
      case 14:
        score = "High Risk";
        break;
      case 13:
        score = "High Risk";
        break;
      case 12:
        score = "High Risk";
        break;
      case 11:
        score = "Moderate Risk";
        break;
      case 10:
        score = "Moderate Risk";
        break;
      case 9:
        score = "Moderate Risk";
        break;
      case 8:
        score = "Moderate Risk";
        break;
      case 7:
        score = "Mild Risk";
        break;
      case 6:
        score = "Mild Risk";
        break;
      case 5:
        score = "Mild Risk";
        break;
      case 4:
        score = "Mild Risk";
        break;
      case 3:
        score = "Ideal Profile";
        break;
      case 2:
        score = "Ideal Profile";
        break;
      case 1:
        score = "Ideal Profile";
        break;
      case 0:
        score = "Ideal Profile";
        break;
    }
    return { Score: score, SurveyId: this.state.surveyId };
  }

  async welcome() {
    axiosConfig.headers["x-auth"] =
      window.location.href.includes("hrlogica") == true
        ? await sessionStorage.getItem("x-auth")
        : await cookies.get("x-auth");
    const res = await axios.get(`/surveys/${this.state.surveyId}`, axiosConfig);
    const welcomeText = res.data.survey.welcome;
    return [
      {
        id: "intro_1",
        message: { welcomeText },
        trigger: "comp_msg_1",
        animation: 1000,
      },
      {
        id: "comp_msg",
        message:
          "Everything you share is kept confidential so feel free to give candid feedback. Shall we begin?",
        trigger: "begin_msg",
        animation: 1000,
      },
      {
        id: "begin_msg",
        options: [
          { value: 1, label: "Yeah, Sure", trigger: id },
          { value: 2, label: "Nope, later", trigger: "no_msg" },
        ],
      },
      {
        id: "no_msg",
        message: temMs,
        trigger: "begin_button",
        animation: 1000,
      },
      {
        id: "begin_button",
        options: [{ value: 1, label: "Let's Begin", trigger: id }],
      },
    ];
  }

  async handleStageSurvey() {
    welcomeText();
    if (this.props.isLoggedIn) {
      try {
        axiosConfig.headers["x-auth"] =
          window.location.href.includes("hrlogica") == true
            ? await sessionStorage.getItem("x-auth")
            : await cookies.get("x-auth");
        let res = await axios.get("/surveys/active", axiosConfig);
        res = res.data;
        if (res.data.surveys.length > 0) {
          let isAnswered = false;
          if (res.data.surveys[0].answered_questions.length > 0) {
            const surveyId = res.data.surveys[0].id;
            this.setState({
              surveyId,
            });
            let resSurvey = await axios.get(
              `/surveys/${surveyId}`,
              axiosConfig
            );
            resSurvey = resSurvey.data;
            let surveyClient = await convert(
              resSurvey.data,
              this.state.loginData,
              surveyId
            ); // loginData
            let defMsg = [];
            if (surveyClient.length > 0) {
              defMsg = await defaultMessage(surveyClient[0].id);
            }
            surveyClient = await [...defMsg, ...surveyClient];
            if (!isAnswered) {
              surveyClient = await [...defMsg, ...surveyClient];
              this.setState({
                isLoading: false,
                dimmed: false,
              });
            }
            let animation = 1;
            if (!isAnswered) {
              animation = 1000;
            }
            for (let idx = 0; idx < surveyClient.length; idx++) {
              if (!surveyClient[idx].animation) {
                surveyClient[idx].animation = animation;
              }
              // console.log("surveyClient[idx].id: "+surveyClient[idx].id);
              if (surveyClient[idx].id == "endSurvey") {
                surveyClient[idx].component = (
                  <Send2Server
                    loading_func={true}
                    stop_loading={this.stopLoading}
                    surveyId={surveyId}
                    step_id={surveyClient[idx].id}
                    trigger={surveyClient[idx].trigger}
                    message={surveyClient[idx].message}
                  />
                );
                surveyClient[idx].asMessage = true;
                surveyClient[idx].waitAction = true;
                delete surveyClient[idx].message;
              } else if (
                surveyClient[idx].id.toString().indexOf("ques_") !== -1
              ) {
                // console.log("ques_: "+surveyClient[idx].id);

                surveyClient[idx].component = (
                  <Send2Server
                    loading_func={true}
                    stop_loading={this.stopLoading}
                    surveyId={surveyId}
                    step_id={surveyClient[idx].id}
                    trigger={surveyClient[idx].trigger}
                    message={
                      surveyClient[idx].message != null
                        ? surveyClient[idx].message
                        : surveyClient[idx].component.props.message
                    }
                  />
                );
                surveyClient[idx].animation = 1000;

                surveyClient[idx].asMessage = true;
                surveyClient[idx].waitAction = true;
                delete surveyClient[idx].message;
              } else if (surveyClient[idx].options) {
                // if answered
                // console.log("option type");

                // console.log("no answered");
                surveyClient[idx].animation = 1000;
                for (let i = 0; i < surveyClient[idx].options.length; i++) {
                  for (let j = 0; j < surveyClient.length; j++) {
                    if (
                      surveyClient[j].id == surveyClient[idx].options[i].trigger
                    ) {
                      surveyClient[j].animation = 1000;
                      if (surveyClient[idx].multipleChoice == true) {
                        surveyClient[j].component = (
                          <Send2Server
                            multipleChoice={true}
                            loading_func={true}
                            stop_loading={this.stopLoading}
                            surveyId={surveyId}
                            step_id={surveyClient[j].id}
                            trigger={surveyClient[j].trigger}
                            message={
                              surveyClient[j].message != null
                                ? surveyClient[j].message
                                : surveyClient[j].component.props.message
                            }
                          />
                        );
                        surveyClient[j].selected = false;
                      } else {
                        surveyClient[j].component = (
                          <Send2Server
                            loading_func={true}
                            stop_loading={this.stopLoading}
                            surveyId={surveyId}
                            step_id={surveyClient[j].id}
                            trigger={surveyClient[j].trigger}
                            message={
                              surveyClient[j].message != null
                                ? surveyClient[j].message
                                : surveyClient[j].component.props.message
                            }
                          />
                        );
                      }
                      surveyClient[j].asMessage = true;
                      surveyClient[j].waitAction = true;
                      delete surveyClient[j].message;
                      break;
                    }
                  }
                }
              } else if (surveyClient[idx].user == true) {
                // if answered
                // console.log("user type");
                if (
                  isAnswered &&
                  answers.hasOwnProperty(surveyClient[idx].id)
                ) {
                  // console.log("answered already");
                  surveyClient[idx].message =
                    answers[surveyClient[idx].id] == null
                      ? " "
                      : answers[surveyClient[idx].id];
                  surveyClient[idx].answered = true;
                } else {
                  surveyClient[idx].animation = 1000;
                  for (let j = 0; j < surveyClient.length; j++) {
                    if (surveyClient[j].id == surveyClient[idx].trigger) {
                      surveyClient[j].animation = 1000;
                      break;
                    }
                  }
                }
              } else if (surveyClient[idx].message) {
                surveyClient[idx].component = (
                  <Send2Server
                    loading_func={false}
                    surveyId={surveyId}
                    step_id={surveyClient[idx].id}
                    trigger={surveyClient[idx].trigger}
                    message={surveyClient[idx].message}
                  />
                );
                surveyClient[idx].asMessage = true;
                surveyClient[idx].waitAction = true;
                delete surveyClient[idx].message;
              }
            }

            this.setState({
              steps: surveyClient,
              showProgress: false,
            });
          } else {
            this.setState({
              showProgress: false,
              surveyComplete: true,
              infoModalMessage: "You have already done the survey",
              infoModal: true,
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    function defaultMessage(id) {
      const NODE_ENV = process.env.NODE_ENV;
      const temMs = emojify(
        "Okay! Just press the button below whenever you would like to begin. Thanks :smiley: "
      );
      const cid =
        window.location.href.includes("hrlogica") == true
          ? sessionStorage.getItem("cid")
          : cookies.get("cid");

      return welcomeMessage(NODE_ENV, cid, id);
      // return this.welcome();
    }
  }

  async handleSurvey() {
    let survey_id = await cookies.get("survey_id");
    if (this.props.isLoggedIn) {
      try {
        axiosConfig.headers["x-auth"] =
          window.location.href.includes("hrlogica") == true
            ? await sessionStorage.getItem("x-auth")
            : await cookies.get("x-auth");
        // console.log("x-auth:"+axiosConfig.headers['x-auth']);
        let res = await axios.get(
          `/surveys/active?survey_id=${survey_id}`,
          axiosConfig
        );
        res = res.data;
        // console.log(`surveys/active: ${JSON.stringify(res)}`);
        if (res.data.surveys.length > 0) {
          // console.log("answered_question: "+res.data.surveys[0].answered_questions.length);
          let isAnswered = false;
          const answers = {};
          if (
            res.data.surveys[res.data.surveys.length - 1].answered_questions
              .length > 0
          ) {
            isAnswered = true;
            const answered_questions =
              res.data.surveys[res.data.surveys.length - 1].answered_questions;
            for (const i in answered_questions) {
              if (
                answers[answered_questions[i].question_id] != null &&
                answers[answered_questions[i].question_id] != "" &&
                answers[answered_questions[i].question_id] != undefined
              ) {
                answers[
                  answered_questions[i].question_id
                ] += `\n${answered_questions[i].answer}`;
              } else {
                answers[answered_questions[i].question_id] =
                  answered_questions[i].answer;
              }
            }
          }

          // console.log("answers: "+JSON.stringify(answers));

          const surveyId = res.data.surveys[res.data.surveys.length - 1].id;
          this.setState({
            surveyId,
          });
          let resSurvey = await axios.get(`/surveys/${surveyId}`, axiosConfig);
          resSurvey = resSurvey.data;

          //console.log("surveys/Id: "+JSON.stringify(resSurvey));
          let surveyClient = await convert(
            resSurvey.data,
            this.state.loginData,
            this.state.surveyId
          ); // loginData

          let defMsg = [];
          if (surveyClient.length > 0 && !isAnswered) {
            defMsg = await defaultMessage(surveyClient[0].id);
          }

          if (!isAnswered) {
            surveyClient = await [...defMsg, ...surveyClient];
            this.setState({
              isLoading: false,
              dimmed: false,
            });
          }
          // console.log("initial steps: "+JSON.stringify(surveyClient));

          const greetingMsg_id_arr = [];
          let animation = 1;
          if (!isAnswered) {
            animation = 1000;
          }

          for (let idx = 0; idx < surveyClient.length; idx++) {
            if (!surveyClient[idx].animation) {
              surveyClient[idx].animation = animation;
            }
            //console.log("surveyClient[idx].id: "+surveyClient[idx].id);
            if (surveyClient[idx].id == "endSurvey") {
              surveyClient[idx].component = (
                <Send2Server
                  loading_func={true}
                  stop_loading={this.stopLoading}
                  surveyId={surveyId}
                  step_id={surveyClient[idx].id}
                  trigger={surveyClient[idx].trigger}
                  message={surveyClient[idx].message}
                />
              );
              surveyClient[idx].asMessage = true;
              surveyClient[idx].waitAction = true;
              delete surveyClient[idx].message;
            } else if (
              surveyClient[idx].id.toString().indexOf("ques_") !== -1
            ) {
              //console.log("ques_: "+surveyClient[idx].id);
              if (
                isAnswered &&
                answers.hasOwnProperty(
                  surveyClient[idx].id.replace(/ques_/g, "")
                )
              ) {
                // greetingMsg_id_arr.push(surveyClient[idx].id);

                surveyClient[idx].component = (
                  <Send2Server
                    loading_func={true}
                    stop_loading={() => console.log("loading")}
                    surveyId={surveyId}
                    step_id={surveyClient[idx].id}
                    trigger={surveyClient[idx].trigger}
                    message={
                      surveyClient[idx].message != null
                        ? surveyClient[idx].message
                        : surveyClient[idx].component.props.message
                    }
                  />
                );
              } else {
                if (isAnswered && greetingMsg_id_arr.length === 0) {
                  //console.log("greetingMsg_id: "+greetingMsg_id);
                  greetingMsg_id_arr.push(surveyClient[idx].id);
                }
                surveyClient[idx].component = (
                  <Send2Server
                    loading_func={true}
                    stop_loading={this.stopLoading}
                    surveyId={surveyId}
                    step_id={surveyClient[idx].id}
                    trigger={surveyClient[idx].trigger}
                    message={
                      surveyClient[idx].message != null
                        ? surveyClient[idx].message
                        : surveyClient[idx].component.props.message
                    }
                  />
                );
                surveyClient[idx].animation = 1000;
              }
              surveyClient[idx].asMessage = true;
              surveyClient[idx].waitAction = true;
              delete surveyClient[idx].message;
            } else if (surveyClient[idx].options) {
              // if answered
              //console.log("option type");
              if (isAnswered && answers.hasOwnProperty(surveyClient[idx].id)) {
                // console.log("answered already");
                //console.log("answer is: "+answers[surveyClient[idx].id]);
                for (let i = 0; i < surveyClient[idx].options.length; i++) {
                  //console.log("check whether same: "+surveyClient[idx].options[i].label);
                  if (
                    emoji
                      .emojify(answers[surveyClient[idx].id])
                      .includes(surveyClient[idx].options[i].label)
                  ) {
                    //console.log("same");
                    surveyClient[idx].multipleChoice = false;
                    if (
                      surveyClient[idx].message != null &&
                      surveyClient[idx].message != "" &&
                      surveyClient[idx].message != undefined
                    ) {
                      surveyClient[
                        idx
                      ].message += `\n${surveyClient[idx].options[i].label}`;
                    } else {
                      surveyClient[idx].message =
                        surveyClient[idx].options[i].label;
                    }
                    surveyClient[idx].trigger =
                      surveyClient[idx].options[i].trigger;
                    // break;
                  }
                }
                surveyClient[idx].user = true;
                surveyClient[idx].answered = true;
                delete surveyClient[idx].options;
              } else {
                //console.log("no answered");
                surveyClient[idx].animation = 1000;
                for (let i = 0; i < surveyClient[idx].options.length; i++) {
                  for (let j = 0; j < surveyClient.length; j++) {
                    if (
                      surveyClient[j].id == surveyClient[idx].options[i].trigger
                    ) {
                      surveyClient[j].animation = 1000;
                      if (surveyClient[idx].multipleChoice == true) {
                        surveyClient[j].component = (
                          <Send2Server
                            multipleChoice={true}
                            loading_func={true}
                            stop_loading={this.stopLoading}
                            surveyId={surveyId}
                            step_id={surveyClient[j].id}
                            trigger={surveyClient[j].trigger}
                            message={
                              surveyClient[j].message != null
                                ? surveyClient[j].message
                                : surveyClient[j].component.props.message
                            }
                          />
                        );
                        surveyClient[j].selected = false;
                      } else {
                        surveyClient[j].component = (
                          <Send2Server
                            loading_func={true}
                            stop_loading={this.stopLoading}
                            surveyId={surveyId}
                            step_id={surveyClient[j].id}
                            trigger={surveyClient[j].trigger}
                            message={
                              surveyClient[j].message != null
                                ? surveyClient[j].message
                                : surveyClient[j].component.props.message
                            }
                          />
                        );
                      }
                      surveyClient[j].asMessage = true;
                      surveyClient[j].waitAction = true;
                      delete surveyClient[j].message;
                      break;
                    }
                  }
                }
              }
            } else if (surveyClient[idx].inputDate) {
              if (isAnswered && answers.hasOwnProperty(surveyClient[idx].id)) {
                // console.log("answered already");
                // console.log("answer is: "+answers[surveyClient[idx].id]);

                // console.log("check whether same: "+surveyClient[idx].options[i].label);

                // console.log("same");

                surveyClient[idx].message = answers[surveyClient[idx].id];

                // break;

                surveyClient[idx].user = true;
                surveyClient[idx].answered = true;
                delete surveyClient[idx].inputDate;
              }
            } else if (surveyClient[idx].user == true) {
              // if answered
              //console.log("user type");
              if (isAnswered && answers.hasOwnProperty(surveyClient[idx].id)) {
                // console.log("answered already");
                surveyClient[idx].message =
                  answers[surveyClient[idx].id] == null
                    ? " "
                    : answers[surveyClient[idx].id];
                surveyClient[idx].answered = true;
              } else {
                surveyClient[idx].animation = 1000;
                for (let j = 0; j < surveyClient.length; j++) {
                  if (surveyClient[j].id == surveyClient[idx].trigger) {
                    surveyClient[j].animation = 1000;
                    break;
                  }
                }
              }
            } else if (surveyClient[idx].message) {
              surveyClient[idx].component = (
                <Send2Server
                  loading_func={false}
                  surveyId={surveyId}
                  step_id={surveyClient[idx].id}
                  trigger={surveyClient[idx].trigger}
                  message={surveyClient[idx].message}
                />
              );
              surveyClient[idx].asMessage = true;
              surveyClient[idx].waitAction = true;
              delete surveyClient[idx].message;
            }
          }

          // add greeting message
          // if (greetingMsg_id_arr.length) {
          //   for (let idx = 0; idx < surveyClient.length; idx++) {
          //     if (greetingMsg_id_arr.includes(surveyClient[idx].trigger)) {
          //       surveyClient[
          //         idx
          //       ].trigger = `greeting_${surveyClient[idx].trigger}`;
          //     }
          //   }
          //   for (let i = 0; i < greetingMsg_id_arr.length; i++) {
          //     const greetingMsg = greetingMessage(greetingMsg_id_arr[i]);
          //     greetingMsg.push({
          //       id: `greeting_${greetingMsg_id_arr[i]}`,
          //       component: (
          //         <Send2Server
          //           loading_func={true}
          //           stop_loading={this.stopLoading}
          //           surveyId={surveyId}
          //           step_id={"greeting"}
          //           trigger={"begin_msg"}
          //           message={"Would you like to continue?"}
          //         />
          //       ),
          //       asMessage: true,
          //       waitAction: true,
          //       trigger: `begin_msg_${greetingMsg_id_arr[i]}`,
          //       animation: 1,
          //     });
          //     surveyClient = [...surveyClient, ...greetingMsg];
          //   }
          // }
          console.log(surveyClient);
          this.setState({
            steps: surveyClient,
          });
        } else if (count === 0) {
          alert("No Survey Available");
          count += 1;
        }
      } catch (err) {
        console.log(err);
      }
    }

    function greetingMessage(id) {
      const temMs = emojify(
        "Okay! Just press the button below whenever you would like to begin. Thanks :smiley: "
      );

      const defMsg = [
        {
          id: `begin_msg_${id}`,
          options: [
            { value: 1, label: "Yeah, Sure", trigger: id },
            { value: 2, label: "Nope, later", trigger: `no_msg_${id}` },
          ],
        },
        {
          id: `no_msg_${id}`,
          message: temMs,
          trigger: `begin_button_${id}`,
          animation: 1000,
        },
        {
          id: `begin_button_${id}`,
          options: [{ value: 1, label: "Let's Begin", trigger: id }],
        },
      ];
      return defMsg;
    }

    function defaultMessage(id) {
      const NODE_ENV = process.env.NODE_ENV;
      const temMs = emojify(
        "Okay! Just press the button below whenever you would like to begin. Thanks :smiley: "
      );
      const cid =
        window.location.href.includes("hrlogica") == true
          ? sessionStorage.getItem("cid")
          : cookies.get("cid");

      return welcomeMessage(NODE_ENV, survey_id, id);
      // return this.welcome();
    }
  }

  render() {
    let chatComponentStyle = {
      avatarStyle: {},
      customStyle: {},
      bubbleStyle: {},
      inputStyle: {},
      styleButtonStyle: {},
    };
    if (this.state.width > 1400 && this.state.width < 2700) {
      chatComponentStyle = {
        customStyle: { fontSize: "15px" },
        inputStyle: { fontSize: "16px" },
        styleButtonStyle: {},
      };
    }

    const dimmer = (
      <Dimmer active={this.state.isLoading} inverted>
        <Loader size="massive">Loading</Loader>
      </Dimmer>
    );
    const dis =
      this.state.steps.length > 0 && this.props.isLoggedIn ? (
        <Dimmer.Dimmable dimmed={this.state.dimmed}>
          {dimmer}
          <Row>
            <Col lg={11}>
              <SideBar
                handleReset={this.handleReset}
                handleToggle={this.handleSidebarToggle}
                user={this.props.user}
                handleLogout={() => this.props.handleLogout()}
              />
            </Col>

            <Col lg={1} md={12} sm={12} xs={12}>
              <div>
                <ThemeProvider theme={otherFontTheme}>
                  <ChatBot
                    //showAlert={this.handleOpenInfoModal}
                    verifyOTP={this.sendOTP}
                    steps={this.state.steps}
                    showAlert={this.handleOpenInfoModal}
                    avatarStyle={chatComponentStyle.avatarStyle}
                    customStyle={chatComponentStyle.customStyle}
                    bubbleStyle={chatComponentStyle.bubbleStyle}
                    botDelay={1}
                    customDelay={1}
                    userDelay={1}
                    getScore={this.getScore}
                    inputStyle={chatComponentStyle.inputStyle}
                    submitButtonStyle={chatComponentStyle.styleButtonStyle}
                    placeholder="Type your feedback here ..."
                    id="chatbot"
                    handleSurvey={
                      process.env.NODE_ENV == "staging"
                        ? this.handleStageSurvey
                        : this.handleSurvey
                    }
                  />
                </ThemeProvider>
              </div>
            </Col>
          </Row>
        </Dimmer.Dimmable>
      ) : this.state.surveyComplete || this.props.isLoggedIn ? (
        <Row>
          <Col>
            <SideBar
              handleReset={this.handleReset}
              handleToggle={this.handleSidebarToggle}
              user={this.props.user}
              handleLogout={() => this.props.handleLogout()}
            />
          </Col>
        </Row>
      ) : null;
    return (
      <div>
        <div>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <XaneBar />
            </Col>
          </Row>
          {dis}
        </div>
        {this.state.OTPModal ? (
          <Modal
            className="modal-container"
            style={{ display: "flex", alignItems: "center" }}
            backdropStyle={{
              opacity: "0.5",
              height: "100% !important",
              position: "fixed",
            }}
            animation={false}
            show={this.state.OTPModal}
          >
            <Modal.Body>
              <Modal.Title style={{ textAlign: "center" }}>
                Please enter your OTP
              </Modal.Title>
              <form>
                <FormGroup>
                  <center>
                    <FormControl
                      style={{
                        width: "300px",
                        marginTop: "20px",
                        padding: "10px 0",
                        textAlign: "center",
                        fontSize: "1.3rem",
                        letterSpacing: "0.6rem",
                      }}
                      value={this.state.otp}
                      type="text"
                      pattern="[0-9]*"
                      maxLength="4"
                      placeholder="OTP"
                      onKeyPress={this.handleKeyPress}
                      onInput={this.handleOTPChange}
                    />
                  </center>
                </FormGroup>
                <center>
                  <Button
                    style={{
                      marginTop: "20px",
                      color: "white",
                      backgroundColor: "#25c16f",
                      fontFamily: '"Roboto","Open Sans", sans-serif',
                      transition: "background-color .15s ease-in",
                      textTransform: "uppercase",
                      display: "block",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                    onClick={this.handleSubmit}
                  >
                    Submit
                  </Button>
                </center>
              </form>
              <center>
                <p
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    paddingTop: "5px",
                    cursor: "pointer",
                  }}
                  onClick={this.resendOTP}
                >
                  Resend OTP
                </p>
              </center>
            </Modal.Body>
          </Modal>
        ) : null}
        {this.state.infoModal ? (
          <Modal
            className="modal-container"
            style={{ display: "flex", alignItems: "center" }}
            backdropStyle={{
              opacity: "0.5",
              height: "100% !important",
              position: "fixed",
            }}
            animation={false}
            show={this.state.infoModal}
            onHide={this.handleCloseInfoModal}
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <Modal.Title style={{ textAlign: "center" }}>
                {this.state.infoModalMessage}
              </Modal.Title>
              <center>
                <Button
                  style={{
                    marginTop: "20px",
                    color: "white",
                    backgroundColor: "#25c16f",
                    fontFamily: '"Roboto","Open Sans", sans-serif',
                    transition: "background-color .15s ease-in",
                    textTransform: "uppercase",
                    display: "block",
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                  onClick={this.handleCloseInfoModal}
                >
                  Okay
                </Button>
              </center>
            </Modal.Body>
          </Modal>
        ) : null}
      </div>
    );
  }
}
