import styled from "styled-components";
import defaultTheme from "../../theme";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const ITC = cookies.get("cid") == 218 && process.env.NODE_ENV === "development";

const MultipleSelectElement =
  process.env.NODE_ENV === "staging"
    ? styled.a`
        background: ${(props) => props.bubbleColor};
        border-radius: 22px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
        color: ${(props) =>
          props.bubbleColor == "#21ba45" ? "white !important" : "#595959"};
        display: inline-block;
        font-size: 16px;
        padding: 12px;
        &:hover {
          opacity: 0.7;
        }
      `
    : ITC
    ? styled.a`
        background: ${(props) => props.bubbleColor};
        border-radius: 22px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
        color: ${(props) =>
          props.bubbleColor == "#21ba45"
            ? props.bubbleColor == "#f1f0f0"
              ? "white"
              : "white !important"
            : "black"};
        display: flex;
        font-size: 12px;
        padding: 5px;

        &:hover {
          opacity: 0.7;
        }
      `
    : styled.a`
        background: ${(props) => props.bubbleColor};
        border-radius: 22px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
        color: ${(props) =>
          props.bubbleColor == "#21ba45"
            ? props.bubbleColor == "#f1f0f0"
              ? "white"
              : "white !important"
            : "black"};
        display: flex;
        font-size: 16px;
        padding: 12px;

        &:hover {
          opacity: 0.7;
        }
      `;

MultipleSelectElement.defaultProps = {
  theme: defaultTheme,
};

export default MultipleSelectElement;
