import _ from "lodash";
import React from "react";
import { Redirect } from "react-router-dom";
import QuestionBubble from "./components/Question/questionBubble";
import QuestionText from "./components/Question/questionText";
import Header from "./components/Header/header";
import DepartmentOptions from "./components/Department/DepartmentOptions";
import { themeSelector } from "../static/theme";
import Departments from "./components/DepartmentAnswers/Departments";
import convertToQuestion from "./service/questionConvertor";
import convertToQuestionAnswer from "./service/answerConvertor";
import Cookies from "universal-cookie";
import { Icon, Progress } from "semantic-ui-react";
// import CineBack from "../icons/cine_bck.png";
// import Incred from "../icons/Picture2.png";
import { Modal, Button } from "react-bootstrap";

const cookies = new Cookies();
const surveyId = cookies.get('survey_id');
const isUPSSurvey = () => (process.env.NODE_ENV == 'production' && surveyId == 29) ||
(process.env.NODE_ENV == 'development' && surveyId == 326);
const axios = require("axios");
const axiosConfig = require("../components/AxiosConfig/AxiosConfig");
//const theme = window.theme;
const cid =
  window.location.href.includes("hrlogica") == true
    ? Number(sessionStorage.getItem("cid"))
    : Number(cookies.get("cid"));
const companies =
  (process.env.NODE_ENV == "production" && cid == 16) ||
  (process.env.NODE_ENV == "development" && cid == 13) ||
  (process.env.NODE_ENV == "development" && cid == 64) ||
  (process.env.NODE_ENV == "production" && cid == 38);

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyId: null,
      endScreen: false,
      noSurvey: false,
      redirectToEndScreen: false,
      isModalVisible: false,
      renderSteps: [],
      theme: themeSelector(
        parseInt(cookies.get("cid")),
        process.env.NODE_ENV,
        parseInt(cookies.get("survey_id"))
      ),
      answer_to_edit: false,
    };
    this.renderStep = this.renderStep.bind(this);
    this.submitAnswer = this.submitAnswer.bind(this);
    this.editAnswer = this.editAnswer.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.redirectToEndScreen = this.redirectToEndScreen.bind(this);
    this.deleteLastStep = this.deleteLastStep.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() {
    //console.log(theme.themeSelector(19,'development'));
    const survey_id = await cookies.get("survey_id");
    axiosConfig.headers["x-auth"] =
      window.location.href.includes("hrlogica") == true
        ? await sessionStorage.getItem("x-auth")
        : await cookies.get("x-auth");
    let res = await axios.get(
      `/surveys/active?survey_id=${survey_id}`,
      axiosConfig
    );
    res = res.data;
    if (res.data.surveys.length === 0) {
      this.setState({
        noSurvey: true,
      });
    } else {
      const surveyId = res.data.surveys[res.data.surveys.length - 1].id;
      const answeredQuestions =
        res.data.surveys[res.data.surveys.length - 1].answered_questions;
      let renderSteps = [];
      const surveyType =
        res.data.surveys[res.data.surveys.length - 1].survey_type;
      this.setState({
        surveyType,
      });
      if (answeredQuestions.length > 0) {
        // console.log(JSON.stringify(answeredQuestions));
        renderSteps = await convertToQuestionAnswer(answeredQuestions);
      }
      let resSurvey = await axios.get(
        `/surveys/${surveyId}?question=true`,
        axiosConfig
      );
      resSurvey = await resSurvey.data;

      if (
        res.data.surveys[res.data.surveys.length - 1].survey_type ===
        "General Form"
      ) {
        const surveyQuestion = await convertToQuestion(resSurvey.data);
        if (surveyQuestion[0].answer_type === "Followup") {
          renderSteps[renderSteps.length - 1].followup.pop();
          renderSteps[renderSteps.length - 1].followup.push(...surveyQuestion);
        } else {
          renderSteps = [...renderSteps, ...surveyQuestion];
        }
      } else {
        if (
          resSurvey.data.current_question &&
          renderSteps[renderSteps.length - 1].id ===
            resSurvey.data.current_question.id
        ) {
          // comparing last set with new set
          const lastStep = renderSteps[renderSteps.length - 1];
          renderSteps.pop();
          renderSteps.push(...convertToQuestion(resSurvey.data, lastStep));
          // console.log(JSON.stringify(renderSteps));
        }
        if (resSurvey.data.next_question && resSurvey.data.response === 2) {
          // adding followups
          // console.log(JSON.stringify(renderSteps));
          renderSteps[renderSteps.length - 1].followup.pop();
          renderSteps[renderSteps.length - 1].followup.push(
            ...convertToQuestion(resSurvey.data)
          );
        }
        if (resSurvey.data.next_question && resSurvey.data.response !== 2) {
          renderSteps.push(...convertToQuestion(resSurvey.data));
        }
      }
      if (resSurvey.data.end_survey) {
        this.setState({
          redirectToEndScreen: true,
        });
      }
      if (renderSteps[0].id === "Welcome") {
        renderSteps.shift();
      }
      this.setState({
        renderSteps,
        surveyId,
      });
      if (renderSteps.length === 2 && resSurvey.data.response === 3) {
        this.handleModal();
      }
      this.scrollToBottom();
    }
  }

  async submitAnswer(data) {
    try {
      const { renderSteps } = this.state;
      const params = {};
      params.question_id = renderSteps[renderSteps.length - 1].id;
      params.answer_id = data;

      if (typeof data === "object") {
        if (data.questionId) {
          params.question_id = data.questionId;
          params.answer = data.value;
          const regex = /'/g;
          params.answer = params.answer.replace(regex, "\\'");
          delete params.answer_id;
        } else {
          params.answer_id = String(data.value);
        }
        params.department_id = data.dept;
      }
      axiosConfig.headers["x-auth"] =
        window.location.href.includes("hrlogica") == true
          ? await sessionStorage.getItem("x-auth")
          : await cookies.get("x-auth");
      // console.log(params);
      let res = await axios.post(
        `/surveys/${this.state.surveyId}/answers`,
        params,
        axiosConfig
      );

      res = await res.data;

      if (res.error) {
        throw new Error("Data not found");
      } else if (typeof data === "object" && data.questionId && !res.error) {
        // console.log(renderSteps[renderSteps.length - 1]);
        if (
          renderSteps[renderSteps.length - 1].followup.find(
            (obj) => obj.department_id === data.dept
          ) !== undefined
        ) {
          renderSteps[renderSteps.length - 1].followup.find(
            (obj) => obj.department_id === data.dept
          ).answered = true;
        }
      }

      let newStep = await convertToQuestion(res.data);

      if (res.data.response === 3 && renderSteps.length === 1) {
        newStep[0].department.forEach((dept) => {
          dept.editable = false;
        });
        newStep[0].department[0].editable = true;
      }
      if (
        res.data.response === 3 &&
        renderSteps[renderSteps.length - 1].department !== undefined &&
        renderSteps[renderSteps.length - 1].department.length - 1 ===
          renderSteps[renderSteps.length - 1].followup.length
      ) {
        // console.log('here');
        // console.log(renderSteps);
        if (renderSteps[renderSteps.length - 1].followup.length === 0) {
          renderSteps[renderSteps.length - 1].followup.push({});
        } else {
          renderSteps[renderSteps.length - 1].department[
            renderSteps[renderSteps.length - 1].followup.length - 1
          ].editable = false;
          renderSteps[renderSteps.length - 1].followup.push({});
          renderSteps[renderSteps.length - 1].department[
            renderSteps[renderSteps.length - 1].followup.length - 1
          ].editable = true;
        }
        // console.log(renderSteps);
      }
      if (
        res.data.response === 1 &&
        res.data.is_follow_up === false &&
        newStep[0].answer_type === "Subjective"
      ) {
        renderSteps[renderSteps.length - 1].department.forEach((dept) => {
          dept.editable = false;
        });
        // console.log(renderSteps[renderSteps.length - 1]);
      }
      if (
        newStep[0] &&
        (Object.keys(newStep[0]).length === 0 ||
          newStep[0].answer_type === "Followup") &&
        data.triggeredBy !== "followup"
      ) {
        // console.log(newStep);
        renderSteps[renderSteps.length - 1].followup.push(...newStep);
        // console.log(renderSteps[renderSteps.length - 1]);
        if (this.state.surveyType === "Form") {
          renderSteps[renderSteps.length - 1].department[
            renderSteps[renderSteps.length - 1].followup.length - 1
          ].editable = true;
          if (
            renderSteps[renderSteps.length - 1].department[
              renderSteps[renderSteps.length - 1].followup.length - 2
            ]
          ) {
            renderSteps[renderSteps.length - 1].department[
              renderSteps[renderSteps.length - 1].followup.length - 2
            ].editable = false;
          }
        }
        newStep = [];
      }
      // console.log(JSON.stringify([...renderSteps, ...newStep]));
      if (newStep[0] && Object.keys(newStep[0]).length > 0) {
        if (newStep[0].id === "EndSurvey") {
          this.setState({
            redirectToEndScreen: true,
          });
        } else {
          if (
            res.data.response === 3 &&
            res.data.is_follow_up === false &&
            newStep[0].answer_type === "Subjective"
          ) {
            newStep[0].department.forEach((dept) => {
              dept.editable = false;
            });
          }
          // console.log([...renderSteps, ...newStep]);
          this.setState({
            renderSteps: [...renderSteps, ...newStep],
          });
          this.scrollToBottom();
        }
      } else {
        this.setState({
          renderSteps,
        });
        this.scrollToBottom();
      }
      // console.log(res.data.response, renderSteps.length);
      if (renderSteps.length === 1 && res.data.response === 3) {
        this.handleModal();
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  async editAnswer(data) {
    try {
      const { renderSteps } = this.state;
      const params = {};
      params.question_id = renderSteps[renderSteps.length - 1].id;
      params.answer_id = data;
      // console.log(renderSteps);
      if (typeof data === "object") {
        if (data.questionId) {
          params.question_id = data.questionId;
          params.answer = data.value;
          const regex = /'/g;
          params.answer = params.answer.replace(regex, "\\'");
          delete params.answer_id;
        } else {
          params.answer_id = String(data.value);
        }
        params.department_id = data.dept;
      }
      axiosConfig.headers["x-auth"] =
        window.location.href.includes("hrlogica") == true
          ? await sessionStorage.getItem("x-auth")
          : await cookies.get("x-auth");
      // console.log('parameter edit', params);
      let res = await axios.post(
        `/surveys/${this.state.surveyId}/answers/edit`,
        params,
        axiosConfig
      );
      // console.log('response', res);
      res = await res.data;
      if (res.error) {
        throw new Error("Data not found");
      } else if (typeof data === "object" && data.questionId && !res.error) {
        if (
          renderSteps[renderSteps.length - 1].followup.find(
            (obj) => obj.department_id === data.dept
          ) !== undefined
        ) {
          renderSteps[renderSteps.length - 1].followup.find(
            (obj) => obj.department_id === data.dept
          ).answered = true;
        }
      }
      // console.log(JSON.stringify(res.data));
      let newStep = await convertToQuestion(res.data);
      // console.log(newStep);
      if (
        res.data.response === 3 &&
        renderSteps[renderSteps.length - 1].department.length - 1 ===
          renderSteps[renderSteps.length - 1].followup.length
      ) {
        if (renderSteps[renderSteps.length - 1].followup.length === 0) {
          renderSteps[renderSteps.length - 1].followup.push({});
        } else {
          renderSteps[renderSteps.length - 1].department[
            renderSteps[renderSteps.length - 1].followup.length - 1
          ].editable = false;
          renderSteps[renderSteps.length - 1].followup.push({});
          renderSteps[renderSteps.length - 1].department[
            renderSteps[renderSteps.length - 1].followup.length - 1
          ].editable = true;
        }
      }
      if (
        res.data.response === 1 &&
        (!res.data.is_follow_up || res.data.is_follow_up === false) &&
        newStep[0] &&
        Object.keys(newStep[0]).length === 0 &&
        data.triggeredBy !== "followup"
      ) {
        // Triggers when Subjective Question's Subjective answers is edited
        renderSteps[renderSteps.length - 1].department.find(
          (d) => d.department_id === data.dept
        ).editable = false;
        renderSteps[renderSteps.length - 1].followup.push({});
        renderSteps[renderSteps.length - 1].department[
          renderSteps[renderSteps.length - 1].followup.length - 1
        ].editable = true;
      }
      if (
        newStep[0] &&
        res.data.is_follow_up === true &&
        (Object.keys(newStep[0]).length === 0 ||
          newStep[0].answer_type === "Followup") &&
        data.triggeredBy !== "followup"
      ) {
        // console.log(newStep);
        renderSteps[renderSteps.length - 1].followup.push(...newStep);
        if (Object.keys(newStep[0]).length === 0) {
          // console.log(renderSteps[renderSteps.length - 1]);
          renderSteps[renderSteps.length - 1].department[
            renderSteps[renderSteps.length - 1].followup.length - 1
          ].editable = true;
          if (
            renderSteps[renderSteps.length - 1].department[
              renderSteps[renderSteps.length - 1].followup.length - 2
            ]
          ) {
            renderSteps[renderSteps.length - 1].department[
              renderSteps[renderSteps.length - 1].followup.length - 2
            ].editable = false;
          }
        }
        newStep = [];
      }
      // console.log(JSON.stringify([...renderSteps, ...newStep]));
      if (newStep[0] && Object.keys(newStep[0]).length > 0) {
        if (newStep[0].id === "EndSurvey") {
          this.setState({
            redirectToEndScreen: true,
          });
        } else {
          this.setState({
            renderSteps: [...renderSteps, ...newStep],
          });
        }
      } else {
        this.setState({
          renderSteps,
        });
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  deleteLastStep(dept) {
    const renderSteps = this.state.renderSteps;
    if (
      renderSteps[renderSteps.length - 2].department &&
      dept ===
        renderSteps[renderSteps.length - 2].department[
          renderSteps[renderSteps.length - 2].department.length - 1
        ].department_id &&
      renderSteps[renderSteps.length - 2].department.length > 1
    ) {
      renderSteps.pop();
      this.setState({
        renderSteps,
      });
    }
  }

  scrollToBottom() {
    if (this.messagesEnd !== null) {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  }

  redirectToEndScreen() {
    this.setState({
      endScreen: true,
    });
  }

  editPreviousAnswer = async (id) => {
    axiosConfig.headers["x-auth"] =
      window.location.href.includes("hrlogica") == true
        ? await sessionStorage.getItem("x-auth")
        : await cookies.get("x-auth");
    let companyId =
      window.location.href.includes("hrlogica") == true
        ? await sessionStorage.getItem("cid")
        : await Number(cookies.get("cid"));
    if (id.followup.length > 0 && id.followup[0].id !== undefined) {
      await axios.delete(
        "/users/" + companyId + "/delete_last_answer/" + id.followup[0].id,
        axiosConfig
      );
    }
    axios
      .delete(
        "/users/" + companyId + "/delete_last_answer/" + id.id,
        axiosConfig
      )
      .then(async (res) => {
        if (res.data.affected_rows > 0) {
          let newSteps = this.state.renderSteps;
          newSteps.splice(newSteps.length - 2, 2);

          this.setState(
            {
              renderSteps: newSteps,
            },
            async () => {
              axiosConfig.headers["x-auth"] =
                window.location.href.includes("hrlogica") == true
                  ? await sessionStorage.getItem("x-auth")
                  : await cookies.get("x-auth");
              const survey_id = await cookies.get("survey_id");
              let res = await axios.get(
                `/surveys/active?survey_id=${survey_id}`,
                axiosConfig
              );
              res = res.data;
              if (res.data.surveys.length === 0) {
                this.setState({
                  noSurvey: true,
                });
              } else {
                const surveyId =
                  res.data.surveys[res.data.surveys.length - 1].id;
                const answeredQuestions =
                  res.data.surveys[res.data.surveys.length - 1]
                    .answered_questions;
                let renderSteps = [];
                const surveyType =
                  res.data.surveys[res.data.surveys.length - 1].survey_type;
                this.setState({
                  surveyType,
                });
                if (answeredQuestions.length > 0) {
                  // console.log(JSON.stringify(answeredQuestions));
                  renderSteps = await convertToQuestionAnswer(
                    answeredQuestions
                  );
                }
                let resSurvey = await axios.get(
                  `/surveys/${surveyId}?question=true`,
                  axiosConfig
                );
                resSurvey = await resSurvey.data;
                if (
                  res.data.surveys[res.data.surveys.length - 1].survey_type ===
                  "General Form"
                ) {
                  const surveyQuestion = await convertToQuestion(
                    resSurvey.data
                  );
                  if (surveyQuestion[0].answer_type === "Followup") {
                    renderSteps[renderSteps.length - 1].followup.pop();
                    renderSteps[renderSteps.length - 1].followup.push(
                      ...surveyQuestion
                    );
                  } else {
                    renderSteps = [...renderSteps, ...surveyQuestion];
                  }
                } else {
                  if (
                    resSurvey.data.current_question &&
                    renderSteps[renderSteps.length - 1].id ===
                      resSurvey.data.current_question.id
                  ) {
                    // comparing last set with new set
                    const lastStep = renderSteps[renderSteps.length - 1];
                    renderSteps.pop();
                    renderSteps.push(
                      ...convertToQuestion(resSurvey.data, lastStep)
                    );
                    // console.log(JSON.stringify(renderSteps));
                  }
                  if (
                    resSurvey.data.next_question &&
                    resSurvey.data.response === 2
                  ) {
                    // adding followups
                    // console.log(JSON.stringify(renderSteps));
                    renderSteps[renderSteps.length - 1].followup.pop();
                    renderSteps[renderSteps.length - 1].followup.push(
                      ...convertToQuestion(resSurvey.data)
                    );
                  }
                  if (
                    resSurvey.data.next_question &&
                    resSurvey.data.response !== 2
                  ) {
                    renderSteps.push(...convertToQuestion(resSurvey.data));
                  }
                }
                if (resSurvey.data.end_survey) {
                  this.setState({
                    redirectToEndScreen: true,
                  });
                }
                if (renderSteps[0].id === "Welcome") {
                  renderSteps.shift();
                }
                this.setState({
                  renderSteps,
                  surveyId,
                });
                if (renderSteps.length === 2 && resSurvey.data.response === 3) {
                  this.handleModal();
                }
                this.scrollToBottom();
              }
            }
          );
        }
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.renderSteps.length !== prevState.renderSteps.length) {
      this.scrollToBottom();
    }
  }

  handleClose() {
    this.setState({
      isModalVisible: false,
    });
  }

  handleModal() {
    this.setState({
      isModalVisible: true,
    });
  }

  renderStep(step, index) {
    let english = [
      "training",
      "guidance",
      "join",
      "learning",
      "work",
      "performance",
      "appreciate",
      "incentive",
      "earn",
      "encourage",
      "teamwork",
      "follow",
      "supervisor",
      "feedback",
      "support",
      "development",
      "vision",
      "organisation",
      "recommend",
      "feedback",
      "suggestions",
      "HR",
      "journey",
      "Organisation",
      "perform",
      "SOPs",
      "manager",
      "organisation",
    ];
    if (step.answer_type === "MCQ") {
      return (
        <React.Fragment key={index}>
          <QuestionBubble>
            <QuestionText qTheme={this.state.theme}>
              {step.message}
            </QuestionText>
          </QuestionBubble>
          <div
            style={{
              marginTop: "2.5rem",
              paddingLeft: "1rem",
              paddingBottom: "3rem",
              color: "white",
              fontSize: "1.1rem",
            }}
          >
            <ul style={{ textAlign: "left" }}>
              <li>
                To know more about the function, you can click on Survey
                Guidelines at the top right corner of the page.
              </li>
              <li>
                Click again on a selected function in case you want to unselect
                that function.
              </li>
              <li>
                <sup>*</sup>Please note that:{" "}
                <strong>You can only Edit your last submitted response.</strong>{" "}
                For indication they are marked with{" "}
                <Icon
                  style={{
                    color: "#00ffff",
                    background: "none",
                  }}
                  name="pencil"
                  size="large"
                />
              </li>
              <li>Any responses submitted earlier can NOT be edited.</li>
            </ul>
          </div>
          <DepartmentOptions
            mainTheme={this.state.theme}
            options={step.answers}
            submitAnswer={this.submitAnswer}
          />
        </React.Fragment>
      );
    }

    if (step.answer_type === "Objective" || step.answer_type === "Subjective") {
      // console.log(step.message);

      let _finalMessage = "";
      let krutiDev = "";
      if (
        (process.env.NODE_ENV === "production" &&
          localStorage.getItem("cid") === "21") ||
        (process.env.NODE_ENV === "development" && cookies.get("cid") === "17")
      ) {
        let messageSplit = step.message.split(" ");
        for (let e of messageSplit) {
          if (english.indexOf(e) !== -1) {
            _finalMessage += `<span style="font-family: 'kruti dev';">${krutiDev}</span>`;
            _finalMessage += e + " ";
            krutiDev = "";
          } else {
            krutiDev += e + " ";
          }
        }
        if (krutiDev.length > 0) {
          _finalMessage += `<span style="font-family: 'kruti dev';">${krutiDev}</span>`;
        }
      } else {
        _finalMessage = step.message;
      }

      return (
        <React.Fragment key={index}>
          <QuestionBubble>
            <QuestionText qTheme={this.state.theme}>
              {companies ? index : index + 1}.{" "}
              <span dangerouslySetInnerHTML={{ __html: _finalMessage }} />
            </QuestionText>
          </QuestionBubble>
          <div style={{ display: "inline-block"}}>
            <Departments
              step={step}
              lastStep={index === this.state.renderSteps.length - 1}
              lastStepLastOption={
                index === this.state.renderSteps.length - 2 &&
                this.state.renderSteps[index + 1] &&
                (this.state.renderSteps[index + 1].followup === undefined ||
                  this.state.renderSteps[index + 1].followup.length === 0)
              }
              deleteLastStep={this.deleteLastStep}
              editAnswer={this.editAnswer}
              submitAnswer={this.submitAnswer}
              theme={this.state.theme}
            />

            {((process.env.NODE_ENV === "development" && cid === 6) ||
              (process.env.NODE_ENV === "production" && cid === 5)) &&
            this.state.renderSteps.length > 1
              ? this.state.renderSteps[this.state.renderSteps.length - 2].id ===
                  step.id &&
                this.state.redirectToEndScreen == false && (
                  <Icon
                    style={{
                      cursor: "pointer",
                      color: `${this.state.theme.btnBg}`,
                      background: "none",
                    }}
                    onMouseDown={() => this.editPreviousAnswer(step)}
                    size="large"
                    name="pencil"
                  />
                )
              : null}
          </div>
        </React.Fragment>
      );
    }
    return null;
  }

  render() {
    const { renderSteps } = this.state;

    const backImageStyle = {
      margin: "0",
      padding: "2vh 0 0 0",
      backgroundImage:
        (process.env.NODE_ENV == "production" && cid == 32) ||
        (process.env.NODE_ENV == "development" && cid == 55)
          ? `url('https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/Picture2.png')`
          : `url('https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/cine_bck.png')`,
      width: "100%",
      minHeight: "100vH",
      fontFamily: "Source Sans Pro, sans-serif",
      overflow: "hidden",
    };
    const simpleBackStyle = {
      margin: "0",
      padding: "2vh 0 0 0",
      backgroundColor: this.state.theme.formBG,
      width: "100%",
      minHeight: "100vH",
      fontFamily: "Source Sans Pro, sans-serif",
      overflow: "hidden",
    };

    // console.log(JSON.stringify(renderSteps));
    return this.state.noSurvey === true ? (
      <Redirect to="/no_survey" />
    ) : this.state.endScreen === true ? (
      <Redirect to="/end_survey" />
    ) : (
      <React.Fragment>
        <Header headTheme={this.state.theme} />
        {/* <div
          style={{
            margin: '0',
            padding: '0',
            position: 'fixed',
            top: '9vH',
            zIndex: '1',
            width: '100%',
          }}
        >
          <Progress
            style={{
              height: '24px',
            }}
            percent={
              this.state.redirectToEndScreen === true
                ? 100
                : (process.env.NODE_ENV === 'production' && cid === 19) ||
                  (process.env.NODE_ENV === 'development' && cid === 16)
                ? (2.63 * (renderSteps.length - 1)).toFixed(2)
                : (5.55 * (renderSteps.length - 1)).toFixed(2)
            }
            progress
            active
            size="medium"
            color="green"
            autoSuccess
          />
        </div> */}

        <div style={simpleBackStyle}>
          <div
            style={{
              margin: window.innerWidth > 500 ? "2vH 5% 0 5%" : "2vH 5% 0 5%",

              padding: "2vH 0 8vH 0",
              overflow: "hidden",
            }}
          >
            {cid === 4 ? (
              <img
                src="https://munna-storage.s3.ap-south-1.amazonaws.com/static/assets/cr1.jpeg"
                alt="Girl in a jacket"
                style={{ height: "50vh", marginTop: "5vh" }}
              />
            ) : null}

            {/* <div
              style={{
                border: "2px solid #343a40",
                height: "25%",
                width: "60%",
                color: "#ff883a",
                marginTop: "5%",
                fontSize: "1.2rem",
                padding: "20px",
              }}
            >
             
              <p
          style={{
            fontWeight: "bold",
            fontSize: "1.6em",
            textAlign: "justify"
          }}
        >
          Points to remember before you begin:
        </p>

        <ul
          style={{
            // fontSize: "1.4em",
            textAlign: "justify",
            // fontWeight: "bold"
          }}
        >
          <li>All questions are mandatory to respond to.</li>
          <li>
            Read the question and rating scale carefully before responding.
          </li>
          <li>
            You can move on to the next question as soon as you respond to the
            previous one.
          </li>
          <li>
          In case you aren’t able to complete the survey in one go, you
                may use the same link and the survey will resume from where you
                last left.
          </li>
          <li>
          Please note that once you have responded to a question, it cannot be edited. 
          </li>
          <li>
            <strong>Confidentiality: </strong> We assure you that we will keep
            your data and your responses highly confidential. Make sure you
            respond in a frank and honest manner.
          </li>
        </ul>
            </div> */}

            {_.map(renderSteps, this.renderStep)}
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.messagesEnd = el;
              }}
            />
            {this.state.redirectToEndScreen === true ? (
              <div
                style={{
                  textAlign: "center",
                  margin: "5rem auto",
                  overflow: "hidden",
                }}
              >
             
                <span
                  style={{
                    textAlign: "center",
                    // background: "#ff6600",
                    background: isUPSSurvey ? '#2363A0' : '#ff6600',
                    color: "white",
                    fontWeight: "600",
                    fontSize: "1.3rem",
                    borderRadius: "5px",
                    padding: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={this.redirectToEndScreen}
                >
                  Submit Responses
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <Modal
          backdrop={true}
          className="modal-container"
          style={{ display: "flex", alignItems: "center" }}
          backdropStyle={{
            opacity: "0.5",
            zIndex: 2,
            height: "100% !important",
            position: "fixed",
          }}
          animation={false}
          show={this.state.isModalVisible}
          onHide={this.handleClose}
        >
          <Modal.Body>
            <Modal.Title style={{ textAlign: "center", fontSize: "1.3rem" }}>
              Please note that:
              <br />
              <strong>You can only Edit your last submitted response.</strong>
              <br />
              <br />
              For indication they are marked with{" "}
              <Icon
                style={{
                  color: "#00ffff",
                  background: "none",
                }}
                name="pencil"
                size="large"
              />
              <br />
              Any responses submitted earlier can NOT be edited.
            </Modal.Title>
            <center>
              <Button
                style={{
                  marginTop: "20px",
                  color: "white",
                  backgroundColor: "#25c16f",
                  fontFamily: '"Roboto","Open Sans", sans-serif',
                  transition: "background-color .15s ease-in",
                  textTransform: "uppercase",
                  display: "block",
                  fontSize: 14,
                  fontWeight: 600,
                }}
                onClick={this.handleClose}
              >
                Okay, I got it.
              </Button>
            </center>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
