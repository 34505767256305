export default [
    {
        key: 'id',
        types: ['string', 'number'],
        required: true,
    },
    {
        key: 'options',
        types: ['object'],
        required: false,
    },
    {
        key: 'selected',
        types: ['boolean'],
        required: false,
    },
    {
        key: 'end',
        types: ['boolean'],
        required: false,
    },
    {
        key: 'multipleChoice',
        types: ['boolean'],
        required: false,
    },
    {
        key: 'message',
        types: ['string'],
        required: false,
    },
    {
        key: 'user',
        types: ['string'],
        required: false,
    },
    {
        key: 'animation',
        types: ['number'],
        required: false,
    },
    {
        key: 'trigger',
        types: ['string'],
        required: false,
    },
    {
        key: 'component',
        types: ['any'],
        required: true,
    },
    {
        key: 'asMessage',
        types: ['boolean'],
        required: false,
    },
    {
        key: 'waitAction',
        types: ['boolean'],
        required: false,
    },
];