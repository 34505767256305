import React from "react";
// import LittleNapLogo from "../../../icons/LittleNap.jpg";
// import Incred from "../../../icons/IncredLogo.png";
// import FirstSource from "../../../icons/FirstsourceLogo.png";
// import HRLogica from "../../../icons/HRLogicaNew.png";
import Cookies from "universal-cookie";
//import CineGrand from "../../../icons/CGlogo.jpg";
import { Button } from "semantic-ui-react";
const axios = require("axios");
const axiosConfig = require("../../../components/AxiosConfig/AxiosConfig");
import "./style.css";
import "./header.css";
const cookies = new Cookies();
const surveyId = cookies.get("survey_id");
const isUPSSurvey = () => (process.env.NODE_ENV == 'production' && surveyId == 29) ||
(process.env.NODE_ENV == 'development' && surveyId == 326);
const companyLogos = {
  FirstsourceLogo:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/FirstsourceLogo.png",
  HRLogica:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/HRLogicaNew.png",
  Incred:
    cookies.get("survey_id") == 11
      ? "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/IncredLogo.png"
      : "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/incred-wealth.png",
  CineGrand:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/CGlogo.jpg",
  LittleNapLogo:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/LittleNap.jpg",
  UPS_Logo: "https://i.imgur.com/FcbHJah.jpeg",
  // "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/UPS-Logo.png", OLD UPS LOGO
};

const cid =
  window.location.href.includes("hrlogica") == true
    ? Number(sessionStorage.getItem("cid"))
    : Number(cookies.get("cid"));
const companies =
  (process.env.NODE_ENV == "production" && cid == 16) ||
  (process.env.NODE_ENV == "development" && cid == 13) ||
  (process.env.NODE_ENV == "development" && cid == 64) ||
  (process.env.NODE_ENV == "production" && cid == 38);
export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: false,
      surveyName: "",
    };
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  async componentDidMount() {
    const survey_id = await cookies.get("survey_id");
    axiosConfig.headers["x-auth"] = await cookies.get("x-auth");
    let res = await axios.get(`/surveys/${surveyId}`, axiosConfig);
    res = res.data;
    // console.log(result.data.data.survey.survey_name);
    try {
      this.setState({
        surveyName: res.data.survey.survey_name,
      });
      // return emojify(result.data.data.survey.survey_name);
    } catch (e) {
      this.setState({
        surveyName: "Xane Survey",
      });
    }
  }

  handleClick(e) {
    this.toggleSidebar();
    e.stopPropagation();
  }

  toggleSidebar() {
    this.setState({
      sidebar: !this.state.sidebar,
    });
  }

  render() {
    const cid =
      window.location.href.includes("hrlogica") == true
        ? Number(sessionStorage.getItem("cid"))
        : Number(cookies.get("cid"));
    console.log(this.props.headTheme.logoSource);
    return (
      
      <div
        style={{
          position: "fixed",
          top: "0",
          height: "11vh",
          background: "white",
          textAlign: "center",
          // boxShadow: '0px 5px 5px grey',
          width: "100%",
          zIndex: "1",
          borderBottom: isUPSSurvey ? "2px solid #2363A0": null ,
        }}
      >
        {/* <MenuButton active={this.state.sidebar} onClick={this.handleClick} />
        <Sidebar handleMouseDown={this.handleClick} menuVisibility={this.state.sidebar} /> */}
        <img
          src={
            (process.env.NODE_ENV === "production" && cid === 22) ||
            (process.env.NODE_ENV === "development" && cid === 19)
              ? companyLogos.LittleNapLogo
              : process.env.NODE_ENV === "development" && cid === 48
              ? companyLogos.CineGrand
              : (process.env.NODE_ENV == "production" && cid == 1) || //change made for incred (31Aug2021)
                (process.env.NODE_ENV == "development" && cid == 32) ||
                (process.env.NODE_ENV == "development" && cid == 55)
              ? companyLogos.Incred
              : (process.env.NODE_ENV == "production" && cid == 16) ||
                (process.env.NODE_ENV == "development" && cid == 13)
              ? companyLogos.FirstSource
              : process.env.NODE_ENV == "production" && cid == 5
              ? companyLogos.UPS_Logo
              : cookies.get("company_logo")
            // : this.props.headTheme.logoSource
          }
          alt={
            (process.env.NODE_ENV === "production" && cid === 19) ||
            (process.env.NODE_ENV === "development" && cid === 19)
              ? "HRLogica"
              : null
          }
          width={
            process.env.NODE_ENV === "development" && cid === 55
              ? "180vw"
              : (process.env.NODE_ENV == "production" && cid == 5) ||
                (process.env.NODE_ENV == "development" && cid == 6)
              ? 97
              : "auto"
          }
          height={
            (process.env.NODE_ENV == "production" && cid == 32) ||
            (process.env.NODE_ENV == "development" && cid == 55)
              ? "80%"
              : (process.env.NODE_ENV == "production" && cid == 5) ||
                (process.env.NODE_ENV == "development" && cid == 6)
              ? 43
              : "90%"
          }
          style={{
            position: "relative",
            float: "left",
            marginLeft: "2vW",
            top: 5,
          }}
        />
        {(process.env.NODE_ENV === "production" && cid === 19) ||
        (process.env.NODE_ENV === "development" && cid === 16) ? null : (
          <div
            className="show sTitle"
            style={{
              fontWeight: 900,
              marginTop: "20px",
              fontSize: "1.4rem",
              // margin: 'auto',
            }}
          >
            {(process.env.NODE_ENV == "production" && cid == 32) ||
            (process.env.NODE_ENV == "development" && cid == 55) ||
            (process.env.NODE_ENV == "development" && cid == 6) ||
            (process.env.NODE_ENV == "production" && cid == 5)
              ? ""
              : surveyId == 11
              ? "InCred Employee Survey"
              : surveyId == 12
              ? "InCred Wealth Employee Survey"
              : this.state.surveyName}
            {/* : this.props.headTheme.surveyTitle} */}

            {/* <a
              style={{
                cursor: 'pointer',
                position: 'relative',
                color: '#2a2a2a',
                float: 'right',
                fontSize: '3vH',
                verticalAlign: 'middle',
                marginTop: '1vH',
                marginRight: '5vW',
                border: '2px solid #2a2a2a',
                borderRadius: '5px',
                padding: '1vH',
              }}
              href="https://web.xane.ai/FAQ_FS.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Survey Guidelines
            </a> */}
            {(process.env.NODE_ENV == "production" && cid == 32) ||
            (process.env.NODE_ENV == "development" && cid == 55) ||
            companies ? (
              <Button
                style={{
                  background: companies ? "#2a2a2a" : "#ff6600",
                  color: "white",
                  borderRadius: "5px",
                  padding: "1rem",
                  float: "right",
                }}
              >
                <a
                  style={{ color: "#fff" }}
                  target="_blank"
                  href={
                    companies
                      ? "https://munna-storage.s3.ap-south-1.amazonaws.com/FS_Survey_Guidelines.pdf"
                      : "https://munna-storage.s3.ap-south-1.amazonaws.com/FAQs.pdf"
                  }
                >
                  Survey Guidelines
                </a>
              </Button>
            ) : null}
          </div>
        )}
         {(process.env.NODE_ENV == "production" && surveyId == 29) ||
            (process.env.NODE_ENV == "development" &&  surveyId == 326) ?
        <div className="info">
        <div className="infoInner">
        <p style={{color:"#267500"}}>5-Excellent</p>
        <p style={{color:"#5BD820"}}>4-Good</p>
        <p style={{color:"#FFC300"}}>3-Average</p> 
        <p style={{color:"#F0940F"}}>2-Below Average</p>
        <p style={{color:"#FF0000"}}>1-Poor</p>
        </div>
        </div>:null}
                
      </div>
      : null 
    );
  }
}
