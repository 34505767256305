/* eslint-disable eqeqeq */
import React from 'react';

import Cookies from 'universal-cookie';

const cookies = new Cookies();
const surveyId = cookies.get('survey_id');
const isUPSSurvey = () => (process.env.NODE_ENV == 'production' && surveyId == 29) ||
(process.env.NODE_ENV == 'development' && surveyId == 326);

const NoSurvey = () => (
  <div
    style={{
      minHeight: '100vH',
      // background: "#2a2a2a",
      background: isUPSSurvey ? '#fff' : '#2a2a2a',
      // color: '#00ffff',
      color: isUPSSurvey ? '#2363A0' : '#ff883a',
      fontWeight: '700',
      fontSize: '1.3rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center' /* align horizontal */,
      alignItems: 'center',
      verticalAlign: 'middle',
      width: "95%",
      margin:"auto",

    }}
  >
    {/* The Survey has ended. Your responses (if any) have been submitted. */}
    <p> Thank you for successfully completing this survey.</p>
    <p>
      It was a pleasure talking to you! Thank you for your valuable feedback!
    </p>
  </div>
);

export default NoSurvey;
