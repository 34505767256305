import styled from 'styled-components';
import defaultTheme from './theme';
import { pulse } from './common/animations';

const fillFunc = (props) => {
  const { speaking, invalid, theme } = props;

  if (speaking) {
    return theme.headerBgColor;
  }
  return invalid ? '#E53935' : '#4a4a4a';
};

const SubmitButton = styled.button`
  background-color: #08323d;
  border: none;
  border-radius: 5px;
  box-shadow: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  fill: #00ffff;
  opacity: ${props => (props.disabled && !props.invalid ? '.5' : '1')};
  outline: none;
  padding: 17px 45px 12px 40px;
  position: absolute;
  top: 0px;
  right: 5vw;
  &:before {
    content: '';
    position: absolute;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    animation: ${({ theme, speaking }) =>
      speaking ? `${pulse(theme.headerBgColor)} 2s ease infinite` : ''};
  }
  &:not(:disabled):hover {
    opacity: 0.7;
  }
  @media screen and (max-width: 520px) {
    right: 13vw;
  }
  @media screen and (max-width: 450px) {
    right: 8vw;
  }
  @media screen and (max-width: 520px) {
    padding: 18px 13px 12px 14px;
  }
`;

SubmitButton.defaultProps = {
  theme: defaultTheme,
};

export default SubmitButton;
