import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import DateComponent from './DateComponent';
import DateElement from './DateElement';

class SubmitButton extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <DateComponent style={{ cursor: 'pointer' }}>
        <DateElement bubbleColor="#21ba45" onClick={() => this.props.onClick()}>
          <Icon name="arrow right" color="black" />
        </DateElement>
      </DateComponent>
    );
  }
}
export default SubmitButton;
