import styled from 'styled-components';
import { scale } from '../../common/animations';

const MultipleSelect = styled.li`
animation: ${scale} .3s ease forwards;
cursor: pointer;
display: inline-block;
margin: 2px;
transform: scale(0);
`;
export default MultipleSelect;
