import styled from 'styled-components';

const Dates = styled.ul`
  margin: 2px 0 13px 62px;
  padding: 0px 70px;
  @media screen and (max-width: 450px) {
    margin: 2px 0 13px 12px;
  }
  @media screen and (min-width: 1400px) {
    margin: 2px 0 13px 70px;
  }
`;

export default Dates;
