import styled from "styled-components";
import defaultTheme from "../../theme";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const Incred =
  (cookies.get("survey_id") == 2 && process.env.NODE_ENV == "development") ||
  (cookies.get("survey_id") == 6 && process.env.NODE_ENV == "development") ||
  (cookies.get("survey_id") == 2 && process.env.NODE_ENV == "production");
const OptionElement =
  process.env.NODE_ENV === "staging"
    ? styled.a`
        background: ${({ theme }) => theme.botBubbleColor};
        border-radius: 22px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
        color: ${({ theme }) => theme.botFontColor};
        display: inline-block;
        font-size: 16px;
        padding: 12px;

        &:hover {
          opacity: 0.7;
        }
      `
    : styled.a`
        background: ${({ theme }) =>
          Incred ? "#f8981c" : theme.botBubbleColor};
        border-radius: 22px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
        color: ${({ theme }) => theme.botFontColor};
        display: inline-block;
        font-size: 16px;
        padding: 12px;

        &:hover {
          opacity: 0.7;
        }
      `;

OptionElement.defaultProps = {
  theme: defaultTheme,
};

export default OptionElement;
