import styled from 'styled-components';
import { scale } from '../../common/animations';
import defaultTheme from '../../theme';
import Cookies from "universal-cookie";
const cookies = new Cookies();
const ITC = cookies.get("cid") == 218 && process.env.NODE_ENV === "development"

const Bubble = styled.div`
  animation: ${scale} 0.3s ease forwards;
  background: ${props => (props.user ? defaultTheme.userBubbleColor : props.step == 2916 ? "green" :  defaultTheme.botBubbleColor)};
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: ${props => (props.user ? defaultTheme.userFontColor : defaultTheme.botFontColor)};
  display: inline-block;
  font-size: 16px;
  max-width: ${()=>{
    return ITC ? '80%':"50%"
  }};
  margin: ${(props) => {
    const { isFirst, showAvatar, user } = props;
    if (!isFirst && showAvatar) {
      return user ? '-8px 46px 10px 0' : ITC ? '4px 14px' : '-4px 0 10px 75px';
    } else if (!isFirst && !showAvatar) {
      return user ? '-8px 0px 10px 0' : '-8px 0 10px 0px';
    }

    return ITC ? '1vw 14px 16px 10px' :'1vw 29px 16px 15px';
  }};
  overflow: hidden;
  position: relative;
  padding: 10px;
  transform: scale(0);
  transform-origin: ${(props) => {
    const { isFirst, user } = props;
    if (isFirst) {
      return user ? 'bottom right' : 'bottom left';
    }

    return user ? 'top right' : 'top left';
  }};
  @media screen and (max-width: 650px) {
    margin: ${(props) => {
      const { isFirst, showAvatar, user } = props;
      if (!isFirst && showAvatar) {
        return user ? '-8px 46px 10px 0' :ITC ? '4px 14px' : '-4px 0 10px 75px';
      } else if (!isFirst && !showAvatar) {
        return user ? '-8px 0px 10px 0' : '-8px 0 10px 0px';
      }

      return ITC ? '1vw 14px 16px 10px' : '1vw 29px 16px 15px';
    }};
  }

  @media screen and (min-width: 1883px) {
    margin: ${(props) => {
      const { isFirst, showAvatar, user } = props;
      if (!isFirst && showAvatar) {
        return user ? '-8px 46px 10px 0' :ITC ? '4px 14px' : '-4px 0 10px 85px';
      } else if (!isFirst && !showAvatar) {
        return user ? '-8px 0px 10px 0' : '-8px 0 10px 0px';
      }

      return ITC ? '1vw 14px 16px 10px' : '1vw 29px 16px 15px';
    }};
  }

  @media screen and (max-width: 420px) {
    margin: ${(props) => {
      const { isFirst, showAvatar, user } = props;
      if (!isFirst && showAvatar) {
        return user ? '-8px 46px 10px 0' :ITC ? '4px 10px' : '-4px 0 10px 85px';
      } else if (!isFirst && !showAvatar) {
        return user ? '-8px 0px 10px 0' : '-8px 0 10px 0px';
      }
  
      return ITC ? '1vw 2px 16px 10px' : '1vw 29px 16px 15px';
    }};
  }
`;

 


Bubble.defaultProps = {
  theme: defaultTheme,
};

export default Bubble;
