import styled from "styled-components";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const ITC = cookies.get("cid") == 218 && process.env.NODE_ENV === "development";

const MultipleSelects = styled.ul`
  margin: ${()=>{
    return ITC ? "2px 0 13px 13px" : "2px 0 13px 62px"
  }} ;
  padding: ${() => {
    return ITC ? "0px" : "0px 70px";
  }} 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media screen and (max-width:450px){
  margin: 2px 0 13px 12px;
  padding: ${() => {
    return ITC ? "0px" : "0px 70px";
  }} 
  }
  @media screen and (min-width:1400px){
  margin: 2px 0 13px 62px;
  padding: ${() => {
    return ITC ? "-10px" : "0px 70px";
  }} 
  }
`;

export default MultipleSelects;
