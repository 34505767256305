import React from "react";
import ReactTooltip from "react-tooltip";
import DepartmentAnswerStyle from "./DepartmentAnswerStyle";
import _ from "lodash";
import emoji from "node-emoji";
// import cry from "../../../icons/cry.png";
// import slightly_frowning_face from "../../../icons/slightly_frowning_face.png";
// import neutral_face from "../../../icons/neutral_face.png";
// import slightly_smiling_face from "../../../icons/slightly_smiling_face.png";
// import smiley from "../../../icons/smiley.png";
// import Angry from "../../../icons/Angry.png";
// import Very_Angry from "../../../icons/Very_Angry.png";
// import Very_Happy from "../../../icons/Very_Happy.png";
// import Neutral from "../../../icons/Neutral.png";
// import Happy from "../../../icons/Happy.png";
import { Icon } from "semantic-ui-react";
import FollowupLabel from "./FollowupLabel";
import FollowupInput from "./FollowupInput";

const labelImages = {
  Happy:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/Happy.png",
  Very_Happy:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/Very_Happy.png",
  Very_Angry:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/Very_Angry.png",
  Neutral:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/Neutral.png",
  Angry:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/Angry.png",
  slightly_frowning_face:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/slightly_frowning_face.png",
  slightly_smiling_face:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/slightly_smiling_face.png",
  neutral_face:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/neutral_face.png",
  cry: "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/cry.png",
  smiley:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/smiley.png",
};

class Departments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: this.props.step,
      edit: false,
      active: 0,
      isSelected: {},
      disabled: {},
    };
    this.renderDepartments = this.renderDepartments.bind(this);
    this.departmentSelectObjective = this.departmentSelectObjective.bind(this);
    this.departmentSelectSubjective =
      this.departmentSelectSubjective.bind(this);
    this.departmentSelectSubjectiveEdit =
      this.departmentSelectSubjectiveEdit.bind(this);
    this.handleEditFollowup = this.handleEditFollowup.bind(this);
    this.handleEditSubjective = this.handleEditSubjective.bind(this);
    this.departmentSubjective = this.departmentSubjective.bind(this);
    this.departmentSubjectiveEdit = this.departmentSubjectiveEdit.bind(this);
    this.showSubjectiveQuestions = this.showSubjectiveQuestions.bind(this);
    this.handleEditButton = this.handleEditButton.bind(this);
    this.handleKrutiDevFont = this.handleKrutiDevFont.bind(this);
  }

  handleKrutiDevFont(message) {
    // Kruti-devi Juggad
    let english = [
      "training",
      "guidance",
      "join",
      "learning",
      "appreciate",
      "incentive",
      "earn",
      "encourage",
      "teamwork",
      "follow",
      "supervisor",
      "feedback",
      "support",
      "development",
      "vision",
      "organisation",
      "recommend",
      "feedback",
      "suggestions",
      "HR",
      "journey",
      "Organisation",
      "perform",
    ];
    let _finalMessage = "";
    let krutiDev = "";
    let messageSplit = message.split(" ");
    for (let e of messageSplit) {
      if (english.indexOf(e) !== -1) {
        _finalMessage += `<span style="font-family: 'kruti dev';">${krutiDev}</span>`;
        _finalMessage += e + " ";
        krutiDev = "";
      } else {
        krutiDev += e + " ";
      }
    }
    if (krutiDev.length > 0) {
      _finalMessage += `<span style="font-family: 'kruti dev';">${krutiDev}</span>`;
    }
    return _finalMessage;
  }

  componentDidMount() {
    const { department } = this.state.step;
    const disabled = Object.assign({}, this.state.disabled);
    let active = this.state.active;
    if (this.state.step.answer_type !== "Subjective") {
      const disableDepartments = department.filter((dept) => dept.answer_id);
      disableDepartments.length > 0
        ? disableDepartments.forEach((dept) => {
            active += 1;
            disabled[dept.department_id] = true;
          })
        : null;
    } else {
      const disableDepartments = department.filter((dept) => dept.answer);
      disableDepartments.length > 0
        ? disableDepartments.forEach((dept) => {
            active += 1;
            disabled[dept.department_id] = true;
          })
        : null;
    }
    this.setState({
      disabled,
      active,
    });
    // console.log(disabled);
    // console.log(active);
  }

  departmentSelectObjective(value, dept, edit) {
    // console.log(this.state.disabled);
    // console.log(this.state.active);
    if (this.state.disabled[dept]) {
      const newState = Object.assign({}, this.state.isSelected);
      newState[dept] = {};
      const disabled = Object.assign({}, this.state.disabled);
      disabled[dept] = true;
      // console.log(disabled);
      newState[dept][value] = Boolean(value);
      if (edit === true) {
        this.setState({
          disabled,
          edit: false,
          active: this.state.active + 1,
          isSelected: newState,
        });
        this.props.editAnswer({ value, dept });
      } else {
        this.setState({
          disabled,
          active: this.state.active + 1,
          isSelected: newState,
        });
        this.props.submitAnswer({ value, dept });
      }
    }
  }

  departmentSelectSubjective(value, dept, questionId) {
    // console.log(this.state.disabled);
    // console.log(this.state.active);

    // const { step } = this.state;
    // step.followup.find(obj => obj.department_id === dept).answered = true;
    // this.setState({
    //   step,
    // });
    this.props.submitAnswer({
      value,
      dept,
      questionId,
      triggeredBy: "followup",
    });
  }

  departmentSelectSubjectiveEdit(value, dept, questionId) {
    // console.log(this.state.disabled);
    // console.log(this.state.active);
    // const { step } = this.state;
    // step.followup.find(obj => obj.department_id === dept).answered = true;
    // this.setState({
    //   step,
    // });
    this.props.editAnswer({ value, dept, questionId, triggeredBy: "followup" });
  }

  departmentSubjective(value, dept, questionId) {
    // console.log(this.state.disabled);
    if (this.state.disabled[dept]) {
      const disabled = Object.assign({}, this.state.disabled);
      disabled[dept] = true;
      this.setState({
        disabled,
        active: this.state.active + 1,
      });
      this.props.submitAnswer({ value, dept, questionId });
    }
  }

  departmentSubjectiveEdit(value, dept, questionId) {
    // console.log(this.state.disabled);
    const disabled = Object.assign({}, this.state.disabled);
    // console.log(disabled);
    // console.log(this.state.disabled);
    disabled[dept] = true;
    this.setState({
      disabled,
      active: this.state.active + 1,
      edit: false,
    });
    this.props.editAnswer({ value, dept, questionId });
  }

  showSubjectiveQuestions(department) {
    this.state.disabled[department.department_id] = false;
    const disableArray = Object.keys(this.state.disabled);
    if (this.state.active === 0) {
      this.state.disabled[disableArray[this.state.active]] = true;
    } else {
      this.state.disabled[disableArray[this.state.active]] = true;
    }
    department.answer
      ? (this.state.disabled[department.department_id] = true)
      : null;
    // console.log(this.state.step);
    // console.log(this.state.active);
    // console.log(department.editable);
    // console.log("this.props.lastStep",":",this.props.lastStep)
    // console.log("this.props.lastStepLastOption",":",this.props.lastStepLastOption)
    // console.log("this.state.step.followup",":",this.state.step.followup)
    // console.log("this.state.active === this.state.step.followup.length",":",this.state.active === this.state.step.followup.length)
    // console.log("department.editable",":",department.editable)
    // console.log("this.state.edit",":",this.state.edit)
    // console.log("this.state.active",":",this.state.active)
    // console.log("this.state.step.followup.length",":",this.state.step.followup.length)
    return (
      <React.Fragment key={department.definition}>
        <FollowupInput
          theme={this.props.theme}
          dept={department.department_id}
          handleSubmit={this.departmentSubjective}
          qId={this.state.step.id}
          disabled={this.state.disabled[department.department_id]}
          answer={department.answer}
          handleEdit={this.departmentSubjectiveEdit}
          handleEditFollowup={this.handleEditSubjective}
          edit={
            (this.props.lastStep === true ||
              (this.props.lastStepLastOption === true &&
                this.state.step.followup !== null &&
                this.state.active === this.state.step.followup.length)) &&
            department.editable &&
            department.editable === true &&
            this.state.edit === false
          }
        />
      </React.Fragment>
    );
  }

  handleEditSubjective(dept) {
    this.state.step.followup.pop();
    this.props.deleteLastStep(dept);
    const disabled = Object.assign({}, this.state.disabled);
    Object.keys(disabled).forEach((key) => (disabled[key] = false));
    disabled[dept] = true;
    // console.log(this.state.disabled, this.state.active);
    // console.log(disabled);
    this.setState({
      disabled,
      active: this.state.active - 1,
    });
  }

  handleEditFollowup(dept) {
    this.props.deleteLastStep(dept);
  }

  handleEditButton(dept) {
    // console.log(this.state.isSelected);
    this.state.step.followup.pop();
    this.props.deleteLastStep(dept);
    const newState = Object.assign({}, this.state.isSelected);
    Object.keys(newState[dept]).forEach((val) => (newState[dept][val] = false));
    // console.log(newState);
    this.setState({
      edit: true,
      isSelected: newState,
      active: this.state.active - 1,
    });
  }

  renderDepartments(department, index) {
    let answers = null;
    let followup = null;
    if (this.state.step.answer_type === "Objective") {
      answers = this.state.step.answers;
      followup = this.state.step.followup;
    }
    // console.log(answers);
    // console.log(this.state.active, followup.length);
    // console.log(department.editable);
    // console.log(JSON.stringify(followup));
    const showTooltop =
      index > this.state.active
        ? true
        : index < this.state.active
        ? false
        : !!(
            index === this.state.active &&
            followup &&
            followup[index - 1] &&
            followup[index - 1].answered === false
          );
    return (
      <React.Fragment key={department.department_id}>
        <div
          style={{
            marginBottom: "20px",
            display: answers === null ? "block" : "flex",
            justifyContent:
              department.department === null ? "flex-start" : "flex-end",
            //flexWrap: "wrap",
            flexDirection: "row",
            opacity:
              index > this.state.active
                ? "0.1"
                : index < this.state.active
                ? null
                : index === this.state.active &&
                  followup &&
                  followup[index - 1] &&
                  followup[index - 1].answered === false
                ? "0.1"
                : null,
            // index !== this.state.active &&
            // (followup === null ||
            //   followup[index] === undefined ||
            //   Object.keys(followup[index]).length === 0)
          }}
        >
          {department.department === null ? null : (
            <div
              style={{
                color: "#00ffff",
                fontWeight: 700,
                marginLeft: "4px",
                fontSize: "1.2rem",
                //marginRight: "auto",
                width: "30%",
              }}
            >
              {department.department}
              {department.definition ? (
                <React.Fragment key={department.definition}>
                  <Icon
                    style={{
                      paddingLeft: "5px",
                      color: "white",
                      cursor: "pointer",
                      background: "none",
                    }}
                    name="info circle"
                    size="small"
                    color="white"
                    data-tip={department.definition}
                    data-place="right"
                    data-type="light"
                    data-effect="solid"
                  />
                  {<ReactTooltip />}
                </React.Fragment>
              ) : null}
            </div>
          )}

          {((followup !== null && this.props.lastStep === true) ||
            (this.props.lastStepLastOption === true &&
              followup !== null &&
              this.state.active === followup.length)) &&
          department.editable &&
          department.editable === true &&
          this.state.edit === false &&
          followup[index] &&
          (followup[index].answered === undefined ||
            followup[index].answered === false) ? (
            <Icon
              style={{
                cursor: "pointer",
                color: "#00ffff",
                background: "none",
              }}
              onMouseDown={() =>
                this.handleEditButton(department.department_id)
              }
              size="large"
              name="pencil"
            />
          ) : null}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              width: "150%",
            }}
          >
            {answers === null
              ? this.showSubjectiveQuestions(department)
              : answers.map((ans) => {
                  let isSelected = this.state.isSelected[
                    department.department_id
                  ]
                    ? this.state.isSelected[department.department_id]
                    : false;
                  isSelected = isSelected[ans.id]
                    ? isSelected[ans.id]
                    : !!(
                        department.answer_id && department.answer_id === ans.id
                      );
                  // console.log(
                  //     ans.id,
                  //     isSelected[ans.id],
                  //     department.answer_id,
                  //     isSelected[department.answer_id],
                  //   );
                  // console.log(this.state.active);
                  // console.log(this.state.disabled);
                  this.state.disabled[department.department_id] = false;
                  const disableArray = Object.keys(this.state.disabled);
                  // console.log(index, followup[index - 1]);
                  if (this.state.active === 0) {
                    this.state.disabled[disableArray[this.state.active]] = true;
                  } else {
                    followup[index - 1] === undefined
                      ? null
                      : Object.keys(followup[index - 1]).length === 0
                      ? (this.state.disabled[
                          disableArray[this.state.active]
                        ] = true)
                      : followup[index - 1].answered === false
                      ? (this.state.disabled[
                          disableArray[this.state.active]
                        ] = false)
                      : (this.state.disabled[
                          disableArray[this.state.active]
                        ] = true);
                  }
                  return (
                    <DepartmentAnswerStyle
                      key={ans.answer}
                      onClick={() =>
                        this.departmentSelectObjective(
                          ans.id,
                          department.department_id,
                          this.state.edit
                        )
                      }
                      optionColor={
                        isSelected
                          ? this.props.theme.optionSelected
                          : this.props.theme.optionNotSelected
                      }
                      isSelected={this.state.isSelected}
                      theme={this.props.theme}
                    >
                      {ans.answer === emoji.emojify(":cry:") ? (
                        <img
                          style={{ width: 30, height: 30 }}
                          src={labelImages.cry}
                        />
                      ) : ans.answer ===
                        emoji.emojify(":slightly_frowning_face:") ? (
                        <img
                          style={{ width: 30, height: 30 }}
                          src={labelImages.slightly_frowning_face}
                        />
                      ) : ans.answer === emoji.emojify(":neutral_face:") ? (
                        <img
                          style={{ width: 30, height: 30 }}
                          src={labelImages.neutral_face}
                        />
                      ) : ans.answer ===
                        emoji.emojify(":slightly_smiling_face:") ? (
                        <img
                          style={{ width: 30, height: 30 }}
                          src={labelImages.slightly_smiling_face}
                        />
                      ) : ans.answer === emoji.emojify(":smiley:") ? (
                        <img
                          style={{ width: 30, height: 30 }}
                          src={labelImages.smiley}
                        />
                      ) : ans.answer === "Happy.png" ? (
                        <img
                          style={{ width: 120, height: 90 }}
                          src={labelImages.Happy}
                        />
                      ) : ans.answer === "Very Happy.png" ? (
                        <img
                          style={{ width: 120, height: 90 }}
                          src={labelImages.Very_Happy}
                        />
                      ) : ans.answer === "Very Angry.png" ? (
                        <img
                          style={{ width: 120, height: 90 }}
                          src={labelImages.Very_Angry}
                        />
                      ) : ans.answer === "Angry.png" ? (
                        <img
                          style={{ width: 120, height: 90 }}
                          src={labelImages.Angry}
                        />
                      ) : ans.answer === "Neutral.png" ? (
                        <img
                          style={{ width: 120, height: 90 }}
                          src={labelImages.Neutral}
                        />
                      ) : (
                        ans.answer
                      )}
                    </DepartmentAnswerStyle>
                  );
                })}
          </div>
        </div>

        {followup === null ||
        followup[index] === undefined ||
        Object.keys(followup[index]).length === 0 ? null : (
          <div
            style={{
              // border: '1px solid #00ffff',
              borderLeft: `2px solid ${this.props.theme.inputBgColor}`,
              // borderBottom: '2px solid #00ffff',
              // boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 8px',
              // borderBottomLeftRadius: '8px',
              display: "block",
              margin:
                window.innerWidth > 500
                  ? "0 5rem 1rem 5rem"
                  : "0 2rem 1rem 2rem",
              padding: "5px 5px 5px 5px",
            }}
          >
            <FollowupLabel theme={this.props.theme}>
              {followup[index].message}
            </FollowupLabel>
            <FollowupInput
              theme={this.props.theme}
              dept={department.department_id}
              handleSubmit={this.departmentSelectSubjective}
              handleEdit={this.departmentSelectSubjectiveEdit}
              qId={followup[index].id}
              disabled={!followup[index].answered}
              answer={followup[index].answer}
              handleEditFollowup={this.handleEditFollowup}
              edit={
                (this.props.lastStep === true ||
                  (this.props.lastStepLastOption === true &&
                    this.state.active === followup.length)) &&
                department.editable &&
                department.editable === true &&
                this.state.edit === false &&
                followup[index] &&
                (followup[index].answered === undefined ||
                  followup[index].answered === true)
              }
            />
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    const departments = this.props.step.department;
    return _.map(departments, this.renderDepartments);
  }
}

export default Departments;
