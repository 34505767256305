import React from "react";
import Header from "./components/Header/header";
import { Link, Redirect } from "react-router-dom";
import { themeSelector } from "../static/theme";
import convertToQuestion from "./service/questionConvertor";
import Cookies from "universal-cookie";

import { Button } from "semantic-ui-react";
const cookies = new Cookies();
const surveyID = cookies.get("survey_id");
const isUPSSurvey = () => (process.env.NODE_ENV == 'production' && surveyId == 29) ||
(process.env.NODE_ENV == 'development' && surveyId == 326);
const axios = require("axios");
const axiosConfig = require("../components/AxiosConfig/AxiosConfig");
const cid =
  window.location.href.includes("hrlogica") == true
    ? Number(sessionStorage.getItem("cid"))
    : Number(cookies.get("cid"));
const companies =
  (process.env.NODE_ENV == "production" && cid == 16) ||
  (process.env.NODE_ENV == "development" && cid == 13) ||
  (process.env.NODE_ENV == "development" && cid == 64) ||
  (process.env.NODE_ENV == "production" && cid == 38);
export default class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noSurvey: false,
      surveyId: null,
      endScreen: false,
      renderSteps: [],
      theme: themeSelector(parseInt(cookies.get("cid")), process.env.NODE_ENV),
    };
    this.updateUser = this.updateUser.bind(this);
    this.redirectToEndScreen = this.redirectToEndScreen.bind(this);
  }

  async componentDidMount() {
    const survey_id = await cookies.get("survey_id");
    axiosConfig.headers["x-auth"] =
      window.location.href.includes("hrlogica") == true
        ? await sessionStorage.getItem("x-auth")
        : await cookies.get("x-auth");
    let res = await axios.get(
      `/surveys/active?survey_id=${survey_id}`,
      axiosConfig
    );
    res = res.data;
    if (res.data.surveys.length === 0) {
      this.setState({
        noSurvey: true,
      });
    } else {
      const surveyId = res.data.surveys[res.data.surveys.length - 1].id;
      const answered_questions =
        res.data.surveys[res.data.surveys.length - 1].answered_questions;
      let resSurvey = await axios.get(
        `/surveys/${surveyId}?question=true`,
        axiosConfig
      );
      resSurvey = resSurvey.data;
      if (resSurvey.data.end_survey) {
        this.redirectToEndScreen();
      }
      const renderSteps = convertToQuestion(resSurvey.data);
      renderSteps[0].message = this.updateUser(renderSteps[0].message);
      this.setState({
        renderSteps,
        surveyId,
      });
    }
  }

  redirectToEndScreen() {
    this.setState({
      endScreen: true,
    });
  }

  updateUser(message) {
    return (
      message != null &&
      message.replace(
        "<Employee Name>",
        window.location.href.includes("hrlogica") == true
          ? sessionStorage.getItem("user").name.split(" ")[0]
          : cookies.get("user").name.split(" ")[0]
      )
    );
  }

  welcomeMesage = () => {
    return (
      <div
        style={{
          display: "flex",
          color: "#000",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h1>Employee Survey 2019</h1>
        </div>

        <br />
        <br />
        <p
          style={{
            fontSize: "1.4em",
            textAlign: "justify",
            fontWeight: "bold",
          }}
        >
          The objective of Employee Survey 2019 is to help us know your feedback
          on different aspects of work life at InCred. It gives us insights that
          can work towards improving productivity and engagement at work.
          <br />
          <br />
          It is an opportunity to reach out to every employee and for every
          employee to be heard. The survey guarantees 100% anonymity and
          confidentiality of individuals sharing their feedback and each
          response is masked to maintain the same. <br />
          <br />
          <strong>To remember</strong>:{" "}
          <ul>
            <li>Be completely candid in your feedback.</li>
            <li>Do not share any names / details in open ended questions.</li>
          </ul>
          We will share the survey results with you at the earliest with action
          points that we derive from your feedback. Cheers!!
        </p>
        <br />

        <p
          style={{
            fontWeight: "bolder",
            fontSize: "1.6em",
            textAlign: "justify",
          }}
        >
          Points to remember before you begin:
        </p>

        <ul
          style={{
            fontSize: "1.4em",
            textAlign: "justify",
            fontWeight: "bold",
          }}
        >
          <li>There are 21 questions in the survey.</li>
          <li>All questions are mandatory to respond to.</li>
          <li>
            Read the question and rating scale carefully before responding.
          </li>
          <li>
            You can move on to the next question as soon as you respond to the
            previous one.
          </li>
          <li>
            You may edit your previous response by clicking on edit button below
            the question.
          </li>
          <li>
            In case you aren’t able to complete the survey in one go, you may
            use the same link and the survey will resume from where you last
            left.
          </li>
          <li>
            <strong>Confidentiality: </strong> We assure you that we will keep
            your data and your responses highly confidential. Make sure you
            respond in a frank and honest manner.
          </li>
        </ul>
      </div>
    );
  };

  render() {
    const { renderSteps } = this.state;
    const welcomeStep = renderSteps[0];
    const backImageStyle = {
      backgroundImage:
        (process.env.NODE_ENV == "production" && cookies.get("cid") == 32) ||
        (process.env.NODE_ENV == "development" && cookies.get("cid") == 55)
          ? `url('https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/Picture2.png')`
          : `url('https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/cine_bck.png')`,
      backgroundColor: this.state.theme.formBG,
      width: "100vW",
      minHeight: "100vH",
      padding:
        window.innerWidth > 768
          ? "10vh 20rem"
          : window.innerWidth > 500
          ? "10vh 10rem"
          : "10vh 2rem",
    };
    const simpleBackStyle = {
      backgroundColor: this.state.theme.formBG,
      width: "100vW",
      minHeight: "100vH",
      marginTop:"30px",
      padding:
        window.innerWidth > 768
          ? "10vh 20rem"
          : window.innerWidth > 500
          ? "10vh 10rem"
          : "10vh 2rem",
    };
    return this.state.noSurvey === true ? (
      <Redirect to="/no_survey" />
    ) : this.state.endScreen === true ? (
      <Redirect to="/end_survey" />
    ) : (
      <React.Fragment>
        <Header headTheme={this.state.theme} />
        
        <div style={simpleBackStyle}>

        {(process.env.NODE_ENV == "production" && surveyID == 29) ||
        (process.env.NODE_ENV == "development" && surveyID == 326) ?
        <div style={{ maxWidth: '100%', height: 'auto' }}>
        <img
          src="https://i.imgur.com/Bz9xaNd.jpg"
          alt="ups-image"
          style={{ width: '100%', maxHeight: '700px', height: 'auto', borderRadius:"8px" }}
        />
      </div>
      : null }
     
          {(cookies.get("cid") == 55 &&
            process.env.NODE_ENV == "development") ||
          (cookies.get("cid") == 32 && process.env.NODE_ENV == "production")
            ? this.welcomeMesage()
            : welcomeStep
            ? welcomeStep.message.split("\n").map((item, i) => (
                <p
                  style={{
                    color: this.state.theme.questionTextColor,
                    fontWeight: "700",
                    fontSize: "1.5rem",
                    marginBottom: "3rem",
                    marginTop: "2rem",
                    justifyContent: "center",
                    textAlign: "left",  
                       
                  }}
                  key={i}
                >
                  {item}
                </p>
              ))
            : null}
           
          <div style={{ textAlign: "center", marginTop: "20px", width:"100%", marginBottom: "20px" }}>
        
         
            <Link
              style={{
                background: `${this.state.theme.btnBg}`,
                
                  color: isUPSSurvey ? "#fff" : "inherit",
                borderRadius: "5px",
                padding: "1rem",
              width:"200px"
              
                
              }}
              to="/form"
            >
              Start Survey
            </Link> 
            {(process.env.NODE_ENV == "production" && surveyID == 29) ||
            (process.env.NODE_ENV == "development" &&  surveyID == 326) ?
            <div style={{ maxWidth: '100%', height: 'auto',  marginTop: "20px", borderRadius:"8px"  }}>
            <img
              src="https://i.imgur.com/tBvzRIt.png"
              alt="ups-image"
              style={{ width: '100%', maxHeight: '700px', height: 'auto', borderRadius:"8px" }}
            />
          </div> : null }

          </div>
        </div>
      </React.Fragment>
    );
  }
}
