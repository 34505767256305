import styled from 'styled-components';
import { invalidInput } from './common/animations';
import Cookies from "universal-cookie";
const cookies = new Cookies();
const ITC = cookies.get("cid") == 218 && process.env.NODE_ENV === "development"
const TextAreaInput = styled.textarea`
  animation: ${props => (props.invalid ? `${invalidInput} .2s ease` : '')};
  border: 1px solid black;
  border-radius: 5px;
  border-top: ${props => (props.invalid ? '0' : '1px solid')};
  box-shadow: ${props => (props.invalid ? 'inset 0 0 2px #E53935' : 'none')};
  box-sizing: border-box;
  color: ${props => (props.invalid ? '#E53935' : '')};
  font-size: 14px;
  opacity: ${props => (props.disabled && !props.invalid ? '.5' : '1')};
  outline: none;
  padding: ${props => (props.hasButton ? '14px 52px 14px 10px' : '16px 10px')};
  width: 60%;
  white-wrap:no-wrap;
  margin-left:148px;
  overflow-y:none;
  -webkit-appearance: none;
  &:disabled { background: #fff; }
  

  @media screen and (min-width:993px)
  {
    width: 54%;
    overflow-y:none;
  }

  @media screen and (min-width:1100px)
  {
    width: 58%;
    overflow-y:none;
  }

  @media screen and (min-width:1250px)
  {
    width: 62%;
    overflow-y:none;
  }

  @media screen and (max-width:910px)
  {
    width: 55%;
    overflow-y:none;
  }

  @media screen and (max-width:740px)
  {
    width: 52%;
    overflow-y:none;
  }

  @media screen and (max-width:650px)
  {
    overflow-y:none;
    width: 42%;
  }

  @media screen and (max-width:450px)
  {
    width: 66%;
    margin-left:10%;
    overflow-y:none;
    font-size:12px;
  }

  @media screen and (max-width:374px)
  {
    width: 42%;
    overflow-y:none;
    font-size: 12px;
  }

  @media screen and (max-width:374px)
  {
    width: 63%;
    overflow-y:none;
    font-size: 12px;
  }
  
  @media screen and (min-width:1400px){
    width: 65%;
    font-size: 18px;
    overflow-y:none;
  }

  @media screen and (min-width:1750px){
    width: 74%;
    font-size: 18px;
    overflow-y:none;
  }

  @media screen and (min-width:1951px){
    width: 79%;
    font-size: 18px;
    overflow-y:none;
  }

  @media screen and (min-width:2450px){
    width: 80%;
    font-size: 18px;
    overflow-y:none;
  }

`;

export default TextAreaInput;
