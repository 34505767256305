import Cookies from "universal-cookie";
const cookies = new Cookies();
const axios = require("axios");
const axiosConfig = require("../../components/AxiosConfig/AxiosConfig");

const getExitMessageBySurveyId = async (surveyId) => {
  axiosConfig.headers["x-auth"] =
    window.location.href.includes("hrlogica") == true
      ? await sessionStorage.getItem("x-auth")
      : await cookies.get("x-auth");
  let result;
  if (surveyId != -1) {
    result = await axios.get(`/surveys/${surveyId}`, axiosConfig);
  }
  try {
    return emoji.emojify(result.data.data.survey.exit);
  } catch (e) {
    return emoji.emojify(
      "Thank you for taking time to have a conversation with me, your feedback has been taken into account and management will soon act upon improvements."
    );
  }
};

import emoji from "node-emoji";

export let endMessage = async (data, env, cid, token, surveyId) => {
  let endSurvey = {};

  endSurvey.id = "endSurvey";
  if (env === "staging") {
    let byeMsg = {};
    let byeMsgEmoji;
    let link1 = {};
    let link2 = {};
    let link3 = {};
    link1.id = "link1";
    link2.id = "link2";
    link3.id = "link3";
    let link1_link = `http://stage.hrlogica.com/surveys/form?survey_id=${surveyId}&token=${token}&type=1`;
    let link2_link = `http://stage.hrlogica.com/surveys/form?survey_id=${surveyId}&token=${token}&type=2`;
    let link3_link = `http://stage.hrlogica.com/surveys/form?survey_id=${surveyId}&token=${token}&type=3`;
    link1.trigger = "link2";
    link2.trigger = "link3";
    link3.trigger = "endSurvey";
    // console.log(cid)
    switch (cid) {
      case "3":
        endSurvey.message =
          "Thank you for taking time to have a conversation with me. Your feedback has been taken into account, appropriate actions shall be taken to ensure resolution and maintain confidentiality.";
        endSurvey.trigger = "bye_survey";
        byeMsg.id = "bye_survey";
        byeMsg.message = emoji.emojify("See you. Bye! :smiley:");
        byeMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
        break;
      case "9":
        endSurvey.message = "We would be glad to serve you again";
        endSurvey.trigger = "bye_survey";

        byeMsg.id = "bye_survey";
        byeMsg.message = emoji.emojify("See you. Bye! :smiley:");
        byeMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
        break;
      case "10":
        endSurvey.message =
          "Thank you for having this conversation this me. Organization willl certainly act to improve work culture.";
        endSurvey.trigger = "bye_survey";
        byeMsg.id = "bye_survey";
        byeMsg.message = emoji.emojify("See you. Bye! :smiley:");
        byeMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
        break;
      case "11":
        link1.message = "BGV Form 1(Verfication Form )";
        link2.message = "Non-Disclosure Agreement (NDA)";
        link3.message = "Quess Code of Conduct";
        endSurvey.message = emoji.emojify("Thanks! :slightly_smiling_face:");
        endSurvey.trigger = "bye_survey";

        byeMsg.id = "bye_survey";
        byeMsg.message = emoji.emojify("See you. Bye! :smiley:");
        byeMsg.end = true;
        link1.message = link1.message.link(link1_link);
        link2.message = link2.message.link(link2_link);
        link3.message = link3.message.link(link3_link);
        data.push(link1);
        data.push(link2);
        data.push(link3);
        data.push(endSurvey);
        data.push(byeMsg);
        break;
      case "12":
        endSurvey.message = emoji.emojify(
          "Thank you for giving us feedback. We value the work you have done with us and your feedback will help us improve as an organisation. We wish you all the best in your future endeavours. :smiley:"
        );
        endSurvey.trigger = "bye_survey";
        byeMsg.id = "bye_survey";
        byeMsg.message = emoji.emojify("See you. Bye! :smiley:");
        byeMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
        break;

      case "14":
        endSurvey.message =
          "Thanks for sharing with me your experience. It will help us improve our processes.";
        endSurvey.trigger = "bye_survey";

        byeMsg.message = emoji.emojify(
          "I wish you all the best for your future endeavors. :smiley:"
        );
        byeMsg.id = "bye_survey";
        byeMsg.message = emoji.emojify("See you. Bye! :smiley:");
        byeMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
        break;
      case "16":
        endSurvey.message =
          "We look forward to serving you again. :slightly_smiling_face:";
        endSurvey.trigger = "bye_survey";

        byeMsg.id = "bye_survey";
        byeMsg.message = emoji.emojify("See you. Bye! :smiley:");
        byeMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
        break;
      case "18":
        endSurvey.message =
          "Absolutely. We are happy you are considering attending this event. We shall contact you in due course to check for your decision. \
          Am sure you would decide in favour of attending. Thank you so much for your time. \
          You’ve been nice and it has been a pleasure chatting with you.\
          Wish you the best! And thank you once again for your time.";
        endSurvey.trigger = "bye_survey";

        byeMsg.id = "bye_survey";
        byeMsg.message = emoji.emojify("See you. Bye! :smiley:");
        byeMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
        break;
      case "20":
        endSurvey.message =
          "Thank you for taking time to have a conversation with me.";
        endSurvey.trigger = "bye_survey";

        byeMsg.id = "bye_survey";
        byeMsg.message = emoji.emojify("See you. Bye! :smiley:");
        byeMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
        break;
      case "21":
        endSurvey.message =
          "Thank you for taking out time to have a conversation with me. It was a pleasure!";
        endSurvey.trigger = "bye_survey";

        byeMsg.id = "bye_survey";
        byeMsg.message = emoji.emojify("See you. Bye! :smiley:");
        byeMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
        break;
      default:
        endSurvey.message =
          "Thank you for taking time to have a conversation with me, your feedback has been taken into account and management will soon act upon improving the work culture.";
        endSurvey.trigger = "bye_survey";

        byeMsg.id = "bye_survey";
        byeMsg.message = emoji.emojify("See you. Bye! :smiley:");
        byeMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
    }
  } else if (env === "development") {
    let byeMsg = {};
    let byeMsgEmoji;
    let exitMsg = {};
    let exit2Msg = {};
    let exitMsgEmoji;
    switch (cid) {
      // case "1":
      //   endSurvey.message = emoji.emojify(
      //     "Thanks for sharing your feedback and suggestions, Management will do its best to improve work culture. :smiley:"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("See you. Bye! :smiley:");

      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "10":
      //   endSurvey.message = emoji.emojify("See you. Bye! :smiley:");
      //   endSurvey.end = true;
      //   data.push(endSurvey);
      //   break;
      // case "11":
      //   let score = {};
      //   score.id = "score";
      //   score.message =
      //     'Thank you for taking time to submit your responses. As per your risk profile assessment, you come under "[[PROFILE]]" category. ';
      //   score.trigger = "report";
      //   let report = {};
      //   report.id = "report";
      //   report.message = "Dummy Data";
      //   report.trigger = "endSurvey";
      //   endSurvey.message =
      //     "Thank you for taking time to have a conversation with me.";
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("See you. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(score);
      //   data.push(report);
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "12":
      //   endSurvey.message =
      //     "Thank you for taking time to have a conversation with me, your feedback has been taken into account and management will soon act upon improving the candidate experience.";
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("See you. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "31":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you so much for your time. \
      //    You’ve been nice and it has been a pleasure chatting with you.\
      //    Wish you the best! :smiley:"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("See you. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "33":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking time to have a conversation with me.Your feedback has been taken into account"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "34":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking time to have a conversation with me."
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "39":
      //   endSurvey.message = emoji.emojify(
      //     "Thanks for your feedback. :smiley: \
      //       Your feedback has been taken into account."
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "125":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "54":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "61":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "68":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "57":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure talking to you!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "117":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure talking to you!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "154":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure talking to you!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "217":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure talking to you!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "218":
      //   endSurvey.message = emoji.emojify(
      //     "As a token of appreciation, you will receive a gift voucher on your registered mobile number shortly. Hope you had a good time! 😁"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      case "2":
        endSurvey.message = emoji.emojify(
          "We value the work you have done with us and your feedback will help us improve as an organisation."
        );
        byeMsg.id = "bye_survey";
        endSurvey.trigger = "bye_survey";
        byeMsgEmoji = emoji.emojify(
          "We have come to the end of the survey. We wish you all the best in your future endeavours. :smiley:"
        );
        byeMsg.message = byeMsgEmoji;
        byeMsg.trigger = "exit_tab";
        exitMsg.id = "exit_tab";
        exitMsgEmoji = emoji.emojify("To exit, you can close the tab.");

        exitMsg.message = exitMsgEmoji;
        exitMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
        data.push(exitMsg);
        break;
      case "12":
        endSurvey.message = await getExitMessageBySurveyId(surveyId);
        endSurvey.trigger = "bye_survey";
        byeMsgEmoji = emoji.emojify("See you. Bye! :smiley:");
        byeMsg = {};
        byeMsg.id = "bye_survey";
        byeMsg.message = byeMsgEmoji;
        byeMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
        break;
      case "14":
        endSurvey.message = emoji.emojify(
          "We would love to get in touch with you to refine this exciting finding. Please click on the calendly link below to schedule a call with one of our representatives."
        );
        endSurvey.trigger = "bye_survey";
        byeMsgEmoji = "Calendly Invite";
        byeMsg = {};
        byeMsg.id = "bye_survey";
        byeMsg.message = byeMsgEmoji;
        byeMsg.trigger = "end__survey";
        let endMsg = {};
        endMsg.id = "end__survey";
        endMsg.message = "Thank you";
        endMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
        data.push(endMsg);
        break;
      // endSurvey.message = emoji.emojify(
      //   "Based on your inputs,  we envision the potential for 60 to 70% automation, saving you a potential of 20 to 40,000 USD a year in FTEs."
      // );
      // endSurvey.trigger = "bye_survey";
      // byeMsgEmoji = emoji.emojify(
      //   "We would love to get in touch with you to refine this exciting finding. Please click on the calendly link below to schedule a call with one of our representatives:"
      // );
      // byeMsg.trigger = "exit_tab";
      // exitMsgEmoji = emoji.emojify("To exit, you can close the tab.");

      // byeMsg.id = "bye_survey";
      // byeMsg.message = byeMsgEmoji;
      // exitMsg = {};
      // exitMsg.id = "exit_tab";
      // exitMsg.message = exitMsgEmoji;
      // exitMsg.end = true;
      // data.push(endSurvey);
      // data.push(byeMsg);
      // data.push(exitMsg);
      // break;
      // case "6":
      //   endSurvey.message = emoji.emojify(
      //     "There is all of this and more, which I am sure you will experience in days to come."
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify(
      //     "We have come to the end of the survey. Look forward to staying connected as you begin your InCredible journey with us! To exit, you can close the tab."
      //   );
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "222":
      //   endSurvey.message = emoji.emojify(
      //     "We value the work you have done with us and your feedback will help us improve as an organisation. It was a pleasure talking to you!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("See you soon. Takecare! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      case "3":
      case "4":
        let exitMsg;
        // endSurvey.message = emoji.emojify(
        //   "You are a valuable asset to Little Nap and your feedback will help us improve as an organisation. It was a pleasure talking to you!"
        // );
        endSurvey.message = await getExitMessageBySurveyId(surveyId);
        endSurvey.trigger = "exit_tab";
        exitMsgEmoji = "<LittleNapLastMessage>";
        // exit2MsgEmoji = `https://i.imgur.com/iouPODT.png`;
        exitMsg = {};
        exitMsg.id = "exit_tab";
        exitMsg.message = exitMsgEmoji;
        exitMsg.trigger = "bye_survey";

        byeMsg = {};
        byeMsg.id = "bye_survey";
        byeMsg.message = "<LittleNap>";
        byeMsg.trigger = "exit2_tab";
        let exit2Msg = {};
        exit2Msg.id = "exit2_tab";
        exit2Msg.message = "Thank you for taking the survey.";
        exit2Msg.trigger = "exitBanner_tab";
        let exitBanner = {};
        exitBanner.id = "exitBanner_tab";
        exitBanner.message = "<LittleNapEndBanner>";
        exitBanner.end = true;
        data.push(endSurvey);
        data.push(exitMsg);
        data.push(byeMsg);
        data.push(exit2Msg);
        data.push(exitBanner);
        break;
      case "5":
        endSurvey.message = emoji.emojify("It was a pleasure talking to you!");
        endSurvey.trigger = "bye_survey";
        byeMsgEmoji = emoji.emojify("See you soon. Takecare! :smiley:");
        byeMsg = {};
        byeMsg.id = "bye_survey";
        byeMsg.message = byeMsgEmoji;
        byeMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
        break;
      default:
        endSurvey.message = await getExitMessageBySurveyId(surveyId);
        endSurvey.trigger = "bye_survey";
        byeMsgEmoji = emoji.emojify("See you. Bye! :smiley:");
        byeMsg = {};
        byeMsg.id = "bye_survey";
        byeMsg.message = byeMsgEmoji;
        byeMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
    }
  } else if (env === "production") {
    let endSurvey = {};
    endSurvey.id = "endSurvey";
    let byeMsg = {};
    let byeMsgEmoji;
    let exitMsg = {};
    let exitMsgEmoji;
    switch (cid) {
      // case "20":
      //   endSurvey.message = emoji.emojify(
      //     "Thanks for sharing your feedback and suggestions, Management will do its best to improve work culture. :smiley:"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("See you. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "13":
      //   endSurvey.message = emoji.emojify("See you. Bye! :smiley:");
      //   endSurvey.end = true;
      //   data.push(endSurvey);
      //   break;
      // case "14":
      //   score = {};
      //   score.id = "score";
      //   score.message =
      //     'Thank you for taking time to submit your responses. As per your risk profile assessment, you come under "[[PROFILE]]" category. ';
      //   score.trigger = "report";
      //   report = {};
      //   report.id = "report";
      //   report.message = "Dummy Data";
      //   report.trigger = "endSurvey";
      //   endSurvey.message =
      //     "Thank you for taking time to have a conversation with me.";
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("See you. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(score);
      //   data.push(report);
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "15":
      //   endSurvey.message =
      //     "Thank you for taking time to have a conversation with me, your feedback has been taken into account and management will soon act upon improving the candidate experience.";
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("See you. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "25":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking time to have a conversation with me.Your feedback has been taken into account"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "27":
      //   endSurvey.message = emoji.emojify(
      //     "Thanks for your feedback. :smiley: \
      //     Your feedback has been taken into account."
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "28":
      //   endSurvey.message = emoji.emojify(
      //     "Mulțumesc că ai avut această conversație cu mine. \
      //     Organizația va acționa cu siguranță pentru a îmbunătăți cultura muncii."
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Pe curand, la revedere!");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "29":
      //   endSurvey.message = emoji.emojify(
      //     "Благодаря ти, че проведе този разговор. Организацията със сигурност ще действа за подобряване на работната култура."
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Ще се видим Чао!");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "30":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "25":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking time to have a conversation with me."
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "26":
      //   endSurvey.message = emoji.emojify(
      //     "Thanks for your feedback. :smiley: \
      //     Your feedback has been taken into account."
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "31":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "33":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure talking to you!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "34":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure talking to you!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "35":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "36":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "37":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure talking to you!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "39":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure talking to you!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      case "2":
        endSurvey.message = emoji.emojify(
          "We value the work you have done with us and your feedback will help us improve as an organisation."
        );
        endSurvey.trigger = "bye_survey";
        byeMsg.id = "bye_survey";
        byeMsgEmoji = emoji.emojify(
          "We have come to the end of the survey. We wish you all the best in your future endeavours. :smiley:"
        );
        byeMsg.message = byeMsgEmoji;
        byeMsg.trigger = "exit_tab";
        exitMsg.id = "exit_tab";
        exitMsgEmoji = emoji.emojify("To exit, you can close the tab.");
        exitMsg.message = exitMsgEmoji;

        exitMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
        data.push(exitMsg);
        break;

      // case "41":
      //   endSurvey.message = emoji.emojify(
      //     "We value the work you have done with us and your feedback will help us improve as an organisation. It was a pleasure talking to you!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("See you soon. Takecare! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "42":
      //   endSurvey.message = emoji.emojify(
      //     "Thank you for taking out time to have a conversation with me. It was a pleasure talking to you!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Have a good day. Bye! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "43":
      //   endSurvey.message = emoji.emojify(
      //     "We value the work you have done with us and your feedback will help us improve as an organisation. It was a pleasure talking to you!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("See you soon. Takecare! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "44":
      //   endSurvey.message = emoji.emojify(
      //     "We value the work you have done with us and your feedback will help us improve as an organisation. It was a pleasure talking to you!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsgEmoji = emoji.emojify("Stay safe, Take care! :smiley:");
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = byeMsgEmoji;
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      // case "45":
      //   endSurvey.message = emoji.emojify(
      //     "We value the work you have done with us and your feedback will help us improve as an organisation. It was a pleasure talking to you!"
      //   );
      //   endSurvey.trigger = "bye_survey";
      //   byeMsg = {};
      //   byeMsg.id = "bye_survey";
      //   byeMsg.message = emoji.emojify("Stay safe, Take care! :smiley:");
      //   byeMsg.end = true;
      //   data.push(endSurvey);
      //   data.push(byeMsg);
      //   break;
      case "3":
        let exitMsg;
        // endSurvey.message = emoji.emojify(
        //   "You are a valuable asset to Little Nap and your feedback will help us improve as an organisation. It was a pleasure talking to you!"
        // );
        endSurvey.message = await getExitMessageBySurveyId(surveyId);
        endSurvey.trigger = "exit_tab";
        exitMsgEmoji = "<LittleNapLastMessage>";
        // exit2MsgEmoji = `https://i.imgur.com/iouPODT.png`;
        exitMsg = {};
        exitMsg.id = "exit_tab";
        exitMsg.message = exitMsgEmoji;
        exitMsg.trigger = "bye_survey";

        byeMsg = {};
        byeMsg.id = "bye_survey";
        byeMsg.message = "<LittleNap>";
        byeMsg.trigger = "exit2_tab";
        let exit2Msg = {};
        exit2Msg.id = "exit2_tab";
        exit2Msg.message = "Thank you for taking the survey.";
        exit2Msg.trigger = "exitBanner_tab";
        let exitBanner = {};
        exitBanner.id = "exitBanner_tab";
        exitBanner.message = "<LittleNapEndBanner>";
        exitBanner.end = true;
        data.push(endSurvey);
        data.push(exitMsg);
        data.push(byeMsg);
        data.push(exit2Msg);
        data.push(exitBanner);
        break;
      case "4":
      case "6":
        // endSurvey.message = emoji.emojify(
        //   "You are a valuable asset to NRL and your feedback will help us improve as an organisation. It was a pleasure talking to you!"
        // );
        endSurvey.message = await getExitMessageBySurveyId(surveyId);
        // endSurvey.trigger = "bye_survey";
        // byeMsg = {};
        // byeMsg.id = "bye_survey";
        // // byeMsg.message = emoji.emojify("See you soon. Takecare! :smiley:");
        // byeMsg.message = emoji.emojify("See you soon. Takecare! :smiley:");
        // byeMsg.end = true;

        data.push(endSurvey);
        // data.push(byeMsg);
        break;
      default:
        // endSurvey.message =
        //   "Thank you for taking time to have a conversation with me, your feedback has been taken into account and management will soon act upon improving the work culture.";
        endSurvey.message = await getExitMessageBySurveyId(surveyId);
        endSurvey.trigger = "bye_survey";
        byeMsg = {};
        byeMsg.id = "bye_survey";
        byeMsg.message = emoji.emojify("See you. Bye! :smiley:");
        byeMsg.end = true;
        data.push(endSurvey);
        data.push(byeMsg);
    }
  }

  return data;
};
