const url = {
  Facebook:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Facebook.png",

  Instagram:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Instagram.png",
  Pinterest:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Pinterest.png",
  Twitter:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Twitter.png",
  Snapchat:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Snapchat.png",
  LinkedIn:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Linkedin.png",
  YouTube:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/YouTube.png",
  Tumblr: "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Tumblr.png",
  Britannia_5050:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Britannia_5050.png",
  Britannia_Bourbon:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Britannia_Bourbon.png",
  Oreo: "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Oreo.png",
  Parle_G:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Parle_G.jpg",
  Sunfeast_Bounce:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Sunfeast_Bounce.jpg",
  Sunfeast_Marie_Light:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Sunfeast_Marie_Light.jpg",
  Britannia_Marie_Gold:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Britannia_Marie_Gold.jpg",
  Sunfeast_Farmlite:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Sunfeast_Farmlite.jpg",
  Parle_HideAndSeek_Chocochip_Cookies:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Parle_Hide&Seek_Chocochip_Cookies.jpg",
  Sunfeast_Dark_Fantasy_Chocofills:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Sunfeast_Dark_Fantasy_Chocofills.jpg",
  Britannia_Nutrichoice:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Britannia_Nutrichoice.png",
  Britannia_GoodDay:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Britannia_GoodDay.jpg",
  Parle_HappyHappy_Cookies:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Parle_HappyHappy_Cookies.jpg",
  Sunfeast_Moms_Magic:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Sunfeast_Mom's_Magic.jpg",
  Parle_Magix_Kreams:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Parle_Magix_Kreams.jpg",
  Parle_2020:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Parle_20-20.jpg",
  Parle_Monaco:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Parle_Monaco.jpg",
  DU: "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/DU.mp4",
  PP: "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/PP.mp4",
  TB: "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/TB.mp4",
  BS: "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/BS.mp4",
  Sunfeast_Snacky:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Sunfeast_Snacky.jpg",
  Unibic_Cookies:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Unibic_Cookies.jpg",
  Sunfeast_Veda_Marie:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Sunfeast_Veda_Marie.JPG",
  Britannia_Treat_Choco_Burst:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Britannia_Treat_Choco_Burst.jpg",
  Britannia_Treat:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Britannia_Treat.jpg",
  Britannia_Pure_Magic_Chocolush:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Britannia_Pure_Magic_Chocolush.jpg",
  Sunfeast_Marie_Light_Vita:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Sunfeast_Marie_Light_Vita.jpg",
  Sunfeast_HiFi:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Sunfeast_HiFi.jpg",
  Sunfeast_Dark_Fantasy_Yumfills:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Sunfeast_Dark_Fantasy_Yumfills.jpg",
  Parle_Bakesmith_Marie:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Parle_Bakesmith_Marie.jpg",
  Mcvities_Digestive:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Mcvities_Digestive.jpg",
  Lotte_Chocopie:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Lotte_Chocopie.jpg",
  Britannia_Vita_Marie_Gold:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Britannia_Vita_Marie_Gold.jpg",
  Weight_1:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Weight+1.png",
  Weight_2:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Weight+2.png",
  Weight_3:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Weight+3.png",
  Weight_4:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Weight+4.png",
  Weight_5:
    "https://itc-user-media.s3.ap-south-1.amazonaws.com/media/Weight+5.png",
  LittleNap: "https://i.imgur.com/E28XtYs.png",
};

export const mapImage = (label) => {
  return url[label] ? url[label] : label;
};
