export default process.env.NODE_ENV === "staging"
  ? {
      background: "#1c2729",
      fontFamily: "monospace",
      headerBgColor: "#6e48aa",
      headerFontColor: "#fff",
      headerFontSize: "16px",
      botBubbleColor: "#1ec1ac",
      botFontColor: "white !important",
      userBubbleColor: "#0e5d64",
      userFontColor: "white",
    }
  : {
      background: "white",
      fontFamily: "monospace",
      headerBgColor: "#6e48aa",
      headerFontColor: "#fff",
      headerFontSize: "16px",
      botBubbleColor: "red",
      botFontColor: "black",
      userBubbleColor: "#0084ff",
      userFontColor: "white",
    };
