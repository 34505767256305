/* eslint-disable linebreak-style */
/* eslint-disable quotes */
import Cookies from "universal-cookie";
const cookies = new Cookies();
const axios = require("axios");
const axiosConfig = require("../../components/AxiosConfig/AxiosConfig");

const getWelcomeMessageBySurveyId = async (surveyId) => {
  axiosConfig.headers["x-auth"] =
    window.location.href.includes("hrlogica") == true
      ? await sessionStorage.getItem("x-auth")
      : await cookies.get("x-auth");

  let result = await axios.get(`/surveys/${surveyId}`, axiosConfig);

  try {
    return emojify(result.data.data.survey.welcome);
  } catch (e) {
    return emojify(
      "Hey there! I am Xane :woman:. I have been hired by your CEO to gather feedback."
    );
  }
};

import { emojify } from "../service/utils";

export const welcomeMessage = async (env, cid, id) => {
  const temMs = emojify(
    "Okay! Just press the button below whenever you would like to begin. Thanks :smiley: "
  );
  const hrlogicaMsg = emojify(
    "Hey there! I am HR Logica's Assistant :woman:. I would like to know about your interview experience to help improve it. Can we have a quick chat ?"
  );
  const xaneMsg = emojify(
    "Hey there! I am Xane :woman: We are rolling out the Employee Voice- Marketing Survey which will help us know how to further strengthen our marketing strategies. "
  );
  const introMsg = await getWelcomeMessageBySurveyId(cid);
  // const introMsg = emojify(
  //   "Hey there! I am Xane :woman:. We are rolling out the Employee Voice- Marketing Survey which will help us know how to further strengthen our marketing strategies."
  // );
  const nablerMsg = emojify(
    "Hey there! I am Xane :woman:. I have been hired by your CEO to gather your feedback about the workplace."
  );
  const nablernewMsg = emojify(
    "Hey there! I am Xane :woman:. Thanks for participating in the recent CSR Drive at Nabler. We would like to know your valuable feedback on the same."
  );
  const beYouPlusMsg = emojify("Hello! Hope you're doing good.");
  const blueQuadMsg = emojify(
    "Hey there! I am Xane :woman:. I have been hired by your CEO to gather your feedback about the workplace."
  );
  const gsAdvisorsMsg =
    "I am the virtual personal assistant of GrowthSource. Thank you for choosing to chat with me on HR technology. I seek your inputs for an important survey to gauge the HR adaptation of Technology across Industries. It will not take you more than 3-4 minutes of your time and you will get a copy of the results and detailed analytics in appreciation of your response.";
  let defMsg;

  if (env == "development") {
    switch (cid) {
      case "317":
      case "318":
      case "319":
      case "320":
      case "321":
        defMsg = [
          {
            id: "intro_1",
            message: introMsg,
            trigger: "intro_2",
            animation: 1000,
          },
          {
            id: "intro_2",
            message: "<IncredBulletPoints>",
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message: "Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "314":
        defMsg = [
          {
            id: "intro_1",
            message: "<speakUpLittleNap>",
            trigger: "intro_1.4",
            animation: 1000,
          },
          {
            id: "intro_1.4",
            message: "<littleNapPoster>",
            trigger: "intro_1.41",
            animation: 1000,
          },
          {
            id: "intro_1.41",
            message: introMsg,
            trigger: "intro_1.5",
            animation: 1000,
          },
          {
            id: "intro_1.5",
            message:
              "Speak up survey is being managed by an independent survey company. This outside company will host the survey on the Internet and collect your responses online. All responses will remain strictly anonymous. We will not be able to trace individual responses back to you.  Please be thoughtful, honest, and candid when you complete the survey.",
            trigger: "intro_2",
            animation: 1000,
          },
          {
            id: "intro_2",
            message:
              "We would like to get 100% participation from all Lttlenapience   in order to ensure that each and every employee's voice is heard. So, please go ahead, let your fingers do the talking.",
            trigger: "intro_3",
            animation: 1000,
          },
          {
            id: "intro_3",
            message: "<list>",
            trigger: "intro_4",
            animation: 1000,
          },
          {
            id: "intro_4",
            message: "<RedMessage>",
            trigger: "intro_5",
            animation: 1000,
          },
          {
            id: "intro_5",
            message: "WE ARE ONE TEAM ONE DREAM",
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message:
              "Everything you share is kept confidential and will never be shared with your manager. Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "327":
        defMsg = [
          {
            id: "intro_1",
            message: "<speakUpLittleNap2024>",
            trigger: "intro_1.4",
            animation: 1000,
          },
          {
            id: "intro_1.4",
            message: "<littleNapPoster2024>",
            trigger: "intro_1.41",
            animation: 1000,
          },
          {
            id: "intro_1.41",
            message: introMsg,
            trigger: "intro_1.5",
            animation: 1000,
          },
          {
            id: "intro_1.5",
            message:
              "Speak up survey is being managed by an independent survey company. This outside company will host the survey on the Internet and collect your responses online. All responses will remain strictly anonymous. We will not be able to trace individual responses back to you.  Please be thoughtful, honest, and candid when you complete the survey.",
            trigger: "intro_2",
            animation: 1000,
          },
          {
            id: "intro_2",
            message:
              "We would like to get 100% participation from all Lttlenapience in order to ensure that each and every employee's voice is heard. So, please go ahead, let your fingers do the talking.",
            trigger: "intro_3",
            animation: 1000,
          },
          {
            id: "intro_3",
            message: "<list>",
            trigger: "intro_4",
            animation: 1000,
          },
          {
            id: "intro_4",
            message: "<RedMessage>",
            trigger: "intro_5",
            animation: 1000,
          },
          {
            id: "intro_5",
            message: "WE ARE ONE TEAM ONE DREAM",
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message: "Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;

      case "2":
        defMsg = [
          {
            id: "intro_1",
            message:
              "Hi <Name>,\
                Thank you for choosing to share your feedback. I am InCredBot and would like to chat with you for a few minutes to understand your experience at InCred.",
            trigger: "comp_msg_1",
            animation: 1000,
          },
          {
            id: "comp_msg_1",
            message:
              "I assure you that your responses will be shared only with the HR leadership, with the aim of continuously improving on the work ecosystem at InCred.",
            trigger: "comp_msg_2",
            animation: 1000,
          },
          {
            id: "comp_msg_2",
            message: "Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Not Now",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: emojify(
              "I wish you’d stay and chat. Click the button if you change your mind. :blush:\
                Else, you can close the tab and come back later at your convenience.	"
            ),
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "3":
      case "11":
        defMsg = [
          {
            id: "intro_1",
            message: emojify(
              "Hey there! I am Xane :woman:. We are rolling out a Quick Satisfaction Survey which will help us to improve as an organisation."
            ),
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message:
              "Everything you share is kept confidential so feel free to give candid feedback. Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "4":
        defMsg = [
          {
            id: "intro_1",
            message: introMsg,
            trigger: "intro_1.5",
            animation: 1000,
          },
          {
            id: "intro_1.5",
            message:
              "This Employee Speak-up survey help in measuring, evaluating, and analyzing concerns that improve productivity and engagement and allow the company to get a better idea of how employees feel about their work life. This is the opportunity for employees to feel heard.",
            trigger: "intro_2",
            animation: 1000,
          },
          {
            id: "intro_2",
            message:
              " Confidentiality:  Little Nap assures you that we will keep all your data of this survey highly confidential. Please be as honest as you can while fulfilling this survey form.",
            trigger: "intro_2.5",
            animation: 1000,
          },
          {
            id: "intro_2.5",
            message:
              "We will highly appreciate your valuable feedback and suggestions to improve the concern areas.",
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message:
              "Everything you share is kept confidential and will never be shared with your manager. Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "5":
        defMsg = [
          {
            id: "intro_1",
            message: emojify(
              "Hey there! I am Xane :woman:. I'm here to have a fun conversation with you."
            ),
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message: "Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "6":
        defMsg = [
          {
            id: "intro_1",
            message: emojify(
              "Hi <Name>,\
              I am InCredBot. It has been a little over a month that you joined us and I’d love to get your feedback on how your experience has been so far."
            ),
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message: "Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: emojify(
              "I wish you’d stay and chat. Click the button if you change your mind. :blush:\
              Else, you can close the tab and come back later at your convenience."
            ),
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "12":
        defMsg = [
          {
            id: "intro_1",
            message: emojify(
              "Hey there, I'm HGS RPA Assessment Bot. I'm here to help you with ROI calculation based on your inputs."
            ),
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message: "Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "14":
        defMsg = [
          {
            id: "intro_1",
            message: emojify(
              "Hi, I’m Seamless, your personal LeanOps Assessment consultant!\
              I’d like to ask you a few questions to help you understand how much you can save with our LeanOps framework & automation technology."
            ),
            trigger: "comp_msg_1",
            animation: 1000,
          },
          {
            id: "comp_msg_1",
            message: emojify(
              "I’ll walk you through 2 assessment phases\
              If at any point in time you’d like to connect with a consultant just hit the button at the bottom right of your screen."
            ),
            trigger: id,
            animation: 1000,
          },
          // {
          //   id: "comp_msg_2",
          //   options: [
          //     { value: 1, label: "Yeah, Sure", trigger: id },
          //     { value: 2, label: "Nope, later", trigger: "no_msg" },
          //   ],
          // },
          // {
          //   id: "no_msg",
          //   message: temMs,
          //   trigger: "begin_button",
          //   animation: 1000,
          // },
          // {
          //   id: "begin_button",
          //   options: [{ value: 1, label: "Let's Begin", trigger: id }],
          // },
        ];
        break;
      case "309":
        defMsg = [
          {
            id: "intro_1",
            message: introMsg,
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message:
              "We value every response so be open and candid, the survey is anonymous. Are you ready to start?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yes",
                trigger: "yes_msg",
              },
              {
                value: 2,
                label: "No",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "yes_msg",
            message: "Great, let’s get to it.",
            trigger: id,
            animation: 1000,
          },
          {
            id: "no_msg",
            message:
              "Alright, no problem. The survey closes on (September 05) so do come back to this link and complete the survey before that.",
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      default:
        defMsg = [
          {
            id: "intro_1",
            message: introMsg,
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message:
              "Everything you share is kept confidential and will never be shared with your manager. Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
    }
  } else if (env === "staging") {
    switch (cid) {
      case "9":
        defMsg = [
          {
            id: "intro_1",
            message:
              window.location.href.includes("hrlogica") == true
                ? emojify(
                    "Hi! I'm HR Logica's Assistant :woman:, thanks for dining at Cravity"
                  )
                : emojify("Hi! I'm Xane :woman:, thanks for dining at Cravity"),
            trigger: id,
          },
        ];
        break;
      case "10":
        defMsg = [
          {
            id: "intro_1",
            message:
              window.location.href.includes("hrlogica") == true
                ? emojify(
                    "Hey there! I am HR Logica’s Assistant.I am here to know your experience at Redington. Everything you share will be confidential."
                  )
                : emojify(
                    "Hey there! I am Xane.I am here to know your experience at Redington. Everything you share will be confidential."
                  ),
            trigger: "comp_msg",
          },
          {
            id: "comp_msg",
            message: "Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "14":
        defMsg = [
          {
            id: "intro_1",
            message:
              window.location.href.includes("hrlogica") == true
                ? emojify(
                    `Hey <Name>! I am HR Logica’s Assistant, I am here to know your interview experience with Moengage. Everything you share will be kept confidential and will be used to improve our processes.`
                  )
                : emojify(
                    `Hey <Name>! I am Xane, I am here to know your interview experience with Moengage. Everything you share will be kept confidential and will be used to improve our processes.`
                  ),
            trigger: "begin_msg",
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "11":
        defMsg = [
          {
            id: "intro_1",
            message:
              window.location.href.includes("hrlogica") == true
                ? emojify(
                    "Hey there I am HR logica's Assistant. I am here to get a few details for easy onboarding. Can we have a quick chat ?"
                  )
                : emojify(
                    "Hey there I am Xane. I am here to get a few details for easy onboarding. Can we have a quick chat ?"
                  ),
            trigger: "begin_msg",
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "12":
        defMsg = [
          {
            id: "intro_1",
            message:
              window.location.href.includes("hrlogica") == true
                ? emojify(
                    "We are sad to see you go :disappointed: We would like to know your experience while you were with us. It will only take a 2-3 minutes."
                  )
                : emojify(
                    "We are sad to see you go :disappointed: We would like to know your experience while you were with us. It will only take a 2-3 minutes."
                  ),
            trigger: "comp_msg",
          },
          {
            id: "comp_msg",
            message:
              "Also, we would like you to know that  this feedback is being carried out by HR Logica and personal information will never be shared with your organisation.",
            trigger: "comp_msg_2",
            animation: 1000,
          },
          {
            id: "comp_msg_2",
            message: "Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "13":
        defMsg = [
          {
            id: "intro_1",
            message: emojify(
              "Hey there! I am Xane :woman:. I have been hired by your CEO to know your experience at First Source with various functions you interact with."
            ),
            trigger: "comp_msg",
          },
          {
            id: "comp_msg",
            message: "Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "16":
        defMsg = [
          {
            id: "intro_1",
            message: emojify(
              "Hi! Thanks for choosing Vivanta Hotels. I'm Xane, hired by the President at Vivanta. Anything you share will be appreciated and help us serve you better in the future."
            ),
            trigger: "comp_msg",
          },
          {
            id: "comp_msg",
            message: "Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "18":
        defMsg = [
          {
            id: "intro_1",
            message: gsAdvisorsMsg,
            trigger: "comp_msg",
          },
          {
            id: "comp_msg",
            message: "Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "20":
        defMsg = [
          {
            id: "intro_1",
            message: "Hello! Hope you're doing good.",
            trigger: "comp_msg",
          },
          {
            id: "comp_msg",
            message:
              "Everything you share is kept confidential. Shall we begin?",
            trigger: "begin_msg",
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "21":
        defMsg = [
          {
            id: "intro_1",
            message: emojify(
              "Hey there! I am Xane :woman:, your chat buddy at First Source. I'm here to know some details about you."
            ),
            trigger: "comp_msg",
          },
          {
            id: "comp_msg",
            message:
              "Everything you share is kept confidential so feel free to share the required information. Shall we begin?",
            trigger: "begin_msg",
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      default:
        defMsg = [
          {
            id: "intro_1",
            message: nablerMsg,
            trigger: "comp_msg",
          },
          {
            id: "comp_msg",
            message:
              "Everything you share is kept confidential and will never be shared with your manager. Shall we begin?",
            trigger: "begin_msg",
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
    }
  } else if (env === "production") {
    switch (cid) {
      case "22":
      case "23":
      case "24":
      case "25":
      case "26":
        defMsg = [
          {
            id: "intro_1",
            message: introMsg,
            trigger: "intro_2",
            animation: 1000,
          },
          {
            id: "intro_2",
            message: "<IncredBulletPoints>",
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message: "Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "21":
        defMsg = [
          {
            id: "intro_1",
            message: "<speakUpLittleNap>",
            trigger: "intro_1.4",
            animation: 1000,
          },
          {
            id: "intro_1.4",
            message: "<littleNapPoster>",
            trigger: "intro_1.41",
            animation: 1000,
          },
          {
            id: "intro_1.41",
            message: introMsg,
            trigger: "intro_1.5",
            animation: 1000,
          },
          {
            id: "intro_1.5",
            message:
              "Speak up survey is being managed by an independent survey company. This outside company will host the survey on the Internet and collect your responses online. All responses will remain strictly anonymous. We will not be able to trace individual responses back to you.  Please be thoughtful, honest, and candid when you complete the survey.",
            trigger: "intro_2",
            animation: 1000,
          },
          {
            id: "intro_2",
            message:
              "We would like to get 100% participation from all Lttlenapience   in order to ensure that each and every employee's voice is heard. So, please go ahead, let your fingers do the talking.",
            trigger: "intro_3",
            animation: 1000,
          },
          {
            id: "intro_3",
            message: "<list>",
            trigger: "intro_4",
            animation: 1000,
          },
          {
            id: "intro_4",
            message: "<RedMessage>",
            trigger: "intro_5",
            animation: 1000,
          },
          {
            id: "intro_5",
            message: "WE ARE ONE TEAM ONE DREAM",
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message:
              "Everything you share is kept confidential and will never be shared with your manager. Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "30":
        defMsg = [
          {
            id: "intro_1",
            message: "<speakUpLittleNap2024>",
            trigger: "intro_1.4",
            animation: 1000,
          },
          {
            id: "intro_1.4",
            message: "<littleNapPoster2024>",
            trigger: "intro_1.41",
            animation: 1000,
          },
          {
            id: "intro_1.41",
            message: introMsg,
            trigger: "intro_1.5",
            animation: 1000,
          },
          {
            id: "intro_1.5",
            message:
              "Speak up survey is being managed by an independent survey company. This outside company will host the survey on the Internet and collect your responses online. All responses will remain strictly anonymous. We will not be able to trace individual responses back to you.  Please be thoughtful, honest, and candid when you complete the survey.",
            trigger: "intro_2",
            animation: 1000,
          },
          {
            id: "intro_2",
            message:
              "We would like to get 100% participation from all Lttlenapience   in order to ensure that each and every employee's voice is heard. So, please go ahead, let your fingers do the talking.",
            trigger: "intro_3",
            animation: 1000,
          },
          {
            id: "intro_3",
            message: "<list>",
            trigger: "intro_4",
            animation: 1000,
          },
          {
            id: "intro_4",
            message: "<RedMessage>",
            trigger: "intro_5",
            animation: 1000,
          },
          {
            id: "intro_5",
            message: "WE ARE ONE TEAM ONE DREAM",
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message: "Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;

      case "2":
        defMsg = [
          {
            id: "intro_1",
            message:
              "Hi <Name>,\
                Thank you for choosing to share your feedback. I am InCredBot and would like to chat with you for a few minutes to understand your experience at InCred.",
            trigger: "comp_msg_1",
            animation: 1000,
          },
          {
            id: "comp_msg_1",
            message:
              "I assure you that your responses will be shared only with the HR leadership, with the aim of continuously improving on the work ecosystem at InCred.",
            trigger: "comp_msg_2",
            animation: 1000,
          },
          {
            id: "comp_msg_2",
            message: "Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Not Now",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: emojify(
              "I wish you’d stay and chat. Click the button if you change your mind. :blush:\
                Else, you can close the tab and come back later at your convenience.	"
            ),
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "3":
        defMsg = [
          {
            id: "intro_1",
            message: emojify(
              "Hey there! I am Xane :woman:. We are rolling out a Quick Satisfaction Survey which will help us to improve as an organisation."
            ),
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message:
              "Everything you share is kept confidential so feel free to give candid feedback. Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "4":
      case "6":
      case "7":
        defMsg = [
          {
            id: "intro_1",
            message: emojify(
              "Hey there! I am Xane :woman:. We are rolling out a Quick Satisfaction Survey which will help us to improve as an organisation."
            ),
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message:
              "Everything you share is kept confidential so feel free to give candid feedback. Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      case "20":
        console.log(cid);
        defMsg = [
          {
            id: "intro_1",
            message: introMsg,
            trigger: "comp_msg",
            animation: 1000,
          },
          {
            id: "comp_msg",
            message:
              "We value every response so be open and candid, the survey is anonymous. Are you ready to start?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yes",
                trigger: "yes_msg",
              },
              {
                value: 2,
                label: "No",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "yes_msg",
            message: "Great, let’s get to it.",
            trigger: id,
            animation: 1000,
          },
          {
            id: "no_msg",
            message:
              "Alright, no problem. The survey closes on (September 05) so do come back to this link and complete the survey before that.",
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
        break;
      default:
        defMsg = [
          {
            id: "intro_1",
            message: introMsg,
            trigger: "comp_msg",
            animation: 1000,
          },
          // {
          //   id: "intro_1.5",
          //   message:
          //     "This Employee Speak-up survey help in measuring, evaluating, and analyzing concerns that improve productivity and engagement and allow the company to get a better idea of how employees feel about their work life. This is the opportunity for employees to feel heard.",
          //   trigger: "intro_2",
          //   animation: 1000,
          // },
          // {
          //   id: "intro_2",
          //   message:
          //     " Confidentiality:  Little Nap assures you that we will keep all your data of this survey highly confidential. Please be as honest as you can while fulfilling this survey form.",
          //   trigger: "intro_2.5",
          //   animation: 1000,
          // },
          // {
          //   id: "intro_2.5",
          //   message:
          //     "We will highly appreciate your valuable feedback and suggestions to improve the concern areas.",
          //   trigger: "comp_msg",
          //   animation: 1000,
          // },
          {
            id: "comp_msg",
            message:
              "Everything you share is kept confidential and will never be shared with your manager. Shall we begin?",
            trigger: "begin_msg",
            animation: 1000,
          },
          {
            id: "begin_msg",
            options: [
              {
                value: 1,
                label: "Yeah, Sure",
                trigger: id,
              },
              {
                value: 2,
                label: "Nope, later",
                trigger: "no_msg",
              },
            ],
          },
          {
            id: "no_msg",
            message: temMs,
            trigger: "begin_button",
            animation: 1000,
          },
          {
            id: "begin_button",
            options: [
              {
                value: 1,
                label: "Let's Begin",
                trigger: id,
              },
            ],
          },
        ];
    }
  }
  // if (env == "development") {
  //   switch (cid) {
  //     case "1":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             "Hi! I am Xane. I am here to know your experience with Telecom Network Solutions (TNS).",
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message: "Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "10":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: "Hey there! I am Xane",
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "I would like to know what your day looks like today. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "11":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             'Hey there! I am Xane. I have been hired by your organization to gather your feedback about your "Personal Safety". Complete the form to know your "Risk Profile". Shall we begin?',
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "12":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             window.location.href.includes("hrlogica") === true
  //               ? hrlogicaMsg
  //               : xaneMsg,
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "13":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. I have been hired by your CEO to know your experience at First Source with various functions you interact with."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message: "Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "22":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             window.location.href.includes("hrlogica") === true
  //               ? hrlogicaMsg
  //               : nablerMsg,
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with your manager. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "31":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. Thanks for participating in the POSH Workshop at Nabler. We would like to know your valuable feedback on the same."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "32":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. I have been hired by your CEO to gather your feedback about the workplace."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with your manager. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "33":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             window.location.href.includes("hrlogica") === true
  //               ? hrlogicaMsg
  //               : nablernewMsg,
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "34":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: beYouPlusMsg,
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "39":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. Thanks for participating in the Hackathon event at Nabler. We would like to know your valuable feedback on the same."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "44":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Buna! Eu sunt Xane. Am fost angajat de CEO-ul tău pentru a colecta feedback-ul tau despre locul de muncă."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Tot ce vei împărtăși va fi confidențial.\
  //               Putem sa incepem?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Da, desigur", trigger: id },
  //             { value: 2, label: "Nu", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Sa incepem", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "45":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Ей там! Аз съм Xane. Бях нает от Вашия изпълнителен директор, за да събера Вашата обратна връзка за работното място."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Всичко, което споделяте, ще бъде поверително.\
  //                   Ще започнем ли?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Да, разбира се", trigger: id },
  //             { value: 2, label: "Не", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Нека да започнем", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "54":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out the Induction Feedback Survey which will help us know how to further improve on our processes."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "61":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out the Induction Feedback Survey which will help us know how to further improve on our processes."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "68":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out the Data Science- Experiential Learning Program Survey which will help us know how to further improve on our processes."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "117":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. Thanks for participating in the Walkathon event at Nabler. We would like to know your valuable feedback on the same."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "122":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: blueQuadMsg,
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message: "Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "217":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out the Design Thinking Workshop Survey which will help us know how to further improve on our processes."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "218":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane 👩. I'm here to play some interesting games with you! Also, at the end of the games, you will stand a chance to win exciting prizes."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message: "Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "220":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             "Hi <Name>,\
  //               Thank you for choosing to share your feedback. I am InCredBot and would like to chat with you for a few minutes to understand your experience at InCred.",
  //           trigger: "comp_msg_1",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg_1",
  //           message:
  //             "I assure you that your responses will be shared only with the HR leadership, with the aim of continuously improving on the work ecosystem at InCred.",
  //           trigger: "comp_msg_2",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg_2",
  //           message: "Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Not Now", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: emojify(
  //             "I wish you’d stay and chat. Click the button if you change your mind. :blush:\
  //               Else, you can close the tab and come back later at your convenience.	"
  //           ),
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "221":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hi <Name>,\
  //             I am InCredBot. It has been a little over a month that you joined us and I’d love to get your feedback on how your experience has been so far."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message: "Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: emojify(
  //             "I wish you’d stay and chat. Click the button if you change your mind. :blush:\
  //             Else, you can close the tab and come back later at your convenience."
  //           ),
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "222":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out a Quick Satisfaction Survey which will help us to support you during these tough times."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential so feel free to give candid feedback. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "223":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out a Quick Satisfaction Survey which will help us to support you during these tough times."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential so feel free to give candid feedback. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "224":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out the Management Staff Feedback Survey which will help us know how to further improve on our processes."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential so feel free to give candid feedback. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "226":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. I'm here to have a fun conversation with you."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message: "Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     default:
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: introMsg,
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with your manager. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //   }
  // } else if (env == "staging") {
  //   switch (cid) {
  //     case "9":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             window.location.href.includes("hrlogica") == true
  //               ? emojify(
  //                   "Hi! I'm HR Logica's Assistant :woman:, thanks for dining at Cravity"
  //                 )
  //               : emojify("Hi! I'm Xane :woman:, thanks for dining at Cravity"),
  //           trigger: id,
  //         },
  //       ];
  //       break;
  //     case "10":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             window.location.href.includes("hrlogica") == true
  //               ? emojify(
  //                   "Hey there! I am HR Logica’s Assistant.I am here to know your experience at Redington. Everything you share will be confidential."
  //                 )
  //               : emojify(
  //                   "Hey there! I am Xane.I am here to know your experience at Redington. Everything you share will be confidential."
  //                 ),
  //           trigger: "comp_msg",
  //         },
  //         {
  //           id: "comp_msg",
  //           message: "Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "14":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             window.location.href.includes("hrlogica") == true
  //               ? emojify(
  //                   `Hey <Name>! I am HR Logica’s Assistant, I am here to know your interview experience with Moengage. Everything you share will be kept confidential and will be used to improve our processes.`
  //                 )
  //               : emojify(
  //                   `Hey <Name>! I am Xane, I am here to know your interview experience with Moengage. Everything you share will be kept confidential and will be used to improve our processes.`
  //                 ),
  //           trigger: "begin_msg",
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "11":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             window.location.href.includes("hrlogica") == true
  //               ? emojify(
  //                   "Hey there I am HR logica's Assistant. I am here to get a few details for easy onboarding. Can we have a quick chat ?"
  //                 )
  //               : emojify(
  //                   "Hey there I am Xane. I am here to get a few details for easy onboarding. Can we have a quick chat ?"
  //                 ),
  //           trigger: "begin_msg",
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "12":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             window.location.href.includes("hrlogica") == true
  //               ? emojify(
  //                   "We are sad to see you go :disappointed: We would like to know your experience while you were with us. It will only take a 2-3 minutes."
  //                 )
  //               : emojify(
  //                   "We are sad to see you go :disappointed: We would like to know your experience while you were with us. It will only take a 2-3 minutes."
  //                 ),
  //           trigger: "comp_msg",
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Also, we would like you to know that  this feedback is being carried out by HR Logica and personal information will never be shared with your organisation.",
  //           trigger: "comp_msg_2",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg_2",
  //           message: "Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "13":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. I have been hired by your CEO to know your experience at First Source with various functions you interact with."
  //           ),
  //           trigger: "comp_msg",
  //         },
  //         {
  //           id: "comp_msg",
  //           message: "Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "16":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hi! Thanks for choosing Vivanta Hotels. I'm Xane, hired by the President at Vivanta. Anything you share will be appreciated and help us serve you better in the future."
  //           ),
  //           trigger: "comp_msg",
  //         },
  //         {
  //           id: "comp_msg",
  //           message: "Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "18":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: gsAdvisorsMsg,
  //           trigger: "comp_msg",
  //         },
  //         {
  //           id: "comp_msg",
  //           message: "Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "20":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: "Hello! Hope you're doing good.",
  //           trigger: "comp_msg",
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential. Shall we begin?",
  //           trigger: "begin_msg",
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "21":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:, your chat buddy at First Source. I'm here to know some details about you."
  //           ),
  //           trigger: "comp_msg",
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential so feel free to share the required information. Shall we begin?",
  //           trigger: "begin_msg",
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     default:
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: nablerMsg,
  //           trigger: "comp_msg",
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with your manager. Shall we begin?",
  //           trigger: "begin_msg",
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //   }
  // } else if (env == "production") {
  //   switch (cid) {
  //     case "20":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             "Hi! I am Xane. I am here to know your experience with Telecom Network Solutions (TNS).",
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message: "Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "13":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: "Hey there! I am Xane",
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "I would like to know what your day looks like today. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "14":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             'Hey there! I am Xane. I have been hired by your organization to gather your feedback about your "Personal Safety". Complete the form to know your "Risk Profile". Shall we begin?',
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "15":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             window.location.href.includes("hrlogica") === true
  //               ? hrlogicaMsg
  //               : xaneMsg,
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "23":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             window.location.href.includes("hrlogica") === true
  //               ? hrlogicaMsg
  //               : nablerMsg,
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with your manager. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "24":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             window.location.href.includes("hrlogica") === true
  //               ? hrlogicaMsg
  //               : nablernewMsg,
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "25":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             window.location.href.includes("hrlogica") === true
  //               ? hrlogicaMsg
  //               : nablernewMsg,
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "16":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. I have been hired by your CEO to know your experience at First Source with various functions you interact with."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message: "Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "26":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. Thanks for participating in the Hackathon event at Nabler. We would like to know your valuable feedback on the same."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "27":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. Thanks for participating in the POSH Workshop at Nabler. We would like to know your valuable feedback on the same."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "28":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Buna! Eu sunt Xane. Am fost angajat de CEO-ul tău pentru a colecta feedback-ul tau despre locul de muncă."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Tot ce vei împărtăși va fi confidențial.\
  //               Putem sa incepem?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Da, desigur", trigger: id },
  //             { value: 2, label: "Nu", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Sa incepem", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "29":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Ей там! Аз съм Xane. Бях нает от Вашия изпълнителен директор, за да събера Вашата обратна връзка за работното място."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Всичко, което споделяте, ще бъде поверително.\
  //                   Ще започнем ли?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Да, разбира се", trigger: id },
  //             { value: 2, label: "Не", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Нека да започнем", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "30":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out the Employee Voice- Sports Month Feedback Survey which will help us know how to further improve on the engagement activities."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential so feel free to share your candid feedback. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "31":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out the Induction Feedback Survey which will help us know how to further improve on our processes."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;

  //     case "32":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. I have been hired by your CEO to gather your feedback about the workplace."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with your manager. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "33":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out the All Hands Feedback Survey which will help us know how to further improve on our processes."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;

  //     case "35":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out the Induction Feedback Survey which will help us know how to further improve on our processes."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "36":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out the Data Science- Experiential Learning Program Survey which will help us know how to further improve on our processes."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "37":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. Thanks for participating in the Walkathon event at Nabler. We would like to know your valuable feedback on the same."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "39":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out the Design Thinking Workshop Survey which will help us know how to further improve on our processes."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "40":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message:
  //             "Hi <Name>,\
  //               Thank you for choosing to share your feedback. I am InCredBot and would like to chat with you for a few minutes to understand your experience at InCred.",
  //           trigger: "comp_msg_1",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg_1",
  //           message:
  //             "I assure you that your responses will be shared only with the HR leadership, with the aim of continuously improving on the work ecosystem at InCred.",
  //           trigger: "comp_msg_2",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg_2",
  //           message: "Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Not Now", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: emojify(
  //             "I wish you’d stay and chat. Click the button if you change your mind. :blush:\
  //               Else, you can close the tab and come back later at your convenience.	"
  //           ),
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "41":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out a Quick Satisfaction Survey which will help us to support you during these tough times."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential so feel free to give candid feedback. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "42":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out the All Hands Feedback Survey which will help us know how to further improve on our processes."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with anyone. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "43":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out a Quick Satisfaction Survey which will help us to support you during these tough times."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential so feel free to give candid feedback. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "44":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out a Quick Satisfaction Survey which will help us to support you during these tough times."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential so feel free to give candid feedback. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "45":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out a Quick Satisfaction Survey which will help us to support you during these tough times."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential so feel free to give candid feedback. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     case "46":
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: emojify(
  //             "Hey there! I am Xane :woman:. We are rolling out a Quick Satisfaction Survey which will help us to improve as an organisation."
  //           ),
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential so feel free to give candid feedback. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //       break;
  //     default:
  //       defMsg = [
  //         {
  //           id: "intro_1",
  //           message: introMsg,
  //           trigger: "comp_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "comp_msg",
  //           message:
  //             "Everything you share is kept confidential and will never be shared with your manager. Shall we begin?",
  //           trigger: "begin_msg",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_msg",
  //           options: [
  //             { value: 1, label: "Yeah, Sure", trigger: id },
  //             { value: 2, label: "Nope, later", trigger: "no_msg" },
  //           ],
  //         },
  //         {
  //           id: "no_msg",
  //           message: temMs,
  //           trigger: "begin_button",
  //           animation: 1000,
  //         },
  //         {
  //           id: "begin_button",
  //           options: [{ value: 1, label: "Let's Begin", trigger: id }],
  //         },
  //       ];
  //   }
  // }

  return defMsg;
};
