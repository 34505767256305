import emoji from 'node-emoji';

export default function convertToQuestionAnswer(data) {
  const questionSet = [];

  const prop = Object.keys(data);
  for (let i = 0; i < prop.length; i += 1) {
    const question = {};
    const key = prop[i];
    question.id = data[key].question_id;
    question.message = data[key].question;
    question.answers = data[key].answers.map(ans => ({
      id: ans.answer_id,
      answer: emoji.emojify(ans.answer),
    }));
    if (data[key].answer_type === 'Multiple Choice') {
      question.answer_type = 'MCQ';
    } else {
      question.answer_type = data[key].answer_type;
      question.department = [];
      question.followup = [];
    }
    while (
      i < prop.length &&
      (data[prop[i]].is_follow_up === 1 || data[prop[i]].question_id === question.id)
    ) {
      if (data[prop[i]].answer_type === 'Multiple Choice') {
        question.answers.find(ans => ans.id === data[prop[i]].answer_id).selected = true;
      } else if (data[prop[i]].answer_type === 'Objective' || data[prop[i]].is_follow_up === 1) {
        if (data[prop[i]].is_follow_up === 1) {
          const followUp = {};
          followUp.id = data[prop[i]].question_id;
          followUp.message = data[prop[i]].question;
          followUp.answered = true;
          followUp.answer = data[prop[i]].answer;
          followUp.answer_type = 'Followup';
          // console.log(followUp.id, data[prop[i]].question_id);
          // console.log(followUp.message, data[prop[i]].question);
          // console.log(followUp.answer, data[prop[i]].answer);
          // console.log(followUp.answer_type);
          question.followup.pop();
          question.followup.push(followUp);
        } else {
          const dept = {};
          const followUp = {};
          dept.department_id = data[prop[i]].department_id;
          dept.department = data[prop[i]].department;
          dept.answer_id = data[prop[i]].answer_id;
          question.followup.push(followUp);
          question.department.push(dept);
        }
      } else if (data[prop[i]].answer_type === 'Subjective' && data[prop[i]].is_follow_up === 0) {
        question.followup = [];
        const dept = {};
        dept.department_id = data[prop[i]].department_id;
        dept.department = data[prop[i]].department;
        dept.answer = data[prop[i]].answer;
        delete question.answers;
        question.department.push(dept);
      }
      i += 1;
    }
    i -= 1;
    questionSet.push(question);
  }
  // console.log(questionSet);
  return questionSet;
}
