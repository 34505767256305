import styled from 'styled-components';
import defaultTheme from './theme';

const Header = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.headerBgColor};
  color: ${({ theme }) => theme.headerFontColor};
  display: flex;
  fill: ${({ theme }) => theme.headerFontColor};
  height: 5.2vh;
  justify-content: space-between;
  padding: 0 10px;
  min-height:2.5vh;
  @media screen and (min-width:359px) {
  	height:0.2vh;
  }
  @media screen and (min-width:361px) {
  	height:5.2vh;
  }
  @media screen and (min-width:410px) {
  	height:0.2vh;
  }
  @media screen and (max-width:318px) {
    height:3vh;
  }
  @media screen and (min-width:319px) {
  	height:0.9vh;
  }
  @media screen and (min-width:321px) {
  	height:0.2vh;
  }
  @media screen and (min-width:374px) {
  	height:1.2vh;
  }
  @media screen and (min-width:376px) {
  	height:0vh;
  }
  @media screen and (min-width:414px) {
  	height:0.2vh;
  }
  @media screen and (min-width:451px) {
    height:3.2vh;
  }
  @media screen and (min-width:452px) {
    height:5.2vh;
  }
`;

Header.defaultProps = {
  theme: defaultTheme,
};

export default Header;
