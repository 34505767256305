import styled from 'styled-components';
import defaultTheme from '../../theme';

const DateElement = styled.div`
  background: ${props => (props.bubbleColor ? props.bubbleColor : props.theme.botBubbleColor)};
  border-radius: 22px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.botFontColor};
  display: inline-block;
  font-size: 16px;
  padding: 12px;
`;

DateElement.defaultProps = {
  theme: defaultTheme,
};

export default DateElement;
