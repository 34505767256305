import React from "react";
import { Modal, Button, FormGroup, FormControl } from "react-bootstrap";
import "../Styles/Login.css";

const axios = require("axios");
const axiosConfig = require("../components/AxiosConfig/AxiosConfig");
import Cookies from "universal-cookie";

/* It is used to test the survey in development */
// const cookies = new Cookies();
// cookies.set("x-auth", "ef5a4f109cad4d278ee3b61c7e0433d1");
// cookies.set("cid", "6");
// cookies.set("survey_id", "326");
// cookies.set("user", {
//   name: "Siddesh G",
//   designation: "Principal Consultant",
//   initials: "SG",
//   user_id: 1312,
// });

console.log(process.env.NODE_ENV);
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      // number: null,
      hover: false,
      showModal: false,
      modalMessage: null,
      askEmail: false,
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    // this.handleNumberChange = this.handleNumberChange.bind(this);
    this.handleForgetPassword = this.handleForgetPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleCandidateSubmit = this.handleCandidateSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    axios
      .post(
        "/auths/login",
        { email: this.state.email, password: this.state.password },
        axiosConfig
      )
      .then((response) => {
        response = response.data;

        if (!response.error) {
          this.props.handleUpdate(response.data);
        } else {
          this.setState({
            showModal: true,
            modalMessage: response.message,
            askEmail: false,
          });
        }
      })
      .catch((error) => console.log(error));
  }

  // handleCandidateSubmit(e) {
  //   e.preventDefault();
  //   axios
  //     .post('/auths/verify', { email: this.state.email, phone: this.state.number }, axiosConfig)
  //     .then(response => {
  //       response = response.data;
  //       if (!response.error) {
  //         this.props.handleUpdate(response.data);
  //       } else {
  //         this.setState({
  //           showModal: true,
  //           modalMessage: response.message,
  //         });
  //       }
  //     })
  //     .catch(error => console.log(error));
  // }

  handleEmailChange(e) {
    const target = e.target;
    this.setState({
      email: target.value,
    });
  }

  // handleNumberChange(e) {
  //   const target = e.target;
  //   this.setState({
  //     number: target.value,
  //   });
  // }

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  handlePasswordChange(e) {
    const target = e.target;
    this.setState({
      password: target.value,
    });
  }

  handleForgetPassword() {
    if (this.state.email != null && this.state.email != "") {
      axios
        .get(
          `/auths/signup?type=password&action=forget&email=${this.state.email}`,
          axiosConfig
        )
        .then((response) => {
          response = response.data;
          if (!response.error) {
            if (response.code === "email_sent") {
              this.setState({
                showModal: true,
                modalMessage: response.message,
                askEmail: false,
              });
            }
          } else {
            this.setState({
              showModal: true,
              modalMessage: response.message,
              askEmail: false,
            });
          }
        })
        .catch((error) => console.log(error));
    } else {
      this.setState({
        showModal: true,
        modalMessage: "Enter your Email Address",
        askEmail: true,
      });
    }
  }

  render() {
    const submitButton = {
      color: "#004242",
      letterSpacing: "1px",
      backgroundColor: "#00ffff",
      fontFamily: '"Roboto","Open Sans", sans-serif',
      transition: "background-color .15s ease-in",
      textTransform: "uppercase",
      cursor: "pointer",
      display: "block",
      fontSize: 16,
      fontWeight: 800,
      width: 300,
      height: 40,
      borderRadius: 5,
      borderWidth: 0,
    };
    const hoverSubmit = Object.assign({}, submitButton, {
      backgroundColor: "#00cccc",
    });
    let submitStyle;
    this.state.hover === true
      ? (submitStyle = hoverSubmit)
      : (submitStyle = submitButton);
    return (
      <React.Fragment>
        <div>
          <div>
            <div
              style={{
                position: "absolute",
                background: "linear-gradient(#196879, #051534)",
                width: "100vw",
                height: "100vh",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "15vh",
                  left: 0,
                  right: 0,
                  bottom: 0,
                  margin: "auto",
                }}
              >
                <center>
                  <div style={{ height: "150px" }}>
                    {window.location.href.includes("hrlogica") == true ? (
                      <React.Fragment>
                        <img
                          width={297}
                          height="150px"
                          src="https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/hrlogica.png"
                        />
                        <div
                          style={{
                            color: "white",
                            display: "inline-block",
                            fontSize: "9rem",
                            lineHeight: "25%",
                            fontFamily: "Times New Roman",
                            position: "relative",
                            top: "63px",
                          }}
                        >
                          LOGICA
                        </div>
                      </React.Fragment>
                    ) : (
                      <img
                        width={297}
                        height={"95px"}
                        src="https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/logo.png"
                      />
                    )}
                  </div>
                </center>
                <br />
                <br />
                <br />
                <center>
                  <span
                    style={{
                      marginRight: "200px",
                      display: "block",
                      fontFamily: '"Roboto","Open Sans", sans-serif',
                      fontSize: 30,
                      color: "white",
                    }}
                  >
                    Sign In
                  </span>
                </center>
                <br />
                <br />
                <center>
                  <form onSubmit={this.handleSubmit}>
                    <input
                      type="email"
                      placeholder="Email Address"
                      onChange={this.handleEmailChange}
                      style={{
                        display: "block",
                        color: "#00ffff",
                        width: 300,
                        height: 40,
                        background: "none",
                        boxShadow: "none",
                        boxSizing: "border-box",
                        border: "1px solid #00ffff",
                        borderRadius: 5,
                        padding: "17px 10px",
                        fontSize: 20,
                      }}
                      required
                    />
                    <br />
                    <input
                      type="password"
                      placeholder="Password"
                      onChange={this.handlePasswordChange}
                      style={{
                        display: "block",
                        color: "#00ffff",
                        width: 300,
                        height: 40,
                        background: "none",
                        boxShadow: "none",
                        boxSizing: "border-box",
                        border: "1px solid #00ffff",
                        borderRadius: 5,
                        padding: "17px 10px",
                        fontSize: 20,
                      }}
                      required
                    />
                    <br />
                    <button
                      type="submit"
                      onMouseEnter={this.toggleHover}
                      onMouseLeave={this.toggleHover}
                      style={submitStyle}
                    >
                      {"Login"}
                    </button>
                  </form>
                  <div style={{ paddingTop: "20px" }}>
                    <a onClick={this.handleForgetPassword}>
                      <span
                        style={{
                          display: "inline",
                          cursor: "pointer",
                          fontFamily: '"Roboto","Open Sans", sans-serif',
                          fontSize: 16,
                          color: "white",
                        }}
                      >
                        Forgot your password?
                      </span>
                    </a>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
        <Modal
          backdrop={true}
          className="modal-container"
          style={{ display: "flex", alignItems: "center" }}
          backdropStyle={{
            opacity: "0.5",
            zIndex: 2,
            height: "100% !important",
            position: "fixed",
          }}
          animation={false}
          show={this.state.showModal}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <Modal.Title style={{ textAlign: "center" }}>
              {this.state.modalMessage}
            </Modal.Title>
            {this.state.askEmail === true ? (
              <form>
                <FormGroup>
                  <center>
                    <FormControl
                      style={{ width: 350, marginTop: 20 }}
                      type="email"
                      placeholder="Email Address"
                      onChange={this.handleEmailChange}
                    />
                  </center>
                </FormGroup>
                <center>
                  <Button
                    style={{
                      marginTop: "20px",
                      color: "white",
                      backgroundColor: "#25c16f",
                      fontFamily: '"Roboto","Open Sans", sans-serif',
                      transition: "background-color .15s ease-in",
                      textTransform: "uppercase",
                      display: "block",
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                    onClick={this.handleForgetPassword}
                  >
                    Submit
                  </Button>
                </center>
              </form>
            ) : (
              <center>
                <Button
                  style={{
                    marginTop: "20px",
                    color: "white",
                    backgroundColor: "#25c16f",
                    fontFamily: '"Roboto","Open Sans", sans-serif',
                    transition: "background-color .15s ease-in",
                    textTransform: "uppercase",
                    display: "block",
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                  onClick={this.handleClose}
                >
                  Close
                </Button>
              </center>
            )}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
