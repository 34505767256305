import styled from 'styled-components';

const Footer = styled.div`
  position: relative;
  bottom:40px;
  margin-bottom: 0px;
  margin-top: 40px;
  padding-top: 0px;
`;

export default Footer;
