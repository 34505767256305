import styled from "styled-components";

const DepartmentOptionStyle = styled.div`
  background: ${(props) => props.optionColor};
  ${
    "" /* color: ${(props) =>
    props.optionColor === "#00ffff" ? "#2a2a2a" : props.fontColor}; */
  }
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid #00ffff;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 1rem;
  margin: 0 1rem 1rem 1rem;
  &:hover {
    opacity: 0.5;
  }
`;

export default DepartmentOptionStyle;
