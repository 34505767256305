/* eslint-disable no-nested-ternary */
/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import React from "react";
import MediaQuery from "react-responsive";
import { slide as Menu } from "react-burger-menu";
import { Button } from "semantic-ui-react";
import Cookies from "universal-cookie";
import axios from "axios";
import Image from "./steps/text/Image";

const axiosConfig = require("./components/AxiosConfig/AxiosConfig");

const cookies = new Cookies();

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      width: null,
    };
    this.handleStateChange = this.handleStateChange.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.updateWindowDimension = this.updateWindowDimension.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimension);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
  }

  updateWindowDimension() {
    if (window.innerWidth <= 991) {
      this.setState({
        width: window.innerWidth,
      });
    }
  }

  async handleReset() {
    const cid = await cookies.get("cid");
    const token = await cookies.get("x-auth");
    const userDetails = await cookies.get("user");
    axiosConfig.headers["x-auth"] = token;

    axios
      .delete(
        `/users/${Number(cid)}/delete_filled_survey/${userDetails.user_id}`,
        axiosConfig
      )
      .then(async (response) => {
        window.location.reload();
      })
      .catch((error) => console.log(error));
  }

  closeMenu() {
    this.setState({ visible: false });
  }

  toggleMenu() {
    this.setState({ visible: !this.state.visible });
  }

  handleStateChange(state) {
    this.setState({ visible: state.isOpen });
  }

  render() {
    const cid = cookies.get("cid");
    const hgs = process.env.NODE_ENV == "development" && cid == 8;
    return (
      <div>
        <MediaQuery query="(max-width: 991px)">
          {(matches) => {
            if (matches) {
              if (this.state.width - 15 > 453) {
                // console.log('hello > 420');
                return (
                  <div>
                    <Button
                      style={{
                        backgroundColor: "rgba(0,0,0,0)",
                        zIndex: 1999,
                        position: "absolute",
                        top: "-12px",
                        left: "18px",
                      }}
                      icon="sidebar"
                      onClick={() => this.toggleMenu()}
                    />
                    <Menu
                      width={"200%"}
                      isOpen={this.state.visible}
                      onStateChange={(state) => this.handleStateChange(state)}
                      noOverlay
                    >
                      <div>
                        <div
                          style={{
                            overflow: "hidden",
                            width: "24%",
                            height: "100%",
                            position: "absolute",
                            zIndex: 1231,
                            backgroundColor: "#252525",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            zIndex: 1241,
                            width: "22%",
                            textAlign: "center",
                            top: "15vh",
                            left: "2%",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              zIndex: 99998,
                              backgroundColor:
                                cookies.get("cid") == 3 ||
                                cookies.get("cid") == 4
                                  ? "#202020"
                                  : "#328eb7",
                              borderRadius: "50%",
                              top: "4%",
                              left: "-1%",
                              margin: "0px auto",
                              height: "125px",
                              width: "126px",
                            }}
                          >
                            <span
                              style={{
                                position: "relative",
                                zIndex: 99999,
                                letterSpacing: "-8px",
                                color: "white",
                                fontWeight: 300,
                                fontSize: "6em",
                                left: "-3%",
                              }}
                            >
                              {this.props.user.initials}
                            </span>
                          </div>
                          <span
                            style={{
                              color: "#fff",
                              fontSize: 25,
                              paddingBottom: 5,
                              overflowWrap: "break-word",
                            }}
                            id="username"
                          >
                            {" "}
                            {this.props.user.name}
                          </span>
                          <br />
                          <span
                            style={{ color: "#E0E0E0", fontSize: 18 }}
                            id="designation"
                          >
                            {" "}
                            {hgs ? "" : this.props.user.designation}{" "}
                          </span>
                        </div>
                        {process.env.NODE_ENV === "staging" && (
                          <button
                            style={{
                              cursor: "pointer",
                              overflow: "hidden",
                              borderRadius: 5,
                              backgroundColor: "rgba(0,0,0,0)",
                              border: "2px solid #F2C94C",
                              top: "87%",
                              left: "7.3%",
                              width: "12%",
                              height: "6%",
                              position: "absolute",
                              zIndex: 1299,
                              color: "#F2C94C",
                              fontSize: 14,
                            }}
                            onClick={() => this.props.handleLogout()}
                          >
                            LOGOUT
                          </button>
                        )}
                        {(process.env.NODE_ENV === "staging" ||
                          cid == 222 ||
                          cid == 224) && (
                          <button
                            style={{
                              cursor: "pointer",
                              borderRadius: 5,
                              backgroundColor: "rgba(0,0,0,0)",
                              border: "2px solid #F2C94C",
                              top: "75%",
                              left: "7.3%",
                              width: "12%",
                              height: "6%",
                              position: "absolute",
                              zIndex: 1299,
                              color: "#F2C94C",
                              fontSize: 16,
                            }}
                            onClick={
                              process.env.NODE_ENV === "staging"
                                ? () => this.props.handleReset()
                                : this.handleReset
                            }
                          >
                            {" "}
                            RESET{" "}
                          </button>
                        )}{" "}
                      </div>
                    </Menu>
                  </div>
                );
              }
              // console.log('hello < 420');
              return (
                <div>
                  <Button
                    style={{
                      backgroundColor: "rgba(0,0,0,0)",
                      zIndex: 1999,
                      position: "absolute",
                      top: "-10px",
                      left: "12px",
                    }}
                    icon="sidebar"
                    onClick={() => this.toggleMenu()}
                  />
                  <Menu
                    width={"200%"}
                    isOpen={this.state.visible}
                    onStateChange={(state) => this.handleStateChange(state)}
                    noOverlay
                  >
                    <div>
                      <div
                        style={{
                          overflow: "hidden",
                          width: "24%",
                          height: "100%",
                          position: "absolute",
                          zIndex: 1231,
                          backgroundColor: "rgb(81, 90, 90)",
                          top: "0%",
                        }}
                      />

                      <div
                        style={{
                          position: "absolute",
                          zIndex: 1241,
                          width: "22%",
                          textAlign: "center",
                          top: "10vh",
                          left: "2%",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            zIndex: 99998,
                            backgroundColor:
                              cookies.get("cid") == 3 || cookies.get("cid") == 4
                                ? "#202020"
                                : "#328eb7",
                            borderRadius: "50%",
                            top: "4%",
                            left: "-1%",
                            margin: "0px auto",
                            height: "125px",
                            width: "126px",
                          }}
                        >
                          <span
                            style={{
                              position: "relative",
                              zIndex: 99999,
                              letterSpacing: "-8px",
                              color: "white",
                              fontWeight: 300,
                              fontSize: "6em",
                              left: "-3%",
                            }}
                          >
                            {this.props.user.initials}
                          </span>
                        </div>
                        <span
                          style={{
                            color: "#fff",
                            fontSize: 25,
                            paddingBottom: 5,
                            overflowWrap: "break-word",
                          }}
                          id="username"
                        >
                          {" "}
                          {this.props.user.name}
                        </span>
                        <br />
                        <span
                          style={{ color: "#E0E0E0", fontSize: 18 }}
                          id="designation"
                        >
                          {" "}
                          {hgs ? "" : this.props.user.designation}{" "}
                        </span>
                      </div>
                      {process.env.NODE_ENV === "staging" && (
                        <button
                          style={{
                            cursor: "pointer",
                            overflow: "hidden",
                            borderRadius: 5,
                            backgroundColor: "rgba(0,0,0,0)",
                            border: "2px solid #F2C94C",
                            top: "87%",
                            left: "7.3%",
                            width: "12%",
                            height: "6%",
                            position: "absolute",
                            zIndex: 1299,
                            color: "#F2C94C",
                            fontSize: 14,
                          }}
                          onClick={() => this.props.handleLogout()}
                        >
                          LOGOUT
                        </button>
                      )}
                      {(process.env.NODE_ENV === "staging" ||
                        cid == 222 ||
                        cid == 224) && (
                        <button
                          style={{
                            cursor: "pointer",
                            borderRadius: 5,
                            backgroundColor: "rgba(0,0,0,0)",
                            border: "2px solid #F2C94C",
                            top: "75%",
                            left: "7.3%",
                            width: "12%",
                            height: "6%",
                            position: "absolute",
                            zIndex: 1299,
                            color: "#F2C94C",
                            fontSize: 16,
                          }}
                          onClick={
                            process.env.NODE_ENV === "staging"
                              ? () => this.props.handleReset()
                              : this.handleReset
                          }
                        >
                          {" "}
                          RESET{" "}
                        </button>
                      )}
                    </div>
                  </Menu>
                </div>
              );
            }
            return (
              <div>
                <div>
                  <div
                    style={{
                      overflow: "hidden",
                      width: "24%",
                      height: "100%",
                      position: "absolute",
                      zIndex: 1231,
                      backgroundColor:
                        cookies.get("cid") == 16 || cookies.get("cid") == 9
                          ? "#fff"
                          : cookies.get("cid") == 3 || cookies.get("cid") == 4
                          ? "#EC2029"
                          : "#515a5a",
                    }}
                  />

                  <div
                    style={{
                      position: "absolute",
                      zIndex: 1241,
                      width: "22%",
                      textAlign: "center",
                      top: "15vh",
                      left: "2%",
                    }}
                  >
                    {cookies.get("cid") == 16 ? (
                      <Image
                        className="rsc-ts-image"
                        style={{ boxShadow: "none !important" }}
                        // showAvatar={showAvatar}
                        // user={user}
                        style={{
                          height: "200px",
                          borderRadius: 0,
                          boxShadow: "none",
                        }}
                        src={"https://i.imgur.com/Y1lJzp6.png"}
                        alt="avatar"
                      />
                    ) : (
                      <div>
                        <div
                          style={{
                            position: "relative",
                            zIndex: 99998,
                            backgroundColor:
                              cookies.get("cid") == 3 || cookies.get("cid") == 4
                                ? "#202020"
                                : "#328eb7",
                            borderRadius: "50%",
                            top: "4%",
                            left: "3%",
                            margin: "0px auto",
                            height: "125px",
                            width: "126px",
                          }}
                        >
                          <span
                            style={{
                              position: "relative",
                              zIndex: 99999,
                              letterSpacing: "-8px",
                              color: "white",
                              fontWeight: 300,
                              fontSize: "6em",
                              left: "-3%",
                            }}
                          >
                            {this.props.user.initials}
                          </span>
                        </div>
                        <span
                          style={{
                            color: "#fff",
                            fontSize: 25,
                            paddingBottom: 5,
                            overflowWrap: "break-word",
                          }}
                          id="username"
                        >
                          {" "}
                          {this.props.user.name}
                        </span>
                        <br />
                        <span
                          style={{ color: "#E0E0E0", fontSize: 18 }}
                          id="designation"
                        >
                          {" "}
                          {hgs ? "" : this.props.user.designation}{" "}
                        </span>
                      </div>
                    )}
                  </div>
                  {process.env.NODE_ENV === "staging" && (
                    <button
                      style={{
                        cursor: "pointer",
                        overflow: "hidden",
                        borderRadius: 5,
                        backgroundColor: "rgba(0,0,0,0)",
                        border: "2px solid #F2C94C",
                        top: "87%",
                        left: "7.3%",
                        width: "12%",
                        height: "6%",
                        position: "absolute",
                        zIndex: 1299,
                        color: "#F2C94C",
                        fontSize: 14,
                      }}
                      onClick={() => this.props.handleLogout()}
                    >
                      LOGOUT
                    </button>
                  )}
                  {(process.env.NODE_ENV === "staging" ||
                    cid == 222 ||
                    cid == 224) && (
                    <button
                      style={{
                        cursor: "pointer",
                        borderRadius: 5,
                        backgroundColor: "rgba(0,0,0,0)",
                        border: "2px solid #F2C94C",
                        top: "75%",
                        left: "7.3%",
                        width: "12%",
                        height: "6%",
                        position: "absolute",
                        zIndex: 1299,
                        color: "#F2C94C",
                        fontSize: 16,
                      }}
                      onClick={
                        process.env.NODE_ENV === "staging"
                          ? () => this.props.handleReset()
                          : this.handleReset
                      }
                    >
                      {" "}
                      RESET{" "}
                    </button>
                  )}
                </div>
              </div>
            );
          }}
        </MediaQuery>
      </div>
    );
  }
}

export default SideBar;
