export function themeSelector(cid, env) {
  // default
  const config = {
    formBG: '#2a2a2a',
    questionTextColor: '#00ffff',
    questionBubbleColor: 'inherit',
    surveyTitle: 'iVOC - 2020',
    logoSource: '',
    btnBg: '#00ffff',
    btnTxtColor: '#00ffff',
    btnBorderColor: '#00ffff',
    inputBgColor: '#2a2a2a',
    inputTxtColor: '#00ffff',
    inputBorderColor: '#00ffff',
    optionNotSelected: '#2a2a2a',
    optionSelected: '#00ffff',
    deptOptionColorSelected: '#00ffff',
    deptOptionColorNotSelected: '#2a2a2a',
    deptOptionFontColor: '#00ffff',
    deptAnswerOptionColor: '#00ffff',
    deptAnswerBorderColor: '#00ffff',
  };
  const envList = ['production', 'development', 'staging', 'preproduction'];
  const envIndex = envList.indexOf(env);
  if (envIndex > -1) {
    switch (cid) {
      case [3, 4, 20, 22][envIndex]:
        {
          config.formBG = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.deptOptionColorSelected = ['#a12306', '#a12306', 'ghi', 'lmo'][
            envIndex
          ];
          config.deptOptionColorNotSelected = ['#fff', '#fff', 'ghi', 'lmo'][
            envIndex
          ];
          config.deptOptionFontColor = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.deptAnswerOptionColor = ['#a12306', '#a12306', 'ghi', 'lmo'][
            envIndex
          ];
          config.deptAnswerBorderColor = ['#fff', '#fff', 'ghi', 'lmo'][
            envIndex
          ];
          config.optionNotSelected = ['#a12306', '#a12306', 'ghi', 'lmo'][
            envIndex
          ];
          config.optionSelected = ['#c2bab8', '#c2bab8', 'ghi', 'lmo'][
            envIndex
          ];
          config.inputBgColor = ['#a12306', '#a12306', 'ghi', 'lmo'][envIndex];
          config.inputBorderColor = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.inputTxtColor = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.btnBg = ['#a12306', '#a12306', 'ghi', 'lmo'][envIndex];
          config.btnBorderColor = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.btnTxtColor = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.surveyTitle = '';
          config.logoSource =
            'https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/LittleNap.jpg';
          config.questionTextColor = ['#a12306', '#a12306', 'ghi', 'lmo'][
            envIndex
          ];
        }
        break;
      case [32, 55, 20, 22][envIndex]:
        {
          config.formBG = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.deptOptionColorSelected = ['#ff6600', '#ff6600', 'ghi', 'lmo'][
            envIndex
          ];
          config.deptOptionColorNotSelected = ['#fff', '#fff', 'ghi', 'lmo'][
            envIndex
          ];
          config.deptOptionFontColor = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.deptAnswerOptionColor = ['#a12306', '#a12306', 'ghi', 'lmo'][
            envIndex
          ];
          config.deptAnswerBorderColor = ['#fff', '#fff', 'ghi', 'lmo'][
            envIndex
          ];
          config.optionNotSelected = ['#ff6600', '#ff6600', 'ghi', 'lmo'][
            envIndex
          ];
          config.optionSelected = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.inputBgColor = ['#ff6600', '#ff6600', 'ghi', 'lmo'][envIndex];
          config.inputBorderColor = ['#ff6600', '#ff6600', 'ghi', 'lmo'][
            envIndex
          ];
          config.inputTxtColor = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.btnBg = ['#a12306', '#a12306', 'ghi', 'lmo'][envIndex];
          config.btnBorderColor = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.btnTxtColor = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.surveyTitle = '';
          config.logoSource =
            'https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/LittleNap.jpg';
          config.questionTextColor = ['#000', '#000', 'ghi', 'lmo'][envIndex];
        }
        break;
      case [16, 13, 20, 22][envIndex]:
        {
          config.formBG = ['#2a2a2a', '#2a2a2a', 'ghi', 'lmo'][envIndex];
          config.deptOptionColorSelected = ['#00ffff', '#00ffff', 'ghi', 'lmo'][
            envIndex
          ];
          config.deptOptionColorNotSelected = [
            '#2a2a2a',
            '#2a2a2a',
            'ghi',
            'lmo',
          ][envIndex];
          config.deptOptionFontColor = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.deptAnswerOptionColor = ['#a12306', '#a12306', 'ghi', 'lmo'][
            envIndex
          ];
          config.deptAnswerBorderColor = ['#fff', '#fff', 'ghi', 'lmo'][
            envIndex
          ];
          config.optionNotSelected = ['#2a2a2a', '#2a2a2a', 'ghi', 'lmo'][
            envIndex
          ];
          config.optionSelected = ['#00ffff', '#00ffff', 'ghi', 'lmo'][
            envIndex
          ];
          config.inputBgColor = ['#2a2a2a', '#2a2a2a', 'ghi', 'lmo'][envIndex];
          config.inputBorderColor = ['#00ffff', '#00ffff', 'ghi', 'lmo'][
            envIndex
          ];
          config.inputTxtColor = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.btnBg = ['#00ffff', '#00ffff', 'ghi', 'lmo'][envIndex];
          config.btnBorderColor = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.btnTxtColor = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.surveyTitle = '';
          config.logoSource =
            'https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/LittleNap.jpg';
          config.questionTextColor = ['#00ffff', '#00ffff', 'ghi', 'lmo'][
            envIndex
          ];
        }
        break;
      case [1][envIndex]:
        {
          config.questionTextColor = ['#ff883a', '#ff883a', 'ghi', 'lmo'][
            envIndex
          ];
          config.btnBg = '#ff883a';
          config.btnTxtColor = '#ff883a';
          config.btnBorderColor = '#ff883a';
          config.inputTxtColor = '#ff883a';
          config.inputBorderColor = '#ff883a';
          config.optionSelected = '#ff883a';
          config.deptOptionColorSelected = '#ff883a';
          config.deptOptionFontColor = '#ff883a';
          config.deptAnswerOptionColor = '#ff883a';
          config.deptAnswerBorderColor = '#ff883a';
        }
        // UPS Changes background and color changes
      case [5, 6][envIndex]:
        {
          config.questionTextColor = ['#2363A0', '#2363A0', 'ghi', 'lmo'][
            envIndex
          ];
          config.deptOptionColorNotSelected = ['#fff', '#fff', 'ghi', 'lmo'][
            envIndex
          ];
          config.deptOptionFontColor = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.optionNotSelected = ['#fff', '#fff', 'ghi', 'lmo'][
            envIndex
          ];
          config.formBG = ['#fff', '#fff', 'ghi', 'lmo'][envIndex];
          config.btnBg = '#2363A0';
          config.btnTxtColor = '#2363A0';
          config.btnBorderColor = '#2363A0';
          config.inputTxtColor = '#2363A0';
          config.inputBorderColor = '#2363A0';
          config.optionSelected = '#2363A0';
          config.deptOptionColorSelected = '#2363A0';
          config.deptOptionFontColor = '#2363A0';
          config.deptAnswerOptionColor = '#2363A0';
          config.deptAnswerBorderColor = '#2363A0';
        }
        break;
      default:
    }
  }

  return config;
}
