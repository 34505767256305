import React from "react";

import convertToQuestion from "./service/questionConvertor";
import Cookies from "universal-cookie";
import Header from "./components/Header/header";
import { Redirect } from "react-router-dom";
import emoji from "node-emoji";
import { themeSelector } from "../static/theme";
const cookies = new Cookies();
const axios = require("axios");
const axiosConfig = require("../components/AxiosConfig/AxiosConfig");

const cid =
  window.location.href.includes("hrlogica") == true
    ? Number(sessionStorage.getItem("cid"))
    : Number(cookies.get("cid"));
const companies =
  (process.env.NODE_ENV == "production" && cid == 16) ||
  (process.env.NODE_ENV == "development" && cid == 13) ||
  (process.env.NODE_ENV == "development" && cid == 64) ||
  (process.env.NODE_ENV == "production" && cid == 38);
export default class EndScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noSurvey: false,
      message: null,
      theme: themeSelector(parseInt(cid), process.env.NODE_ENV),
    };
  }

  async componentDidMount() {
    axiosConfig.headers["x-auth"] =
      window.location.href.includes("hrlogica") == true
        ? await sessionStorage.getItem("x-auth")
        : await cookies.get("x-auth");
    let res = await axios.get("/surveys/active", axiosConfig);
    res = res.data;
    if (res.data.surveys.length === 0) {
      this.setState({
        noSurvey: true,
      });
    } else {
      const surveyId = res.data.surveys[res.data.surveys.length - 1].id;
      let resSurvey = await axios.get(
        `/surveys/${surveyId}?question=true`,
        axiosConfig
      );
      resSurvey = resSurvey.data;
      console.log(resSurvey);
      const message = convertToQuestion(resSurvey.data)[0].message;
      this.setState({
        message,
      });
    }
  }

  render() {
    const { message } = this.state;

    const backImageStyle = {
      minHeight: "100vH",
      backgroundImage:
        (process.env.NODE_ENV == "production" && cid == 32) ||
        (process.env.NODE_ENV == "development" && cid == 55)
          ? `url('https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/Picture2.png')`
          : `url('https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/cine_bck.png')`,
      color: "#00ffff",
      fontWeight: "700",
      fontSize: "1.5rem",
      display: "flex",
      justifyContent: "center" /* align horizontal */,
      alignItems: "center",

      flexDirection: "column",
      padding:
        window.innerWidth > 768
          ? "10vh 20rem"
          : window.innerWidth > 500
          ? "10vh 10rem"
          : "10vh 2rem",
    };
    const simpleBackStyle = {
      minHeight: "100vH",
      backgroundColor: this.state.theme.formBG,
      color: "#00ffff",
      fontWeight: "700",
      fontSize: "1.5rem",
      display: "flex",
      justifyContent: "center" /* align horizontal */,
      alignItems: "center",

      flexDirection: "column",
      padding:
        window.innerWidth > 768
          ? "10vh 20rem"
          : window.innerWidth > 500
          ? "10vh 10rem"
          : "10vh 2rem",
    };
    return this.state.noSurvey === true ? (
      <Redirect to="/no_survey" />
    ) : (
      <React.Fragment>
        <Header headTheme={this.state.theme} />
        <div style={simpleBackStyle}>
          {message !== null
            ? message.split(".").map((item, i) => (
                <p
                  style={{
                    color: this.state.theme.questionTextColor,
                    fontWeight: "700",
                    fontSize: "2.0rem",
                    marginBottom: "2rem",
                    marginTop: "2rem",
                    textAlign: "center",
                  }}
                  key={i}
                >
                  {emoji.emojify(item) + "."}
                </p>
              ))
            : null}
        </div>
      </React.Fragment>
    );
  }
}
