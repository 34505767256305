import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import Cookies from "universal-cookie";
import Main from "../../view/Main";
import Form from "../../form/Form";
import Welcome from "../../form/Welcome";
import Login from "../../view/Login";
import EndScreen from "../../form/endScreen";
import NoSurvey from "../../form/noSurvey";

const cookies = new Cookies();

const axios = require("axios");
const axiosConfig = require("../../components/AxiosConfig/AxiosConfig");

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordAuthenticated: false,
      isFeedbackPage: false,
      showFormRoute: false,
      showWelcomeRoute: false,
      showChatRoute: false,
      noSurvey: false,
      user: {
        email: null,
        user_id: null,
      },
      company: {
        company_id: null,
        company_name: null,
      },
      userDetail: {
        name: null,
        designation: null,
        initials: null,
        user_id: null,
      },
      isLoggedIn:
        window.location.href.includes("hrlogica") == true
          ? !!sessionStorage.getItem("x-auth")
          : !!cookies.get("x-auth"),
    };
    this.handlePasswordStateUpdate = this.handlePasswordStateUpdate.bind(this);
    this.handleSessionLogout = this.handleSessionLogout.bind(this);
    this.handleSurveyType = this.handleSurveyType.bind(this);
  }

  async componentWillMount() {
    const survey_id = await cookies.get("survey_id");
    const userDetail =
      window.location.href.includes("hrlogica") == true
        ? sessionStorage.getItem("user")
        : cookies.get("user");
    axiosConfig.headers["x-auth"] =
      window.location.href.includes("hrlogica") == true
        ? sessionStorage.getItem("x-auth")
        : cookies.get("x-auth");
    if (this.state.isLoggedIn) {
      const res = await axios.get(
        `/surveys/active?survey_id=${survey_id}`,
        axiosConfig
      );

      if (res.data.data.surveys.length === 0) {
        this.handleSurveyType("No Survey", 0, this.state.userDetail);
      } else {
        this.handleSurveyType(
          res.data.data.surveys[res.data.data.surveys.length - 1].survey_type,
          res.data.data.surveys[res.data.data.surveys.length - 1]
            .answered_questions.length,
          userDetail
        );
      }
    }
  }

  handlePasswordStateUpdate(data) {
    window.location.href.includes("hrlogica") == true
      ? sessionStorage.setItem("x-auth", data.session.token)
      : data &&
        data.session &&
        data.session.token &&
        cookies.set("x-auth", data.session.token);
    window.location.href.includes("hrlogica") == true
      ? sessionStorage.setItem("cid", data.company.company_id)
      : cookies.set("cid", data.company.company_id);
    let initials = "";
    const username = data.user.name.trim();
    const userSplit = username.split(" ");

    if (userSplit.length === 1) {
      initials = userSplit[0][0];
    } else {
      initials = userSplit[0][0] + userSplit[1][0];
    }
    const userDetail = {
      name: data.user.name,
      designation: data.user.designation,
      user_id: data.user.id,
      initials: initials,
    };
    window.location.href.includes("hrlogica") == true
      ? sessionStorage.setItem("user", JSON.stringify(userDetail))
      : cookies.set("user", JSON.stringify(userDetail));
    this.setState(
      {
        company: data.company,
        userDetail,
      },
      async () => {
        axiosConfig.headers["x-auth"] =
          window.location.href.includes("hrlogica") == true
            ? sessionStorage.getItem("x-auth")
            : cookies.get("x-auth");
        const res = await axios.get("/surveys/active", axiosConfig);
        // console.log(res.data.data.surveys[res.data.data.surveys.length - 1]);
        if (res.data.data.surveys.length === 0) {
          this.handleSurveyType("No Survey", 0, this.state.userDetail);
        } else {
          this.handleSurveyType(
            res.data.data.surveys[res.data.data.surveys.length - 1].survey_type,
            res.data.data.surveys[res.data.data.surveys.length - 1]
              .answered_questions.length,
            this.state.userDetail
          );
        }
      }
    );
  }

  handleSessionLogout() {
    window.location.href.includes("hrlogica") == true
      ? sessionStorage.removeItem("user")
      : cookies.remove("user");
    window.location.href.includes("hrlogica") == true
      ? sessionStorage.removeItem("x-auth")
      : cookies.remove("x-auth");
    this.setState({
      isLoggedIn: false,
      isPasswordAuthenticated: false,
    });
  }

  handleSurveyType(surveyType, answered, userDetail) {
    if (
      surveyType !== "No Survey" &&
      surveyType !== "Form" &&
      surveyType !== "General Form"
    ) {
      this.setState({
        showChatRoute: true,
        showFormRoute: false,
        showWelcomeRoute: false,
        isPasswordAuthenticated: true,
        isLoggedIn: true,
        userDetail,
      });
    } else if (surveyType === "No Survey") {
      this.setState({
        showFormRoute: false,
        showChatRoute: false,
        showWelcomeRoute: false,
        isPasswordAuthenticated: true,
        isLoggedIn: true,
        noSurvey: true,
        userDetail,
      });
    } else if (
      (surveyType === "Form" || surveyType === "General Form") &&
      answered > 0
    ) {
      this.setState({
        showFormRoute: true,
        showChatRoute: false,
        showWelcomeRoute: false,
        isPasswordAuthenticated: true,
        isLoggedIn: true,
        userDetail,
      });
    } else if (
      (surveyType === "Form" || surveyType === "General Form") &&
      answered === 0
    ) {
      this.setState({
        showFormRoute: false,
        showWelcomeRoute: true,
        showChatRoute: false,
        isPasswordAuthenticated: true,
        isLoggedIn: true,
        userDetail,
      });
    } else {
      this.setState({
        showChatRoute: false,
        showFormRoute: false,
        showWelcomeRoute: false,
        isPasswordAuthenticated: true,
        isLoggedIn: true,
        userDetail,
      });
    }
  }

  handleHomePage() {
    this.setState({
      isFeedbackPage: false,
      isFeedbackChatPage: false,
    });
  }

  render() {
    return (
      <div>
        <Switch>
          <Route
            path="/"
            exact
            // component={() => <Login handleUpdate={this.handlePasswordStateUpdate} />}
          />
          <Route
            path="/login"
            component={() => (
              <Login handleUpdate={this.handlePasswordStateUpdate} />
            )}
          />
          <Route
            path="/survey_chat"
            component={() => (
              <Main
                user={this.state.userDetail}
                isLoggedIn={this.state.isLoggedIn}
                handleLogout={this.handleSessionLogout}
              />
            )}
          />
          <Route
            path="/welcome"
            component={() => (
              <Welcome
                user={this.state.userDetail}
                isLoggedIn={this.state.isLoggedIn}
                handleLogout={this.handleSessionLogout}
              />
            )}
          />
          <Route
            path="/form"
            component={() => (
              <Form
                user={this.state.userDetail}
                isLoggedIn={this.state.isLoggedIn}
                handleLogout={this.handleSessionLogout}
              />
            )}
          />
          <Route
            path="/end_survey"
            component={() => (
              <EndScreen
                user={this.state.userDetail}
                isLoggedIn={this.state.isLoggedIn}
                handleLogout={this.handleSessionLogout}
              />
            )}
          />
          <Route path="/no_survey" component={() => <NoSurvey />} />
        </Switch>
        {this.state.isLoggedIn ? (
          this.state.noSurvey ? (
            <Redirect to="/no_survey" />
          ) : this.state.showWelcomeRoute ? (
            <Redirect to="/welcome" />
          ) : this.state.showFormRoute ? (
            <Redirect to="/form" />
          ) : this.state.showChatRoute ? (
            <Redirect to="/survey_chat" />
          ) : null
        ) : !this.state.isPasswordAuthenticated ? (
          <Redirect to="/login" />
        ) : null}
      </div>
    );
  }
}
