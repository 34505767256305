import React from "react";
// import XaneLogo from "./icons/xaneLogo.png";
// //import RedingtonLogo from "./icons/redingtonLogo.png";
// import hrlogica from "./icons/hrlogica.png";
// import FirstsourceLogo from "./icons/FirstsourceLogo.png";

// import NablerLogo from "./icons/nabler-logo.png";
// import TNSLogo from "./icons/tns.jpg";
// import CineGrandLogo from "./icons/CGlogo.jpg";
// import CGLogo from "./icons/CGLogo.png";

import Cookies from "universal-cookie";
import "./xaneBar.css";
const cookies = new Cookies();
const companyLogos = {
  SoilLogo:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/soilLogo.png",
  RedingtonLogo:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/redingtonLogo.png",
  XaneLogo:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/xaneLogo.png",
  NablerLogo:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/nabler-logo.png",
  CineGrandLogo:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/CGlogo.jpg",
  CGLogo:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/CGLogo.png",
  LittleNapLogo:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/LittleNap.jpg",
  TNSLogo:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/tns.jpg",
  FirstsourceLogo:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/FirstsourceLogo.png",
  hrlogica:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/hrlogica.png",
  GrowthSourceLogo:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/xaneLogo.png",
  Incred:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/IncredLogo.png",
  Mobileum:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/Mobileum_Logo.png",
  Continuserve:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/continuserve_logo.png",
  JivaLogo:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/jiva-ayurveda-logo.jpg",
  NrlLogo:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/nrl-logo.jpg",
  SdlLogo:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/sutherland_logo.jpg",
  SDL_XANE_LOGO:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/sutherland+-+powered+by+xaneai.png",
  HGS: "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/Screenshot+2021-02-16+at+7.31.01+PM.png",
};

class XaneBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      logo: "",
      clientLogo: "",
      width: null,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", () => {});
  }

  componentWillMount() {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let date = new Date();
    date = `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;

    const cid = cookies.get("cid");
    const NODE_ENV = process.env.NODE_ENV;
    let logo;
    let clientLogo;
    switch (cid) {
      // case NODE_ENV === "development" ? "1" : "20":
      //   logo = companyLogos.TNSLogo;
      //   break;
      // case NODE_ENV === "development" ? "23" : "22":
      //   logo = companyLogos.LittleNapLogo;
      //   break;
      // case NODE_ENV === "development" ? "5" : "5":
      //   logo = companyLogos.SoilLogo;
      //   break;
      // case NODE_ENV === "development" ? "10" : "13":
      //   logo = companyLogos.RedingtonLogo;
      //   break;
      // case NODE_ENV === "development" ? "12" : "15":
      //   logo = companyLogos.hrlogica;
      //   break;
      // case NODE_ENV === "development" ? "13" : "16":
      //   logo = companyLogos.FirstsourceLogo;
      //   break;
      // case NODE_ENV === "development" ? "22" : "23":
      //   logo = companyLogos.NablerLogo;
      //   break;
      // case NODE_ENV === "development" ? "33" : "24":
      //   logo = companyLogos.NablerLogo;
      //   break;
      // case NODE_ENV === "development" ? "33" : "25":
      //   logo = companyLogos.NablerLogo;
      //   break;
      // case NODE_ENV === "development" ? "20" : "16":
      //   logo = companyLogos.XaneLogo;
      //   break;
      // case NODE_ENV === "development" ? "31" : "131":
      //   logo = companyLogos.XaneLogo;
      //   break;
      // case NODE_ENV === "development" ? "32" : "32":
      //   logo = companyLogos.CineGrandLogo;
      //   break;
      // case NODE_ENV === "development" ? "44" : "28":
      //   logo = companyLogos.CGLogo;
      //   break;
      // case NODE_ENV === "development" ? "45" : "29":
      //   logo = companyLogos.CineGrandLogo;
      //   break;
      // case NODE_ENV === "development" ? "34" : "132":
      //   logo = companyLogos.XaneLogo;
      //   break;
      // case NODE_ENV === "development" ? "39" : "26":
      //   logo = companyLogos.NablerLogo;
      //   break;
      // case NODE_ENV === "development" ? "139" : "27":
      //   logo = companyLogos.NablerLogo;
      //   break;
      // case NODE_ENV === "production" ? "30" : "127":
      //   logo = companyLogos.NablerLogo;
      //   break;
      // case NODE_ENV === "production" ? "31" : "54":
      //   logo = companyLogos.NablerLogo;
      //   break;
      // case NODE_ENV === "production" ? "33" : "154":
      //   logo = companyLogos.NablerLogo;
      //   break;
      // case NODE_ENV === "production" ? "34" : "57":
      //   logo = companyLogos.NablerLogo;
      //   break;
      // case NODE_ENV === "production" ? "35" : "61":
      //   logo = companyLogos.NablerLogo;
      //   break;
      // case NODE_ENV === "production" ? "36" : "68":
      //   logo = companyLogos.NablerLogo;
      //   break;
      // case NODE_ENV === "production" ? "37" : "117":
      //   logo = companyLogos.NablerLogo;
      //   break;
      // case NODE_ENV === "production" ? "39" : "217":
      //   logo = companyLogos.NablerLogo;
      //   break;
      // case NODE_ENV === "production" ? "40" : "220":
      //   logo = companyLogos.Incred;
      //   break;
      case NODE_ENV === "production" ? "1" : "1":
        logo = companyLogos.Incred;
        break;
      // case NODE_ENV === "production" ? "41" : "222222":
      //   logo = companyLogos.NablerLogo;
      //   break;
      // case NODE_ENV === "production" ? "42" : "222222":
      //   logo = companyLogos.NablerLogo;
      //   break;
      // case NODE_ENV === "production" ? "43" : "2222222":
      //   logo = companyLogos.Mobileum;
      //   break;
      // case NODE_ENV === "production" ? "44" : "1901":
      //   logo = companyLogos.Continuserve;
      //   break;
      // case NODE_ENV === "production" ? "45" : "223":
      //   logo = companyLogos.JivaLogo;
      //   break;
      case NODE_ENV === "production" ? "2" : "2":
        logo = companyLogos.NrlLogo;
        break;
      case NODE_ENV === "production" ? "3" : "3":
        logo = companyLogos.SdlLogo;
        break;
      case NODE_ENV === "production" ? "4" : "7":
        logo = companyLogos.SDL_XANE_LOGO;
        break;
      case NODE_ENV === "production" ? "44444" : "8":
        logo = companyLogos.HGS;
        break;
      default:
        logo = companyLogos.XaneLogo;
    }

    this.setState({
      date: date,
      logo: logo,
      clientLogo: logo,
    });
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          paddingTop: "2vh",
          zIndex: 1000,
          borderBottom: "1px solid #d4d4d4",
        }}
      >
        {cookies.get("cid") == 218 &&
        process.env.NODE_ENV === "development" ? null : (
          <div style={{ paddingRight: "18vw" }}>
            {window.innerWidth < 500 ? (
              <img
                src={companyLogos.XaneLogo}
                alt="Company Logo"
                style={{
                  padding: "1px",
                  width: "25vw",
                  height: "5vh",
                  visibility: "hidden",
                  flex: 1,
                }}
              />
            ) : (
              <img
                src={companyLogos.XaneLogo}
                alt="Company Logo"
                style={{
                  padding: "1px",
                  width: "16vh",
                  height: "5vh",
                  visibility:
                    cookies.get("cid") == 4 &&
                    process.env.NODE_ENV === "production"
                      ? "hidden"
                      : "visible",
                  flex: 1,
                }}
              />
            )}
          </div>
        )}
        <div className="date" style={{ paddingRight: "18vw" }}>
          <p
            style={{
              zIndex: 1001,
              fontSize: "3.5vh",
              color: "grey",
              fontWeight: 400,
              marginTop: 0,
              marginBottom: 0,
              left: "40vw",
              top: "0.4vh",
            }}
          >
            {this.state.date}
          </p>
        </div>
        <div>
          <img
            src={cookies.get("company_logo")}
            style={{
              padding: "1px",
              height: "10vh",
            }}
          />
        </div>
        {/* <div>
          {process.env.NODE_ENV === "development" &&
          cookies.get("cid") !== "8" ? null : window.innerWidth < 500 ? (
            <img
              src={this.state.logo}
              alt="Company Logo"
              style={{
                //paddingTop: "1%",
                paddingLeft: 0,
                width: "20vw",
                height: "5vh",
              }}
            />
          ) : (
            <img
              src={
                process.env.NODE_ENV === "staging"
                  ? companyLogos.XaneLogo
                  : this.state.logo
              }
              alt="Company Logo"
              style={{
                //paddingTop: "1%",
                paddingLeft: 0,
                width:
                  process.env.NODE_ENV === "staging"
                    ? "16vh"
                    : this.state.logo == companyLogos.TNSLogo
                    ? "16vh"
                    : this.state.logo == companyLogos.XaneLogo
                    ? "16vh"
                    : this.state.logo == companyLogos.Incred
                    ? "9vw"
                    : this.state.logo == companyLogos.SdlLogo
                    ? "11vw"
                    : "13vw",

                height:
                  process.env.NODE_ENV === "staging"
                    ? "5vh"
                    : this.state.logo == companyLogos.GrowthSourceLogo
                    ? "8vh"
                    : this.state.logo == companyLogos.CineGrandLogo
                    ? "8vh"
                    : this.state.logo == companyLogos.Incred
                    ? "6vh"
                    : this.state.logo == companyLogos.Mobileum ||
                      this.state.logo == companyLogos.SdlLogo
                    ? "10vh"
                    : "7vh",
              }}
            />
          )}
        </div> */}
      </div>
    );
  }
}

export default XaneBar;
