import React, { Component } from "react";
import Cookies from "universal-cookie";
import MultipleSelect from "./MultipleSelect";
import MultipleElement from "./MultipleElement";

const cookies = new Cookies();
const Incred =
  (cookies.get("survey_id") === 2 && process.env.NODE_ENV == "development") ||
  (cookies.get("survey_id") === 6 && process.env.NODE_ENV == "development") ||
  (cookies.get("survey_id") == 2 && process.env.NODE_ENV == "production");
class SubmitButton extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <MultipleSelect>
        <MultipleElement
          bubbleColor="#21ba45"
          fontColor
          onClick={() => this.props.onClick()}
        >
          {Incred ? "Continue" : "Submit"}
        </MultipleElement>
      </MultipleSelect>
    );
  }
}
export default SubmitButton;
