import styled from 'styled-components';

const QuestionBubble = styled.div`
  position: relative;
  /* box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 8px; */
  /* background: grey; */
  /* border-bottom: 2px solid black; */
  /* border-top: 1px solid #00ffff; */
  /* border-right: 3px solid black;
  border-bottom: 3px solid black; */
  color: white;
  /* border-radius: 8px; */
  display: flex;
  margin: 1rem 0 1rem 0;
  padding: 3rem 0 0 0;
`;

export default QuestionBubble;
