import React, { Component } from "react";
import PropTypes from "prop-types";
import Bubble from "./Bubble";
import Image from "./Image";
import ImageContainer from "./ImageContainer";
import Loading from "../common/Loading";
import TextStepContainer from "./TextStepContainer";
import emoji from "node-emoji";
import { mapImage } from "../options/mapImage";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const labelImages = {
  slightly_frowning_face:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/slightly_frowning_face.png",
  slightly_smiling_face:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/slightly_smiling_face.png",
  neutral_face:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/neutral_face.png",
  cry: "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/cry.png",
  smiley:
    "https://munna-storage.s3.ap-south-1.amazonaws.com/company-logos/smiley.png",
};
const ITC = cookies.get("cid") == 218 && process.env.NODE_ENV === "development";
const Incred =
  (cookies.get("survey_id") == 2 && process.env.NODE_ENV == "development") ||
  (cookies.get("survey_id") == 6 && process.env.NODE_ENV == "development") ||
  (cookies.get("survey_id") == 2 && process.env.NODE_ENV == "production");
class TextStep extends Component {
  /* istanbul ignore next */
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };

    this.renderMessage = this.renderMessage.bind(this);
  }

  componentDidMount() {
    const { step } = this.props;

    const { component, delay, waitAction } = step;
    const isComponentWatingUser = component && waitAction;
    setTimeout(() => {
      this.setState({ loading: false }, () => {
        if (!isComponentWatingUser && !step.rendered) {
          this.props.triggerNextStep();
        }
      });
    }, delay);
  }

  renderMessage() {
    const { previousValue, step, steps, previousStep, triggerNextStep } =
      this.props;

    const { component } = step;
    let { message } = step;

    if (component) {
      return React.cloneElement(component, {
        step,
        steps,
        previousStep,
        triggerNextStep,
      });
    }

    message = message.replace(/{previousValue}/g, previousValue);

    message = message.replace(/\n/g, ", ");
    // survey_id
    //  [317,318,319,320,321,22,23,24,25,26]
    const RemoveExtraAnswerOfTheseSurveys = {
      development: [317, 318, 319, 320, 321],
      production: [22, 23, 24, 25, 26],
    };
    if (
      RemoveExtraAnswerOfTheseSurveys[process.env.NODE_ENV].includes(
        Number(cookies.get("survey_id"))
      )
    ) {
      // return message.split(",")[0];
      // return message[
      //   message.indexOf(
      //     message
      //       .split(",")
      //       .find((msg) => msg.toLowerCase().includes("strongly"))
      //   )
      // ];

      if (message.includes(",")) {
        return message.split(",")[0].toLowerCase().includes("strongly")
          ? message.split(",")[0]
          : message.split(",")[1];
      }
      return message;
    }

    return message;
  }

  render() {
    const {
      step,
      isFirst,
      isLast,
      avatarStyle,
      bubbleStyle,
      hideBotAvatar,
      hideUserAvatar,
    } = this.props;
    const { avatar, user } = step;

    const showAvatar = user ? !hideUserAvatar : !hideBotAvatar;

    return (
      <TextStepContainer className="rsc-ts" user={user}>
        {!ITC && (
          <ImageContainer className="rsc-ts-image-container" user={user}>
            {isFirst && showAvatar && (
              <Image
                className="rsc-ts-image"
                style={avatarStyle}
                showAvatar={showAvatar}
                user={user}
                src={avatar}
                alt="avatar"
              />
            )}
          </ImageContainer>
        )}
        <Bubble
          className="rsc-ts-bubble"
          style={{
            backgroundColor: !user
              ? this.props.step.id > "ques_3006" &&
                this.props.step.id <= "ques_3019"
                ? "#CC0006"
                : Incred
                ? "#d7d7d7"
                : "#252525"
              : "#35a2b5",
            color: Incred ? (!user ? "#000" : "#fff") : "#fff",
          }}
          user={user}
          showAvatar={showAvatar}
          isFirst={isFirst}
          isLast={isLast}
        >
          {this.state.loading && <Loading />}
          {!this.state.loading &&
            (this.renderMessage() === emoji.emojify(":cry:") ? (
              <img style={{ width: 30, height: 30 }} src={labelImages.cry} />
            ) : this.renderMessage() ===
              emoji.emojify(":slightly_frowning_face:") ? (
              <img
                style={{ width: 30, height: 30 }}
                src={labelImages.slightly_frowning_face}
              />
            ) : this.renderMessage() === emoji.emojify(":neutral_face:") ? (
              <img
                style={{ width: 30, height: 30 }}
                src={labelImages.neutral_face}
              />
            ) : this.renderMessage() ===
              emoji.emojify(":slightly_smiling_face:") ? (
              <img
                style={{ width: 30, height: 30 }}
                src={labelImages.slightly_smiling_face}
              />
            ) : this.renderMessage() === emoji.emojify(":smiley:") ? (
              <img style={{ width: 30, height: 30 }} src={labelImages.smiley} />
            ) : typeof this.renderMessage() === "string" &&
              this.renderMessage().includes("mp3") ? (
              <audio
                controls
                src={this.renderMessage()}
                style={{ maxWidth: "100%" }}
              />
            ) : typeof this.renderMessage() === "string" &&
              this.renderMessage().includes("jpg") ? (
              <img
                style={{ width: 100, height: 100 }}
                src={this.renderMessage()}
              />
            ) : (
              this.renderMessage()
            ))}
        </Bubble>
      </TextStepContainer>
    );
  }
}

TextStep.propTypes = {
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  step: PropTypes.object.isRequired,
  triggerNextStep: PropTypes.func.isRequired,
  avatarStyle: PropTypes.object.isRequired,
  bubbleStyle: PropTypes.object.isRequired,
  hideBotAvatar: PropTypes.bool.isRequired,
  hideUserAvatar: PropTypes.bool.isRequired,
  previousStep: PropTypes.object,
  previousValue: PropTypes.any,
  steps: PropTypes.object,
};

TextStep.defaultProps = {
  previousStep: {},
  steps: {},
  previousValue: "",
};

export default TextStep;
