import emoji from "node-emoji";
import Cookies from "universal-cookie";
import { endMessage } from "./endMessage";

const cookies = new Cookies();

export default async function convert(data, loginData, surveyId) {
  // console.warn(emoji);
  //   const data = converter.data;
  let survey_id = await cookies.get("survey_id");
  let processedData = [];
  const cid = cookies.get("cid");
  const NODE_ENV = process.env.NODE_ENV;
  let authToken = cookies.get("x-auth");

  if (data.survey.questions) {
    // console.warn('ProcessedData', data);
    let start = 0;
    if (data.survey.first_question_id !== null) {
      const id = data.survey.first_question_id;
      start = data.survey.questions.findIndex((obj) => obj.id === id);
      const first_ques = data.survey.questions[start];
      data.survey.questions.splice(start, 1);
      data.survey.questions.unshift(first_ques);
    }
    for (let idx = 0; idx < data.survey.questions.length; idx++) {
      const jsonQuestion = data.survey.questions[idx];
      const question = {};
      const answer = {};

      question.id = `ques_${jsonQuestion.id}`;
      question.message = emoji.emojify(jsonQuestion.question);
      question.trigger = jsonQuestion.id;
      question.tag = jsonQuestion.question_tag;

      answer.id = jsonQuestion.id;

      const msgArr = [];
      if (jsonQuestion.answers && jsonQuestion.answers.length > 0) {
        const inAns = jsonQuestion.answers[0];

        if (
          jsonQuestion.question_tag === "DOB" ||
          jsonQuestion.question_tag === "Duration"
        ) {
          answer.inputDate = ["DOB"];
          if (jsonQuestion.question_tag === "Duration") {
            answer.inputDate = ["start", "end"];
          }
          const nextQId =
            inAns.next_question_id == null
              ? sessionStorage.getItem("cid") === "11"
                ? "link1"
                : "endSurvey"
              : `ques_${inAns.next_question_id}`;
          if (inAns.prequel_text) {
            var message = {};
            message.id = `msg_q_${jsonQuestion.id}`;
            message.trigger = nextQId;
            message.message = emoji.emojify(inAns.prequel_text);
            answer.trigger = `msg_q_${jsonQuestion.id}`;
            msgArr.push(message);
          } else {
            answer.trigger = nextQId;
          }
        } else if (!inAns.id || inAns.id == null) {
          answer.user = true;
          answer.validator = (value) => {
            if (!value) {
              return "";
            }
            return true;
          };

          const nextQId =
            inAns.next_question_id == null
              ? (NODE_ENV === "development" && cid === "11") ||
                (NODE_ENV === "production" && cid === "14")
                ? "score"
                : NODE_ENV === "staging" && cid == "11"
                ? "link1"
                : "endSurvey"
              : `ques_${inAns.next_question_id}`;
          if (inAns.prequel_text) {
            var message = {};
            message.id = `msg_q_${jsonQuestion.id}`;
            message.trigger = nextQId;
            message.message = emoji.emojify(inAns.prequel_text);
            answer.trigger = `msg_q_${jsonQuestion.id}`;
            msgArr.push(message);
          } else {
            answer.trigger = nextQId;
          }
        } else {
          answer.options = [];
          if (jsonQuestion.answer_type == "Multiple Choice") {
            answer.multipleChoice = true;
          }
          for (let idxAns = 0; idxAns < jsonQuestion.answers.length; idxAns++) {
            const ans = jsonQuestion.answers[idxAns];
            const option = {};
            option.value = ans.id;
            option.label = emoji.emojify(ans.answer);
            const nextQuestionId =
              ans.next_question_id == null
                ? (NODE_ENV === "development" && cid === "11") ||
                  (NODE_ENV === "production" && cid === "14")
                  ? "score"
                  : NODE_ENV === "staging" && cid == "11"
                  ? "link1"
                  : "endSurvey"
                : `ques_${ans.next_question_id}`;
            if (ans.prequel_text) {
              var message = {};
              message.id = `msg_${ans.id}`;
              message.trigger = nextQuestionId;
              message.message = emoji.emojify(ans.prequel_text);
              option.trigger = `msg_${ans.id}`;
              msgArr.push(message);
            } else {
              option.trigger = nextQuestionId;
            }
            answer.options.push(option);
          }
        }
      }

      processedData.push(question);
      processedData.push(answer);
      msgArr.length ? (processedData = [...processedData, ...msgArr]) : null;
      //   if(msgArr.length > 0) {
      //     processedData = processedData.concat(msgArr);
      //     // Object.assign(processedData, processedData, msgArr);
      //   }
    }
  }
  // console.warn('processed data', JSON.stringify(processedData));

  return endMessage(processedData, NODE_ENV, cid, authToken, surveyId);
}
